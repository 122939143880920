import { bindable, inject } from 'aurelia-framework';
import { bindingMode } from 'aurelia-binding';

import { Event } from 'zailab.common';

import './basic-dropdown.scss';

@inject(Element)
export class BasicDropdown {
  @bindable({
    attribute: 'selected-value',
    defaultBindingMode: bindingMode.twoWay,
  })
  public selectedValue: string;
  @bindable({ attribute: 'no-selected-value-message' })
  public noSelectedValueMessage?: string;
  @bindable() public list: any = [];
  @bindable({ attribute: 'display-field' })
  public displayField?: string;
  @bindable() public direction: boolean;
  @bindable public width: string;
  @bindable public height: string;
  @bindable public rounded: boolean;

  public enabled = false;
  private autoHideTimer: any = null;

  constructor(private element: Element) {}

  public toggleMenu(): void {
    this.enabled = !this.enabled;
    this.cancelAutoHide();
  }

  public cancelAutoHide(): void {
    if (this.autoHideTimer) {
      clearTimeout(this.autoHideTimer);
      this.autoHideTimer = null;
    }
  }

  public startAutoHide(): void {
    this.autoHideTimer = setTimeout(() => {
      this.enabled = false;
    }, 300);
  }

  public select(item: any): void {
    this.selectedValue = this.displayField ? item[this.displayField] : item;
    this.enabled = false;
    new Event(this.element, 'change', this.selectedValue);
  }
}
