import { autoinject } from "aurelia-dependency-injection";
import { bindable, customElement, LogManager } from "aurelia-framework";
import { SearchTools, SessionStore } from "zailab.common";
import { TelephonyService } from "../../../../../../telephony/telephony-service";
import { ContactModel } from "../../../../../contact/contact-model";
import { InteractionModel } from "../../../../interaction-model";
import WorkTypesListModel from "../../../../worktypes/work-types-list-model";
import { WorkTypesService } from "../../../../worktypes/work-types-service";

const logger = LogManager.getLogger('TransferToWorktype');

@autoinject
@customElement('z-transfer-to-worktype')
export class TransferToWorktype {

  @bindable public interaction: InteractionModel;
  @bindable public contact: ContactModel;
  @bindable({ attribute: 'is-active' }) public isActive: boolean;

  public hideDelay: number = 1500;
  public searchText: string;
  public selectedWorktype: WorkTypesListModel;
  public displaySearchResults: boolean;
  
  private autoCloseTimer: any;
  private jsSearchInput: HTMLElement;
  private attemptingTransfer: boolean;
  
  private attemptTime: number = 5000;

  constructor(
    private sessionStore: SessionStore,
    private telephonyService: TelephonyService,
    private workTypeService: WorkTypesService
  ) {}

  public attached(): void {
    this.contact.retrieveOrganisationWorkTypes();
  }

  public showWorktypes(event: Event): void {
    event.stopPropagation();
    this.isActive = true;
  }
  
  public hideWorktypes(event: Event): void {
    event.stopPropagation();
    this.selectedWorktype = null;
    this.searchText = '';
    this.isActive = false;
    this.hideSearchResults();
  }

  public hideSearchResults(): void {
    this.displaySearchResults = false;
  }

  public startAutoCloseMenu(_delay: number): void {
    this.autoCloseTimer = setTimeout(() => {
      if (this.jsSearchInput === document.activeElement) {
        return;
      }
      this.displaySearchResults = false;
    }, _delay);
  }

  public preventAutoClose(): void {
    window.clearTimeout(this.autoCloseTimer);
  }

  public toggleSearchResults(event: Event): void {
    event.stopPropagation();
    this.displaySearchResults = !this.displaySearchResults;
  }
  
  public selectWorkTypeToTransfer(workType: WorkTypesListModel, event?: Event): void {
    event && event.stopPropagation();
    this.selectedWorktype = workType;
    this.searchText = workType.templateName;
    this.displaySearchResults = false;
  }

  public inputValueChanged(): void {
    this.selectedWorktype = null;
  }

  public transfer(event: Event): void {
    event.stopPropagation();

    if (this.attemptingTransfer) {
      return;
    }

    this.notifyTransferAttempt();
    this.workTypeService
      .retrieveWorkType(this.selectedWorktype.templateId)
      .then((workType) => {

        const service = workType.service;
        service.taskDataSLA = {
          answeredCalls: workType.targetResponse,
          waitTime: workType.waitTime
        };

        const workTypeTransfer = {
          callId: this.interaction.interactionId,
          transferringMemberId: this.sessionStore.get.user.memberId,
          workTypeId: workType.id
        };

        this.telephonyService.workTypeTransfer(workTypeTransfer);
        this.isActive = false;
      });
  }
  private notifyTransferAttempt(): void {
    this.attemptingTransfer = true;
    setTimeout(() => {
      this.attemptingTransfer = false;
    }, this.attemptTime);
  }

  protected partialMatch(searchExpression: any, value: any, searchParam: any): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}
