import { autoinject, LogManager, customElement, bindable } from 'aurelia-framework';

import { SessionStore } from 'zailab.common';
import { ConversationModel } from '../../conversation-model';
import { computedFrom } from "aurelia-binding";
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { InteractionService } from '../../interaction/interaction-service';
import { InteractionService as OtherInteractionService } from '../../../interactions/interaction-service';
import { ConversationService } from '../../conversation-service';
import { MemberInteractionModel } from '../../interaction/member-interaction-model';
import { TicketModel } from '../../interaction/ticket-model';
import { ContactModel } from '../../../contact/contact-model';
import { ContactService } from '../../../contact/contact-service';

const logger = LogManager.getLogger('TicketInteractionCard');

@autoinject()
@customElement('z-ticket-interaction-card')
export class TicketInteractionCard {
  @bindable private interaction: TicketModel;
  @bindable private contact: ContactModel;
  @bindable private unlinked: boolean;
  @bindable private conversations: ConversationModel[];

  public additionalDataVisible: boolean = false;

  private isInteractionEnded: boolean = true;
  private subscriptions: Subscription[] = [];

  constructor(
    private sessionStore: SessionStore,
    private eventAggregator: EventAggregator,
    private interactionService: InteractionService,
    private otherInteractionService: OtherInteractionService,
    private conversationService: ConversationService,
    private contactService: ContactService
  ) { }

  public bind(): void {
    this.subscriptions.push(this.eventAggregator.subscribe('end-interaction', (interactionId) => {
      if (!this.isInteractionEnded && interactionId === this.interaction.interactionId) {
        this.isInteractionEnded = true;
      }
    }));
    this.updateInteractionDisplay();
  }

  public interactionChanged(newValue: any, oldValue: any): void {
    if (!newValue || !oldValue || newValue.interactionId === oldValue.interactionId) {
      return;
    }
    this.updateInteractionDisplay();
  }

  private updateInteractionDisplay(): void {

    const memberId = this.sessionStore.get.user.memberId;
    this.isInteractionEnded = true;

    this.conversationService.retrieveInteraction(this.interaction.interactionId)
      .then((interaction) => {
        this.interaction.mapMetaData(interaction.metaData);
        this.interaction.mapJourney(interaction.journey);
        this.interaction.mapAdditionalData(interaction.additionalData);
        this.interaction.mapAgent(interaction.agent);
      });

    setTimeout(() => {

      this.otherInteractionService.retrieveConnectedInteractions(memberId)
        .then((res: Array<MemberInteractionModel>) => {
          if (res && res.length) {
            const thisInteraction = res.find((interaction) => interaction.interactionId === this.interaction.interactionId);
            if (thisInteraction) {
              this.isInteractionEnded = false;
            }
          }
        })
        .catch((err: Error) => {
          logger.info('bind :: retrieveConnectedInteractions :: err=', err);
        });
    }, 1000);
  }

  private mapDataToInteraction(interaction, data) {
    interaction.channel = data.metaData.channel || interaction.channel;
    interaction.to = data.metaData.to || interaction.to;
    interaction.taskName = data.metaData.workType || interaction.taskName;
    
    interaction.agent.id = data.agent.memberId || interaction.agent.id;
    interaction.agent.firstName = data.agent.firstName || interaction.agent.firstName;
    interaction.agent.surname = data.agent.surname || interaction.agent.surname;

    interaction.timestamp = data.journey.waypoints[0].timestamp || interaction.timestamp;

    interaction.attachmentDetails = data.attachmentDetails || interaction.attachmentDetails;
    interaction.contactId = data.metaData.serviceId || interaction.contactId;
    interaction.conversationId = data.metaData.flowName || interaction.conversationId;

    return interaction;
};

  public triggerAdditionalDataButton(data: any): void {
    this.contactService.triggerRequest(data.value, data.requestMethod);
  }

  public endInteraction(): void {
    this.isInteractionEnded = true;
    this.interactionService.endInteraction(this.interaction);
  }

  public showAdditionalData(): void {
    if (this.interaction.additionalData.length > 0) {
      this.additionalDataVisible = true;
    }
  }

  public openLink(url: string) {
    this.eventAggregator.publish('interaction-link-open', {
      firstName: this.contact.firstName,
      surname: this.contact.surname,
      remote: this.interaction.from,
      url: url,
      interactionId: this.interaction.interactionId
    });
    this.eventAggregator.publish('dock-cards');
  }

  @computedFrom('contact.contactId', 'interaction')
  public get agentName(): string {
    const user = this.sessionStore.get.user;
    if (user && !this.contact && !this.contact.contactId) {
      return `${user.firstName} ${user.surname}`;
    }
    return this.interaction.agentName;
  }

  public detached(): void {
    this.subscriptions.forEach(subscription => subscription.dispose());
  }
}
