import { autoinject, LogManager } from 'aurelia-framework';
//@ts-ignore
import { DialogController } from 'aurelia-dialog';
import {
  ValidationControllerFactory,
  ValidationRules,
  validateTrigger,
} from 'aurelia-validation';
/**/
import { BootstrapFormRenderer } from 'zailab.common';
import { Validation } from 'zailab.validation';
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { TableInputModel } from '../../../../../../components/custom/table-input';
/**/
const logger = new LogManager.getLogger('SetVariablesDialog');
/**/
@autoinject
export class SetVariablesDialog {
  public name: string = '';
  private validation: any;

  public isAddingContent: boolean = false;
  public isValid: boolean = false;

  private nodeData: NodeModel;
  private nodeConfiguration: ZNodeConfig;
  private changedHeaders;
  public tableData = [];
  public tableColumns = ['Variable Name', 'Variable Mapping'];

  constructor(
    private dialogController: DialogController,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(
      JSON.stringify(this.nodeConfiguration.nodeDefinition)
    ); // Prevent deep copy
    this.name = this.nodeData.properties.name || '';
    this.validate();
    this.tableData = this.nodeData.properties.outputVariables
      ? Object.entries(this.nodeData.properties.outputVariables)
      : [];
  }

  public validate(): void {
    if (this.name.length === 0) {
      this.isValid = false;
      return;
    }
    this.isValid = true;
  }

  public attached(): void {
    this.initialiseValidation();
  }

  private initialiseValidation(): void {
    ValidationRules.ensure('name')
      .required()
      .withMessage('Please enter a name.')
      .maxLength(Validation.LENGTH.NAME)
      .withMessage('Name cannot exceed 30 characters.')
      .on(this);
  }

  public valueChanged(tableData: any): void {
    const reversedObject = {};

    tableData.forEach(([key, value]) => {
      reversedObject[key] = value;
    });
    this.nodeData.properties.outputVariables = reversedObject;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    this.name = this.name.trim();
    if (!this.name) {
      return;
    }
    let properties: any = {
      name: this.name,
      outputVariables: this.nodeData.properties.outputVariables,
      isDefined: true,
      externalReference: this.nodeData.properties.externalReference,
    };
    let updatedNode: NodeModel = this.mapNodeProperties(properties);
    this.dialogController.ok(updatedNode);
  }

  public log(...args: any): void {
    logger.debug('🔍', ...args);
  }

  private mapNodeProperties(properties: any): NodeModel {
    this.nodeData.properties = properties;
    let finalConnections = this.mapConnectors(this.nodeData.connections);

    this.nodeData.outputConnectors = finalConnections;
    this.nodeData.connections = finalConnections;

    return new NodeModel(this.nodeData);
  }

  private mapConnectors(
    _existingConnections: Array<ConnectorModel>
  ): Array<ConnectorModel> {
    let _connections: Array<ConnectorModel> = [
      new ConnectorModel({
        name: 'always',
        dest:
          typeof _existingConnections[0] !== 'undefined'
            ? _existingConnections[0].dest
            : null,
        source: { connectorIndex: 0, nodeID: this.nodeData.id },
        customExtensions: null,
      }),
    ];

    return _connections;
  }
}
