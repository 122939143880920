import { LogManager, autoinject, bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';

const logger = LogManager.getLogger('OnChatDialog');

@autoinject
export class OnChatDialog {
  private nodeData: NodeModel;
  private existingConnections: Array<ConnectorModel> = [];
  private nodeConfiguration: ZNodeConfig;
  public error: string;
  public title;

  constructor(private dialogController: DialogController) {}

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(
      JSON.stringify(this.nodeConfiguration.nodeDefinition)
    );
    this.title = this.nodeData.name;
    this.existingConnections = this.nodeData.connections;
    this.nodeData.properties.activityTimeout =
      this.nodeData.properties.activityTimeout || 4;
    this.nodeData.properties.nudgeMessageEnabled =
      !!this.nodeData.properties.nudgeMessageEnabled;
    this.nodeData.properties.nudgeMessageInterval =
      this.nodeData.properties.nudgeMessageInterval || 8;
    this.nodeData.properties.nudgeMessageDisconnectTimeout =
      this.nodeData.properties.nudgeMessageDisconnectTimeout || 12;
  }

  public toggleNudgeEnabled(): void {
    this.nodeData.properties.nudgeMessageEnabled =
      !this.nodeData.properties.nudgeMessageEnabled;
  }

  public ok(): void {
    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    this.error = null;

    if (this.nodeData.properties.nudgeMessageEnabled) {
      if (
        !this.nodeData.properties.nudgeMessage ||
        this.nodeData.properties.nudgeMessage.length === 0
      ) {
        this.error = 'Please enter a message.';
        return;
      }

      const validContentRegex = /^[A-Za-z\s,.-?!]+$/;
      const message = this.nodeData.properties.nudgeMessage;

      if (!validContentRegex.test(message)) {
        this.error =
          'Message can only contain letters, hyphens, commas, question marks, explamation marks, full stops, and spaces.';
        return;
      }
    } else {
      this.nodeData.properties.nudgeMessage = null;
      this.nodeData.properties.nudgeMessageInterval = null;
      this.nodeData.properties.nudgeMessageDisconnectTimeout = null;
    }

    const nudgeTimeout = parseInt(
      this.nodeData.properties.nudgeMessageInterval,
      10
    );
    if (isNaN(nudgeTimeout)) {
      this.nodeData.properties.nudgeMessageInterval = 8;
    } else {
      this.nodeData.properties.nudgeMessageInterval = nudgeTimeout;
    }

    const timeoutValue = parseInt(
      this.nodeData.properties.nudgeMessageDisconnectTimeout,
      10
    );
    if (isNaN(timeoutValue)) {
      this.nodeData.properties.nudgeMessageDisconnectTimeout = 12;
    } else {
      this.nodeData.properties.nudgeMessageDisconnectTimeout = timeoutValue;
    }
    const finalConnections: Array<ConnectorModel> = this.mapConnectors(
      this.existingConnections
    );
    this.nodeData.connections = finalConnections;
    this.nodeData.outputConnectors = finalConnections;
    this.nodeData.properties.isDefined = true;

    this.dialogController.ok(new NodeModel(this.nodeData));
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public mapConnectors(
    _existingConnections: Array<ConnectorModel>
  ): Array<ConnectorModel> {
    const _connections: Array<ConnectorModel> = [
      new ConnectorModel({
        name: 'always',
        dest:
          typeof _existingConnections[0] !== 'undefined'
            ? _existingConnections[0].dest
            : null,
        source: { connectorIndex: 0, nodeID: this.nodeData.id },
        customExtensions: null,
      }),
      new ConnectorModel({
        name: 'Timeout',
        dest:
          typeof _existingConnections[1] !== 'undefined'
            ? _existingConnections[1].dest
            : null,
        source: { connectorIndex: 1, nodeID: this.nodeData.id },
        customExtensions: null,
      }),
    ];
    return _connections;
  }
}
