import {BaseModel} from 'zailab.abstract';

export class DiallerLoginStatusModel extends BaseModel {

  public memberId: string = null;
  public loggedIn: boolean = null;
  public campaignId: string = null;

  constructor(routingStatus: any) {
    super();
    this.mapProperties(routingStatus);
  }
}