import { INTERACTION_ACTIONS } from './../../interaction/interaction-actions';
import { inject, LogManager, customElement, bindable } from 'aurelia-framework';

import { Event, SessionStore } from 'zailab.common';
import { InteractionModel } from '../../interaction-model';
import { EmailInteractionCardService } from './email-interaction-card-service';
import { DownloadService } from '../../../../../_common/services/download.service';
import { ConversationModel } from '../../conversation-model';
import { computedFrom } from "aurelia-binding";
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { CONVERSATION_ACTIONS } from '../../../contact/contactcontroller/contact-controller-actions';
import { WaypointModel } from '../../waypoint-model';
import { InteractionService } from '../../interaction/interaction-service';
import { InteractionService as OtherInteractionService } from '../../../interactions/interaction-service';
import { ConversationService } from '../../conversation-service';
import { MemberInteractionModel } from '../../interaction/member-interaction-model';
import { DispositionCodesService } from '../../../organisation/dispositioncodes/disposition-codes-service';
import { Disposition, DispositionCode, DefaultDispositionCode, DispositionModel } from '../../../organisation/dispositioncodes/disposition-codes-model';
import { ContactModel } from '../../../contact/contact-model';

const logger = LogManager.getLogger('EmailInteractionCard');

@inject(
  Element,
  SessionStore,
  DownloadService,
  EmailInteractionCardService,
  EventAggregator,
  InteractionService,
  OtherInteractionService,
  ConversationService,
  DispositionCodesService
)
@customElement('z-email-interaction-card')
export class EmailInteractionCard {
  @bindable private interaction: InteractionModel;
  @bindable private contactid: string;
  @bindable public contact: ContactModel;
  @bindable private correlationid: string;
  @bindable private unlinked: boolean;
  @bindable private conversations: ConversationModel[];
  private downloadedAll: boolean = false;
  private showEmailRecipients: boolean = false;
  public additionalDataVisible: boolean = false;

  private isInteractionEnded: boolean = true;
  public disposition: string = null;
  public dispositionCode: DefaultDispositionCode = null;
  public showDispositionCodeSelectButton: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private element: Element,
    private sessionStore: SessionStore,
    private downloadService: DownloadService,
    private emailInteractionCardService: EmailInteractionCardService,
    private eventAggregator: EventAggregator,
    private interactionService: InteractionService,
    private otherInteractionService: OtherInteractionService,
    private conversationService: ConversationService,
    private dispositionCodesService: DispositionCodesService
  ) { }

  public bind(): void {
    this.updateInteractionDisplay();

    this.subscriptions.push(this.eventAggregator.subscribe('select.disposition', (dispositionCode: DispositionCode) => {
      this.updateInteractionDisplay();
    }));

    this.subscriptions.push(this.eventAggregator.subscribe('end-interaction', (interactionId) => {
      if (!this.isInteractionEnded && interactionId === this.interaction.interactionId) {
        this.isInteractionEnded = true;
      }
    }));
  }

  public attached(): void {
    const interactionId = this.interaction.interactionId;

    const interactionSubscription = this.eventAggregator.subscribe(
      'connected-interaction-' + interactionId,
      (interaction: any) => {
        if (!this.interaction) {
          return interactionSubscription && interactionSubscription.dispose();
        }
        if (interaction.interactionId !== interactionId) {
          return;
        }
        this.interaction.isLive = true;
      }
    );
    const connectedInteractionSubscription = this.eventAggregator.subscribe(
      'connection-interaction-updated',
      data => {
        if (!this.interaction) {
          return connectedInteractionSubscription && connectedInteractionSubscription.dispose();
        }
        if (this.interaction.isLive) {
          let isLiveInteraction = data && data.interactions && data.interactions.find(interaction => interaction.interactionId === this.interaction.interactionId);

          if (!isLiveInteraction) {
            this.interaction.isLive = false;
            this.eventAggregator.publish('cc-interaction-ended', { interactionId: this.interaction.interactionId, contactId: this.contactid, correlationId: this.contact.correlationId })
          }
        }
      }
    );
    this.eventAggregator.publish('request-connected-interactions', this.interaction.interactionId);
  }

  public interactionChanged(newValue: any, oldValue: any): void {

    if (!newValue || !oldValue || newValue.interactionId === oldValue.interactionId) {
      return;
    }

    this.updateInteractionDisplay();
  }

  private updateInteractionDisplay(): void {
    this.showDispositionCodeSelectButton = false;
    this.conversationService.retrieveInteraction(this.interaction.interactionId)
      .then((interaction) => {
        this.disposition = interaction.metaData.disposition;

        if (interaction.agent) {
          this.interaction.agent = interaction.agent;
        }

        const metaData = interaction.metaData as any;
        if (metaData.serviceId && metaData.direction) {
          this.interaction.metaData = metaData;
          let organisationId = this.sessionStore.get.organisation.organisationId;
          let serviceId = metaData.serviceId;
          let direction = metaData.direction === 'INBOUND' ? 'Inbound' : 'Outbound';
          let channel = 'Email';
          this.dispositionCodesService.retrieveDispositionListByServiceChannelAndDirection(organisationId, serviceId, channel, direction, metaData.tiered)
            .then((dispositionList: DispositionModel) => {
              this.showDispositionCodeSelectButton = dispositionList && dispositionList.dispositionCodes && dispositionList.dispositionCodes.length > 0;
              if (!this.disposition && this.showDispositionCodeSelectButton) {
                this.eventAggregator.publish('select-disposition-code:enabled' + this.interaction.conversationId);
              }
            });
          }
    });
  }

  public selectDispositionCode(): void {
    this.eventAggregator.publish('member.select.disposition.code', {
      interactionId: this.interaction.interactionId,
      correlationId: this.correlationid
    });
  }

  public showAdditionalData(): void {
    this.additionalDataVisible = true;
  }

  @computedFrom('dispositionCode')
  public get dispositionTitle(): string {
    if (!this.interaction.dispositionCode) {
      return '';
    }
    let title = `Outcome: ${this.interaction.dispositionCode.conversationOutcome}\nReason: ${this.interaction.dispositionCode.interactionOutcomeReason}`;
    return title;
  }

  @computedFrom('dispositionCode')
  public get dispositionColor(): string {
    if (!this.interaction.dispositionCode) {
      return '';
    }
    return this.interaction.dispositionCode.isNegative ? 'var(--unsuccessful)' : this.interaction.dispositionCode.isPositive ? 'var(--successful)' : '';
  }

  private downloadAttachment(attachment: any): void {
    attachment.downloaded = true;
    this.emailInteractionCardService.downloadAttachment(this.interaction.emailId, attachment).then(
      (response) => {
        this.download(attachment.fileName, response);
      },
      (error) => {
        logger.info('Error > Downloading >', error);
      }
    );
  }

  private download(fileName: string, url: string): void {
    this.downloadService.downloadWithoutAuthAndEndpoint(fileName, url).then(response => {
    });
  }

  private downloadAll(): void {
    this.downloadedAll = true;
    this.interaction.attachmentDetails.forEach((attachment: any) => {
      this.emailInteractionCardService.downloadAttachment(this.interaction.emailId, attachment).then(
        (response) => {
          this.download(attachment.fileName, response);
        },
        (error) => {
          logger.warn('Error > Downloading >', error);
        }
      );
    });
  }

  public colorChanged(data: { timeInSeconds: number, color: string }): void {

    new Event(this.element, 'interaction-color-change', {
      contactId: this.contact.contactId,
      correlationId: this.contact.correlationId || this.interaction.correlationId,
      color: data.color,
      timeInSeconds: data.timeInSeconds,
      interactionId: this.interaction.interactionId
    });
  }

  @computedFrom('contactid', 'interaction')
  private get agentName(): string {
    const user = this.sessionStore.get.user;
    if (user && !this.contactid) {
      return `${user.firstName} ${user.surname}`;
    }
    return this.interaction.agentName;
  }

  private toggleRecipients(event: Event): void {
    event.stopPropagation();
    this.showEmailRecipients = !this.showEmailRecipients;
  }

  public forwardEmail(): void {
    let newTo = '';
    let { message, subject, attachmentDetails, emailId, from, to, relativeTime } = this.interaction;
    let newSubject = `FW: ${subject}`;
    attachmentDetails = attachmentDetails.map((attachmentDetail: any) => {
      if (!attachmentDetail.originalEmailId) {
        attachmentDetail.originalEmailId = emailId;
      }
      return attachmentDetail;
    });
    this.eventAggregator.publish(CONVERSATION_ACTIONS.INTERACTIONS_EMAIL_FORWARD, { message, newSubject, subject, newTo, to, from, attachmentDetails, relativeTime });
  }

  public endInteraction(): void {
    this.isInteractionEnded = true;
    this.interactionService.endInteraction(this.interaction);
  }

  public replyToAll(): void {
    let newTo = '';
    let { message, subject, attachmentDetails, emailId, from, to, relativeTime, ccs, bccs } = this.interaction;
    let newSubject = subject;
    attachmentDetails = attachmentDetails.map((attachmentDetail: any) => {
      if (!attachmentDetail.originalEmailId) {
        attachmentDetail.originalEmailId = emailId;
      }
      return attachmentDetail;
    });
    this.eventAggregator.publish(CONVERSATION_ACTIONS.INTERACTIONS_EMAIL_REPLY_ALL, { message, newSubject, subject, newTo, to, from, attachmentDetails, relativeTime, ccs, bccs });
  }

  public openLink(url: string) {
    this.eventAggregator.publish('interaction-link-open', {
      firstName: this.contact.firstName,
      surname: this.contact.surname,
      remote: this.interaction.from,
      url: url,
      interactionId: this.interaction.interactionId
    });
    this.eventAggregator.publish('dock-cards');
  }

  public detached(): void {
    this.subscriptions.forEach(subscription => subscription.dispose());
  }
}
