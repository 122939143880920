import { LogManager, autoinject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { Router } from 'aurelia-router';

import { PlaceholderService } from '../../../../../_common/services/placeholder-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AbstractList, List } from 'zailab.abstract';
import { ArrayTools, MESSAGE_EVENTS } from 'zailab.common';
import { AddAssistantDialog } from '../add-assistant-dialog/add-assistant-dialog';
import { AssistantService } from '../assistants-service';
import { ZIOplog } from '../../../../../../typings/zai/zai.common';
import { AssistantModel } from '../assistant-model';
import { ConfirmDialog } from '../../../../../components_v2/confirm-dialog/confirm-dialog';

const logger = LogManager.getLogger('Integrations List');

@autoinject()
export class IntegrationsList extends AbstractList {
  public loading: boolean;
  public itemList: any;
  public placeholders: number = 0;
  private oplogs = {};
  public actions = [{
    action: 'view',
    label: 'View'
  }, {
    action: 'reset',
    label: 'Reset',
    icon: 'refresh'
  }];

  constructor(
    private dialogService: DialogService,
    private assistantService: AssistantService,
    private router: Router,
    eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
  }

  public activate(): void {
    this.retrieveAssistants();
  }

  private async retrieveAssistants(): Promise<void> {
    const assistants = await this.assistantService
      .retrieveAssistants()
      .catch((e) =>
        logger.error(' > Failed to retrieve assistants due to', e)
      );
    const mappedAssistants = assistants.map(assistant => new AssistantModel(
      assistant.assistantId,
      assistant.name,
      assistant.description,
      assistant.greeting,
      assistant.purpose,
      assistant.status,
      assistant.assignedToFlow
    ));
    this.setupListView(mappedAssistants ? ArrayTools.sort(mappedAssistants, 'name') : []);
  }

  private setupListView(shiftTemplates: any[]): void {
    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableClick()
      .enableAdd()
      .enableDelete()
      .items(shiftTemplates)
      .uniqueIdentifier('id')
      .hasRollover(false)
      .tilesPerRow(5)
      .disableOrderBy()
      .build();
    this.generatePlaceholders();
    super.hideLoader();
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(
      this.container,
      this.itemList.items.length,
      3,
      (placeholders) => {
        this.placeholders = placeholders;
      }
    );
    if (this.placeholderService) {
      //@ts-ignore
      this.placeholders = this.placeholderService.generatePlaceholders(
        this.itemList.items.length
      );
    }
  }


  public addAssistant(): void {
    this.dialogService
      .open({
        viewModel: AddAssistantDialog,
        model: this.itemList.items.map(item => item.name),
      })
      .whenClosed(
        // @ts-ignore
        (
          response: {
            output: {
              id: string,
              name: string,
              description: string,
              purpose: string,
              greeting: string,
              token: string
            };
            wasCancelled: boolean
          }
        ) => this.handleAssistantAdded(response));
  }

  private handleAssistantAdded(
    response: {
      output: {
        id: string,
        name: string,
        description: string,
        purpose: string,
        greeting: string,
        token: string
      };
      wasCancelled: boolean
    }
  ): void {
    if (response.wasCancelled) {
      return;
    }
    this.addAssistantOplog(response.output.id);
    this.itemList.items.push(new AssistantModel(
      response.output.id,
      response.output.name,
      response.output.description,
      response.output.greeting,
      response.output.purpose,
      'pending'
    ));
    ArrayTools.sort(this.itemList.items, 'name');
    this.generatePlaceholders();
  }

  private addAssistantOplog(id: string): void {
    let oplog: ZIOplog = this.assistantService.subscribeToAssistantChanges(id);
    oplog.on('insert', response => this.findAndUpdateAssistant(response));
    oplog.on('update', response => this.findAndUpdateAssistant(response));
    this.oplogs[id] = oplog;
  }

  public unsubscribeOplog(id: string): void {
    if (this.oplogs[id]) {
      this.oplogs[id].unsubscribe();
      delete this.oplogs[id];
    }
  }

  private findAndUpdateAssistant(data: { [key: string]: string }): void {
    let item = this.itemList.items.find(
      (item) => item.assistantId === data._id
    );
    if (item) {
      item.status = data.status;
    }
  }
  
  public select(item): void {
    if (
      !item.assignedToFlow &&
      this.itemList.isDeleting
    ) {
      this.itemList.selectToDelete(item);
    } else {
      this.router.navigate(`${item.assistantId}`);
    }
  }

  public showResetConfirmation(item): void {
    let text = `<div class="width--400">Are you sure you want to reset the AI model? This action will erase all current training data, and the model will need to be retrained.</div>`;
    this.dialogService
      .open({
        viewModel: ConfirmDialog,
        model: {
          header: 'Are you sure?',
          text
        }
      })
      .whenClosed(dialog => {
        if (dialog.wasCancelled) {
          return;
        }
        this.reset(item);
      });
  }

  public reset(item): void {
    this.assistantService.resetAssistant(item.assistantId)
    .then(() => {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Assistant ${item.name} has been successfully reset.`);
    })
    .catch(e => {
      logger.error(' > Failed to reset the Assistant due to', e);
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, `Failed to reset Assistant ${item.name}.`);
    });
  }

  public async delete(): Promise<void> {
    super.showLoader();
    let itemsToDelete = [];
    const deletePromises: Promise<void>[] = this.itemList.items
      .filter(item => {
        if (item.isDeleting) {
          itemsToDelete.push(item);
          return item.isDeleting;
        }
        return false;
      })
      .map(item => this.confirmDelete(item));
  
    try {
      await Promise.all(deletePromises);
      this.itemList.toggleDelete();
      if (deletePromises.length === 1) {
        this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Assistant ${itemsToDelete[0].name} deleted.`);
      } else if (deletePromises.length > 1) {
        this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `${itemsToDelete.length} Assistants deleted.`);
      }
    } catch (error) {
      logger.error(' > Error during delete operation:', error);
    } finally {
      super.hideLoader();
    }
  }
  
  private async confirmDelete(item): Promise<void> {
    try {
      await this.assistantService.deleteAssistants(item.assistantId);
      this.itemList.items.splice(this.itemList.items.indexOf(item), 1);
      this.generatePlaceholders();
    } catch (e) {
      logger.error(' > Failed to remove asssitant due to', e);
      throw e;
    }
  }
  

  public navigateToAuditView(): void {
    this.router.navigate('audit');
  }

  public deactivate(): void {
    const keys = Object.keys(this.oplogs);
    keys.forEach(key => this.unsubscribeOplog(key));
  }
}
