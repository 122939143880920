import {LogManager} from 'aurelia-framework';

import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('ServiceModel');

export class ServiceModel extends BaseModel {

    public selected: boolean = null;
    public id: string = null;
    public name: string = null;

    constructor(service?: ServiceModel) {
        super();
        super.mapProperties(service);
    }
}