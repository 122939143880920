import {HttpClient} from 'aurelia-http-client';
import {autoinject, LogManager} from 'aurelia-framework';
import {InteractionModel} from '../../interaction-model';

const logger = LogManager.getLogger('EmailInteractionCardService');

@autoinject()
export class EmailInteractionCardService {
    constructor(private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    public downloadAttachment(emailId: string, attachment: Object): Promise<string> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .createRequest(`email/${emailId}/attachment/${attachment.attachmentId}`)
                .asGet()
                .withParams({fileName: attachment.fileName})
                .send()
                .then(
                    response => {
                        resolve(response.url);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    public selectInteraction(interactionId: string): Promise<InteractionModel[]> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .createRequest(`v1/organisation/interactions/${interactionId}`)
                .asGet()
                .send()
                .then(
                    response => {
                        if (!response) {
                            resolve([]);
                        } else {
                            resolve(response);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }
}
