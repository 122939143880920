import { autoinject } from 'aurelia-dependency-injection';

import { ZaiForm, ArrayTools, SessionStore } from 'zailab.common';
import { InAppAlertsService } from '../../../../in-app-alerts-service';
import { WorkTypesService } from '../../../../../conversation/worktypes/work-types-service';
import { TeamsService } from '../../../../../member/teams/teams-service';
import { MembersService } from '../../../../../member/members/members-service';

import './tab-3-trigger.scss';

export interface ITab3Trigger {
  id: string;
  source: string;
  conditions: any[];
  conditionsIds: any[];
  audienceType: string;
  fields: string[];
  triggers: { field: string; operator: string; threshold: string }[];
  mappedTriggers: { field: string; operator: string; threshold: string }[];
  valid?: boolean;
}
enum operatorMap {
  '<' = 'LT',
  '<=' = 'LTE',
  '>' = 'GT',
  '>=' = 'GTE',
  '=' = 'EQ',
  '≠' = 'NE',
}
enum reverseOperatorMap {
  'LT' = '<',
  'LTE' = '<=',
  'GT' = '>',
  'GTE' = '>=',
  'EQ' = '=',
  'NE' = '≠',
}
enum conditionTypes {
  worktype = 'WORKTYPE',
  member = 'MEMBER',
  team = 'TEAM',
}

@autoinject()
export class Tab3Triggers {
  public form: any[][];
  public formData: {
    source: string;
    conditions: any[];
    triggers: any[];
    mappedTriggers: any[];
  };
  public loading: boolean;
  private model: { data: ITab3Trigger };

  public tableColumns = ['Field', 'Operator', 'Value'];
  public tableTypes ;
  public tableTypesDefault = [
    {
      type: 'dropdown',
      options: [],
      direction: 'top',
      validation: 'required',
      uniqueKey: false,
      displayField: 'label'
    },
    {
      type: 'dropdown',
      options: ['>', '<', '=', '>=', '<=', '≠'],
      direction: 'top',
      validation: 'required',
    },
    {
      type: 'text',
      validation: 'required',
    },
  ];
  public tableData = [];
  public fields;
  public options: any[];
  public teamOptions: any[];
  public allTeams: any[];
  public memberOptions: any[];
  public allMembers: any[];

  private pageNumber = 0;
  private pageSize = 500;
  public showMoreEnabled = true;
  public noMoreMembers = false;
  public searchText = '';

  private sources: any[] = [
    { display: 'Live Dashboard', value: 'LIVE_DASHBOARD' },
    { display: 'CC Monitoring', value: 'CONTACT_CENTER_MONITORING_DASHBOARD' },
  ];
  private workTypes: { templateId: string; templateName: string, disabled?: boolean }[] = [];

  constructor(
    private inAppAlertsService: InAppAlertsService,
    private workTypeService: WorkTypesService,
    private teamService: TeamsService,
    private sessionStore: SessionStore,
    private memberService: MembersService,
  ) {}

  public async activate(bindingContext: { data: ITab3Trigger }): Promise<void> {
    this.model = bindingContext;
    this.loading = true;
  }
  
  public async attached(): Promise<void> {
    await this.initDisplay();
    if (!this.model.data.source) {
      this.model.data.source = 'Live Dashboard';
      this.tableTypes = this.tableTypesDefault;
    }
    this.retrieveFields(this.model.data.source);
    this.generateForm();
  }

  private async initDisplay(): Promise<void> {
    await this.retrieveWorkTypes();
    await this.retrieveTeams();
    await this.retrieveMembers();
    await this.setOptionFields();
    this.populateConditions();
  }

  private async retrieveWorkTypes(): Promise<void> {
    const workTypes = await this.workTypeService
      .retrieveWorkTypes()
      .catch((e) => console.warn(' > Failed to retrieve worktypes due to', e));
    if (workTypes) {
      this.workTypes = ArrayTools.sort(workTypes, 'templateName');
    }
  }

  private async retrieveTeams(): Promise<void> {
    let teamResults = await this.teamService
      .retrieveOrganisationTeams(
        this.sessionStore.get.organisation.organisationId,
      )
      .catch((e) => console.warn(' > Failed to get teams due to', e));
    if (teamResults) {
      this.allTeams = teamResults;
      this.teamOptions = ArrayTools.sort(teamResults.map((data: any) => {
        return {
          value: data.teamId,
          label: data.teamName,
          icon: 'teams',
          group: 'teams'
        };
      }), 'label');
    }
  }

  private async retrieveMembers(searchText?: string): Promise<any[]> {
    let members = await this.memberService
      .retrieveOrganisationMembers(
        null,
        searchText || null,
        null,
        this.pageNumber,
        this.pageSize,
      )
      .catch((e) => console.warn(' > Failed to get members due to', e));

    if (!members) {
      members = [];
    } else if (members.length < this.pageSize) {
      this.showMoreEnabled = false;
    }
    const mappedMembers = members.map((member: any) => {
      return {
        value: member.id,
        label: member.fullName,
        icon: 
          member.roles.length > 0
          ? member.roles[0].toLowerCase().replace('_', '-')
          : 'user',
        group: 'members'
      };
    });

    if (this.pageNumber === 0) {
      this.memberOptions = mappedMembers;
      this.allMembers = members;
    } else {
      this.memberOptions = [].concat(this.memberOptions, mappedMembers);
      this.allMembers = [].concat(this.allMembers, members);
    }

    return mappedMembers;
  }

  private setOptionFields(): void {
    if (this.model.data.audienceType) {
      if (this.model.data.audienceType === 'Members') {
        this.options = this.memberOptions;
      } else if (this.model.data.audienceType === 'Teams') {
        this.options = this.teamOptions;
      }
    } else {
      this.options = this.memberOptions;
    }
  }

  private populateConditions(): void {
    if (
      !this.model.data.conditionsIds ||
      this.model.data.conditionsIds.length === 0
    ) {
      return;
    }
    this.model.data.conditionsIds.forEach((condition) => {
      if (condition.type === conditionTypes.worktype) {
        const wt = this.workTypes.find(
          (wt) => wt.templateId === condition.value,
        );
        if (wt) {
          this.setDisabledOption(wt.templateId);
          if (this.model.data.conditions.indexOf(wt.templateName) === -1) {
            this.model.data.conditions.push(wt.templateName);
          }
        }
      } else if (condition.type === conditionTypes.member) {
        let member = this.allMembers.find(
          (item) => item.memberId === condition.value,
        );
        if (member) {
          this.setDisabledOption(member.memberId);
          if (JSON.stringify(this.model.data.conditions).indexOf(member.memberId) >= 0) {
            return;
          }
          this.model.data.conditions.push({
            value: member.id,
            label: member.fullName,
            icon: 
              member.roles.length > 0
              ? member.roles[0].toLowerCase().replace('_', '-')
              : 'user',
            group: 'members'
          });
        }
      } else if (condition.type === conditionTypes.team) {
        let team = this.allTeams.find(
          (item) => item.teamId === condition.value,
        );
        if (team) {
          this.setDisabledOption(team.teamId);
          if (JSON.stringify(this.model.data.conditions).indexOf(team.teamId) >= 0) {
            return;
          }
          this.model.data.conditions.push({
            value: team.teamId,
            label: team.teamName,
            icon: 'teams',
            group: 'teams'
          });
        }
      }
    });
  }

  private generateForm(): void {
    let form = new ZaiForm()
      .newField()
      .asDropdown()
      .withTitle('Sources', '120px')
      .withFieldWidth('200px')
      .withClass('flex--basis')
      .withIdentifier('source')
      .withDisplayField('display')
      .withOptions(this.sources)
      .withValue(this.model.data.source)
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .insertField();

    if (this.model.data.source) {
      if (this.model.data.source === 'Live Dashboard') {
        form = form
          .newRow()
          .newField()
          .asSearchDropdown()
          .fullWidth()
          .withTitle('Work Types', '120px')
          .withFieldWidth('200px')
          .withClass('flex--basis')
          .withIdentifier('worktype')
          .withDisplayField('templateName')
          .withOptions(this.workTypes)
          .withPlaceholder('Work Type')
          .withValue('')
          .withValidation([
            { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
          ])
          .multiSelect()
          .withItemsIdentifier('selectedWorktypes')
          .withValues(this.model.data.conditions)
          .insertField();
      } else if (this.model.data.source === 'CC Monitoring') {
        let _form = form
          .newRow()
          .newField()
          .asDropdown()
          .fullWidth()
          .withTitle('Search Type', '120px')
          .withFieldWidth('200px')
          .withClass('flex--basis')
          .withIdentifier('audienceType')
          .withDisplayField('')
          .withOptions(['Members', 'Teams'])
          .withValue(this.model.data.audienceType || 'Members')
          .insertField()

          .newRow()
          .newField()
          .asSearchDropdown()
          .fullWidth()
          .withTitle('Search', '120px')
          .withFieldWidth('200px')
          .withClass('flex--basis')
          .withDisplayField('label')
          .withOptions(this.options)
          .withValidation([
            { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
          ])
          .multiSelect(true)
          .withItemsIdentifier('selectedAudiences')
          .withValues(this.model.data.conditions);

        if (!this.model.data.audienceType || this.model.data.audienceType === 'Members' && this.showMoreEnabled) {
          _form = _form.enableShowMore(() => this.showMore(), this.pageSize);
        }
        form = _form.insertField();
      }
    }

    form.finaliseForm((form) => {
      this.form = form;
      this.loading = false;
    });
  }

  private showMore(): Promise<any[]> {
    this.pageNumber++;
    return this.retrieveMembers();
  }

  public async triggerFormUpdate(data: any): Promise<void> {
    this.formDataChanged({
      ...data,
      mappedTriggers: this.model.data.mappedTriggers,
    });
  }

  public async formDataChanged(data: any): Promise<void> {
    if (!data) {
      return;
    }
    this.formData = data;
    let formChanged = false;
    let sourceChanged = false;

    if (data.source === 'Live Dashboard') {
      data.selectedAudiences = [];
    } else {
      data.selectedWorktypes = [];
    }
    if (
      !this.model.data.audienceType ||
      (this.model.data.audienceType &&
        this.model.data.audienceType !== data.audienceType)
    ) {
      this.model.data.audienceType = data.audienceType;
      if (this.model.data.audienceType === 'Teams') {
        this.options = this.teamOptions;
      } else if (this.model.data.audienceType === 'Members') {
        this.options = this.memberOptions;
      }

      formChanged = true;
    }
    
    this.workTypes.forEach(wt => wt.disabled = false);
    if (data.selectedWorktypes && data.selectedWorktypes.length > 0) {
      this.model.data.conditionsIds.length = 0;
      data.selectedWorktypes.forEach((condition) => {
        let worktype = this.workTypes.find(
          (wt) => wt.templateName === condition,
        );
        if (worktype) {
          worktype.disabled = true;
          this.model.data.conditionsIds.push({
            type: 'WORKTYPE',
            value: worktype.templateId,
          });
        }
      });
    }
    await Promise.all(this.options.map(async (option) => option.disabled = false));
    if (data.selectedAudiences && data.selectedAudiences.length > 0) {
      this.model.data.conditionsIds.length = 0;
      data.selectedAudiences.forEach((condition) => {
        let member = this.allMembers.find(
          (item) => item.memberId === condition.value,
        );
        if (member) {
          this.setDisabledOption(member.id);
          this.model.data.conditionsIds.push({
            type: 'MEMBER',
            value: member.memberId,
          });
        } else {
          let team = this.allTeams.find(
            (item) => item.teamId === condition.value,
          );
          if (team) {
            this.setDisabledOption(team.id);
            team.disabled = true;
            this.model.data.conditionsIds.push({
              type: 'TEAM',
              value: team.teamId,
            });
          }
        }
      });
    }

    if (
      (!data.selectedWorktypes || data.selectedWorktypes.length === 0) &&
      (data.selectedAudiences && data.selectedAudiences.length === 0) ||
      (data.conditions && data.conditions.length === 0)
    ) {
      this.model.data.conditions = [];
      this.model.data.conditionsIds = [];
    }

    if (
      data.source &&
      this.model.data.conditionsIds && this.model.data.conditionsIds.length > 0 &&
      this.model.data.mappedTriggers.length > 0
    ) {
      this.model.data.valid = true;
    } else {
      this.model.data.valid = false;
    }

    if (data.source && this.model.data.source !== data.source) {
      this.model.data.mappedTriggers = [];
      this.model.data.triggers = [];
      this.tableData = [];
      this.model.data.conditions = [];
      this.model.data.conditionsIds = [];
      formChanged = true;
      sourceChanged = true;
      this.model.data.valid = false;
    }
    this.model.data.source = data.source;

    if (formChanged) {
      if (sourceChanged) {
        await this.retrieveFields(data.source);
      }
      this.generateForm();
    }
  }

  private setDisabledOption(id: string): void {
    let selectedOption = this.options.find(option => option.value === id)
    if (selectedOption) {
      selectedOption.disabled = true;
    }
  }

  private async retrieveFields(_source?: string): Promise<void> {
    if ((!this.formData || !this.formData.source) && !_source) {
      return;
    }
    const source = this.sources.find(
      (s) => s.display === _source,
    );
    this.fields = await this.inAppAlertsService
      .retrieveFields(source.value)
      .catch((e) => console.warn(' > Failed to retrieve fields due to', e));

    if (this.fields && Array.isArray(this.fields)) {
      this.setTableFields();
      this.tableTypes = this.tableTypesDefault;
    }

    if (this.model.data.mappedTriggers) {
      this.model.data.mappedTriggers.forEach((trigger) => {
        let description = this.fields.find(
          (field) =>
            field.name === trigger.field ||
            field.description === trigger.field,
        ).description;

        if (JSON.stringify(this.tableData).indexOf(description) >= 0) {
          return;
        }
        this.tableData.push([
          description,
          reverseOperatorMap[trigger.operator],
          trigger.threshold,
        ]);
      });
    }
    this.generateForm();
  }

  private setTableFields(): void {
    const fields = ArrayTools.sort(this.fields.map((field) => {
      return {
        label: field.description,
        disabled: !!this.model.data.mappedTriggers.find(trigger => trigger.field === field.name)
      }
    }), 'label');
    this.tableTypesDefault[0].options = fields;
  }

  public tableDataUpdated(data: any): void {
    this.model.data.triggers.length = 0;
    for (let i = this.model.data.triggers.length - 1; i >= 0; i--) {
      this.model.data.triggers.pop();
    }

    this.model.data.mappedTriggers.length = 0;
    for (let i = this.model.data.mappedTriggers.length - 1; i >= 0; i--) {
      this.model.data.mappedTriggers.pop();
    }
    data.forEach((item) => {
      this.model.data.triggers.push({
        field: item[0],
        operator: item[1],
        threshold: item[2],
      });
      // todo: fix issue where fields are undefined for cc monitoring
      this.model.data.mappedTriggers.push({
        field: this.fields.find((field) => field.description === item[0]).name,
        operator: operatorMap[item[1]] || item[1],
        threshold: item[2],
      });
    });
    this.setTableFields();
    this.formDataChanged(this.model.data);
  }
}
