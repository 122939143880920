import { FeatureFlagService } from './../../../../../../featureflags/feature-flag-service';
import { autoinject } from "aurelia-dependency-injection";
import { bindable, customElement, LogManager } from "aurelia-framework";
import { SearchTools, SessionStore } from "zailab.common";
import { TelephonyService } from "../../../../../../telephony/telephony-service";
import { ContactModel } from "../../../../../contact/contact-model";
import { TransferList, TransferRecipient } from "../../../../../transferlist/transfer-list-model";
import { InteractionModel } from "../../../../interaction-model";
import { MemberModel } from "../../../../member-model";

const logger = LogManager.getLogger('TransferToTransferList');

@autoinject
@customElement('z-transfer-to-transfer-list')
export class TransferToTransferList {
  private consultStateKey = 'consultStateTransferList';

  @bindable public interaction: InteractionModel;
  @bindable public contact: ContactModel;
  @bindable({ attribute: 'is-active' }) public isActive: boolean;

  public conferenceIsEnabled: boolean = null;

  public searchText: string;
  public searchTextRecipient: string;
  public selectedTransferList: TransferList;
  public selectedRecipient: TransferRecipient;
  public displaySearchResults: boolean;
  public displaySearchResultsForRecipients: boolean;
  
  private autoCloseTimer: any;
  private jsSearchInput: HTMLElement;
  private attemptingConsult: boolean;
  public consultAttempted: boolean;
  private attemptingTransfer: boolean;
  private attemptingConnectParties: boolean;
  private attemptingCancelConsult: boolean;
  public gettingMembers: boolean = false;
  public isValidManualNumber: boolean = true;

  public attemptingAddToConference: boolean;
  
  private attemptTime: number = 5000;
  
  public showMoreEnabled: boolean = true;
  private pageNumber: number = 0;
  private pageSize: number = 20;

  constructor(
    private sessionStore: SessionStore,
    private telephonyService: TelephonyService,
    private featureFlagService: FeatureFlagService
  ) {}

  public async bind(): Promise<void> {
    this.conferenceIsEnabled = await this.featureFlagService.isEnabled('ConferenceIsEnabled');
    const consultState = this.getConsultState();
    this.selectedRecipient = consultState.selectedRecipient || null;
    this.searchText = consultState.searchText || '';
  }

  public showTransferList(event: Event): void {
    event.stopPropagation();
    this.isActive = true;
  }
  
  public hideTransferList(event: Event): void {
    event.stopPropagation();
    this.selectedTransferList = null;
    this.selectedRecipient = null;
    this.searchText = '';
    this.searchTextRecipient = '';
    this.isActive = false;
    this.hideSearchResults();
  }

  public hideSearchResults(): void {
    this.displaySearchResults = false;
  }

  public startAutoCloseMenu(_delay: number): void {
    this.autoCloseTimer = setTimeout(() => {
      if (this.jsSearchInput === document.activeElement) {
        return;
      }
      this.displaySearchResults = false;
    }, _delay);
  }

  public preventAutoClose(): void {
    window.clearTimeout(this.autoCloseTimer);
  }

  public toggleSearchResults(event: Event): void {
    event.stopPropagation();

    if (this.gettingMembers) {
      return;
    }
    this.displaySearchResults = true;
    this.pageNumber = 0;

    if (this.displaySearchResults) {
      this.gettingMembers = true;
      let searchText = this.searchText || (this.selectedRecipient ? this.selectedRecipient.name + ' ' + this.selectedRecipient.surname : '');
      this.contact
        .retrieveOrganisationMembers(searchText, this.pageNumber, this.pageSize)
        .then(() => 
          setTimeout(() => {
            this.gettingMembers = false;
          }, 1000)
        );
    }
  }

  public toggleSearchResultsForRecipients(event: Event): void {
    event.stopPropagation();
    this.displaySearchResultsForRecipients = !this.displaySearchResultsForRecipients;
  }
  
  public selectTransferList(transferList: TransferList, event?: Event): void {
    event && event.stopPropagation();
    this.selectedTransferList = transferList;
    this.searchText = transferList.name;
    this.displaySearchResults = false;
    this.displaySearchResultsForRecipients = false;
  }
  
  public selectTransferRecipient(recipient: TransferRecipient, event?: Event): void {
    event && event.stopPropagation();
    this.selectedRecipient = recipient;
    this.searchTextRecipient = recipient.title;
    this.displaySearchResultsForRecipients = false;
    this.isValidManualNumber = true;
  }

  public inputValueChanged(): void {
    this.selectedTransferList = null;
    this.selectedRecipient = null;
    this.displaySearchResults = false;
    this.displaySearchResultsForRecipients = false;
  }

  private debounceTimeout = null;
  public inputValueChangedRecipient(searchTextRecipient: string): void {
    this.selectedRecipient = null;
    this.displaySearchResultsForRecipients = false;

    if (this.debounceTimeout !== null) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    }

    this.debounceTimeout = setTimeout(() => {
      this.debounceTimeout = null;
      
      if (searchTextRecipient && this.isValidNumber(searchTextRecipient)) {
        this.isValidManualNumber = true;
      } else {
        this.isValidManualNumber = false;
      }
    }, 300);
  }
  
  private isValidNumber(searchCriteria: string): boolean {
    const phoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/gm;
    const extensionRegex = /^[0-9]+$/;
    const pbxIntegrationRegex = /^\*9[0-9]+$/;
    if (
      phoneNumberRegex.test(searchCriteria.replace(/ /g,'')) || 
      extensionRegex.test(searchCriteria.replace(/ /g,'')) || 
      pbxIntegrationRegex.test(searchCriteria.replace(/ /g,''))) {
      return true;
    }
    return false
  }

  
  public consult(event: Event): void {
    event.stopPropagation();

    if (this.attemptingConsult) {
      return;
    }

    if (this.selectedRecipient) {
      this.storeConsultState({ selectedRecipient: this.selectedRecipient });
      this.telephonyService.consultDestination(this.interaction.interactionId, this.selectedRecipient.contactNumber, this.sessionStore.get.user.memberId);
    } else if(this.isValidManualNumber) {
      this.storeConsultState({ searchText: this.searchText });
      this.telephonyService.consultDestination(this.interaction.interactionId, this.searchTextRecipient, this.sessionStore.get.user.memberId);
    }
    this.notifyConsultAttempt();
  }
  private notifyConsultAttempt(): void {
    this.attemptingConsult = true;
    this.consultAttempted = true;
    setTimeout(() => {
      this.attemptingConsult = false;
    }, this.attemptTime);
  }

  private storeConsultState(state: any): void {
    const storedState = JSON.parse(localStorage.getItem(this.consultStateKey) || '{}');
    const newState = { ...storedState, ...state };
    localStorage.setItem(this.consultStateKey, JSON.stringify(newState));
  }

  private getConsultState(): any {
    return JSON.parse(localStorage.getItem(this.consultStateKey) || '{}');
  }

  private clearConsultState(): void {
    localStorage.removeItem(this.consultStateKey);
  }
  
  public transfer(event: Event): void {
    event.stopPropagation();
    
    if (this.attemptingTransfer) {
      return;
    }

    if (this.selectedRecipient) {
      const contactNumber = this.extractPhoneNumber(this.selectedRecipient.contactNumber);
      this.telephonyService.transfer(this.interaction.interactionId, contactNumber, this.sessionStore.get.user.memberId);
    } else if(this.isValidManualNumber) {
      const contactNumber = this.extractPhoneNumber(this.searchTextRecipient);
      this.telephonyService.transfer(this.interaction.interactionId, contactNumber, this.sessionStore.get.user.memberId);
    }
    this.notifyTransferAttempt();
  }
  private notifyTransferAttempt(): void {
    this.attemptingTransfer = true;
    setTimeout(() => {
      this.attemptingTransfer = false;
    }, this.attemptTime);
  }

  private extractPhoneNumber(input) {
    const phonePattern = /(\+?\d+)/;
    const match = input.match(phonePattern);
    return match ? match[0] : null;
  }

  public connectParties(event: Event): void {
    event.stopPropagation();
    
    if (this.attemptingConnectParties || this.interaction.conference) {
      return;
    }
    
    if (this.selectedRecipient) {
      this.telephonyService.connectDestination(this.interaction.interactionId, this.selectedRecipient.contactNumber, this.sessionStore.get.user.memberId);
      this.clearConsultState();
    } else if(this.isValidManualNumber) {
      this.telephonyService.connectDestination(this.interaction.interactionId, this.searchTextRecipient, this.sessionStore.get.user.memberId);
      this.clearConsultState();
    }

    this.notifyConnectPartiesAttempt();
  }
  private notifyConnectPartiesAttempt(): void {
    this.attemptingConnectParties = true;
    setTimeout(() => {
      this.attemptingConnectParties = false;
    }, this.attemptTime);
  }

  public addToConference(event: Event): void {
    event.stopPropagation();

    if (this.attemptingAddToConference) {
      return;
    }
    
    this.notifyAddToConference();
    if (this.selectedRecipient) {
      this.telephonyService
        .addToConference(this.sessionStore.get.organisation.organisationId, this.interaction.interactionId, this.sessionStore.get.user.memberId)
        .catch(error => logger.warn(' > Failed to add to conference due to', error));
    } else {
      this.telephonyService
        .addToConference(this.sessionStore.get.organisation.organisationId, this.interaction.interactionId, null, this.searchText)
        .catch(error => logger.warn(' > Failed to add to conference due to', error));
    }
    this.clearConsultState();
  }
  private notifyAddToConference(): void {
    this.attemptingAddToConference = true;
    this.consultAttempted = false;
    setTimeout(() => {
      this.attemptingAddToConference = false;
    }, this.attemptTime);
  }

  public cancelConsult(event: Event): void {
    event.stopPropagation();

    if (this.attemptingCancelConsult) {
      return;
    }
    
    this.telephonyService.cancelConsultDestination(this.interaction.interactionId, this.selectedRecipient.contactNumber, this.sessionStore.get.user.memberId);
    this.notifyCancelConsultAttempt();
    this.clearConsultState();
  }
  private notifyCancelConsultAttempt(): void {
    this.attemptingCancelConsult = true;
    this.consultAttempted = false;
    setTimeout(() => {
      this.attemptingCancelConsult = false;
    }, this.attemptTime);
  }

  protected partialMatch(searchExpression: any, value: any, searchParam: any): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}
