import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Container, LogManager, computedFrom } from 'aurelia-framework';

import { ContactService } from '../../contact/contact-service';
import { ConversationService } from '../conversation-service';
import { INTERACTION_ACTIONS } from './interaction-actions';
import { ZIOplog } from '../../../../../typings/zai/zai.common';
import { WaypointModel } from '../waypoint-model';

import moment from 'moment';
import { observable } from 'aurelia-binding';

const logger = LogManager.getLogger();

interface IIncomingTicket {
  src: string;
  dst: string;
  type: string;
  isServed: boolean;
  payload: {
    interactionId: string;
    sourceNumber: string;
    taskName: string;
    contact: {
      id: string;
      firstName: string;
      surname: string;
    };
    metadata: {
      messagePayloadFrom: string;
      messagePayloadTo: string;
    };
  };
};

interface IConnectedTicket {
  src: string;
  dst: string;
  type: string;
  isServed: boolean;
  payload: {
    from: string;
    taskName: string;
    to: string;
    linkingData: {
      interactionId: string;
      contact: {
        id: string;
        firstName: string;
        surname: string;
      };
    };
    metadata: any,
    additionalData: any,
  };
};

interface IMetaData {
  agentRatingSurveyPercentage: number,
  channel: string,
  connectedMemberIds: string[],
  date: number,
  direction: string,
  flowName: string,
  from: string,
  recordingActive: boolean,
  recordingPaused: boolean,
  serviceId: string,
  to: string,
  type: string
  workType: string,
  screenPopUrl?: string,
  useEmbeddedIFrame?: any;
  start_timestamp?: number;
};

export class TicketModel {
  
  private contactService: ContactService = Container.instance.get(ContactService);
  private conversationService: ConversationService = Container.instance.get(ConversationService);
  private eventAggregator: EventAggregator = Container.instance.get(EventAggregator);

  private static TYPES = {
    incoming: 'IncomingMessage',
    connected: 'MessageConnected'
  };

  public interactionId: string = null;
  public contactId: string = null;
  public conversationId: string = null;
  public serviceId: string = null;
  public interactionType: string = null;
  public state: string = null;
  public contact: any = {};
  public agent: any = {};
  public routerId: string = 'message.ALL';
  public channel: string = 'Ticket';
  public formattedChannel: string = 'ticket';
  public taskName: string = null;
  public src: string = null;
  public dst: string = null;
  public from: string = null;
  public to: string = null;
  public type: string = null;
  public target: string = null;
  public additionalData: { name: string; value: string; isLink?: boolean }[];
  public isServed: boolean = null;
  public isSelected: boolean = null;
  public isActive: boolean = null;
  public direction: string = 'INBOUND';
  public formattedDirection: string = 'Inbound';
  public flowName: string = null;
  public journey: WaypointModel[] = [];
  public timestamp: any = null;
  public firstName: string = null;
  public surname: string = null;
  public recentActivity: boolean = null;
  public correlationId?: string = null;
  public externalReference: string = null;
  public screenPopUrl: string = null;
  public useEmbeddedIFrame: boolean = null;
  public resumeLinking: boolean = null;
  public workType: string = null;
  public numberOfContacts: number = null;
  public metaData: IMetaData = null;
  public channelIcon: string = 'channel-ticket';
  @observable public isLive: boolean = null;

  public isLinkedToConversation: boolean = false;
  public isLinkingToConversation: boolean = false;
  private interactionSubscription: Subscription;
  private oplogSubscription: Subscription;
  private connectedInteractionSubscription: Subscription;

  public loaders = {
    journey: false
  };

  private oplog: ZIOplog;
  public showConversationCard: boolean = true;

  constructor(data: IIncomingTicket | IConnectedTicket, flatStructure?: boolean) {
    this.mapInteraction(data, flatStructure);
    this.subscribeToChanges();
  }

  public mapInteraction(data: any, flatStructure?: boolean): void {
    if (!data) {
      return;
    }
    if (data.type === TicketModel.TYPES.incoming) {
      this.mapIncomingTask(data);
    } else if (data.type === TicketModel.TYPES.connected) {
      this.mapConnectedTask(data);
    } else if (flatStructure === true) {
      this.mapFlatStructure(data);
    }
  }

  private mapIncomingTask(data: IIncomingTicket): void {
    this.interactionId = data.payload.interactionId;
    this.taskName = data.payload.taskName;
    this.type = data.type;
    const from = data.payload.metadata.messagePayloadFrom;
    this.from = from && from !== 'null' ? from : this.from;
    this.to = data.payload.metadata.messagePayloadTo;
    this.src = data.src;
    this.dst = data.dst;
    this.isServed = data.isServed;
    this.mapContact(data.payload.contact);
  }

  private mapConnectedTask(data: IConnectedTicket): void {
    this.interactionId = data.payload.linkingData.interactionId;
    this.taskName = data.payload.taskName;
    this.type = data.type;
    const from = data.payload.from;
    this.from = from && from !== 'null' ? from : this.from;
    this.to = data.payload.to;
    this.src = data.src;
    this.dst = data.dst;
    this.isServed = data.isServed;
    this.mapContact(data.payload.linkingData.contact);
    this.mapMetaData(data.payload.metadata);
    this.mapAdditionalData(data.payload.additionalData);
  }

  private mapContact(contact: { id: string, firstName: string, surname: string }): void {
    if (contact) {
      this.contact = contact;
      this.contactId = contact.id;
      this.firstName = contact.firstName;
      this.surname = contact.surname;
    }
  }

  private mapFlatStructure(data: any): void {
    this.contactId = data.contactId || this.contactId;
    this.conversationId = data.conversationId || this.conversationId;
    this.interactionId = data.interactionId || data.id || this.interactionId;
    this.interactionType = data.interactionType || this.interactionType;
    this.workType = data.workType || this.workType;
    this.state = data.state || this.state;
    this.resumeLinking = data.resumeLinking || this.resumeLinking;
    this.recentActivity = data.recentActivity || this.recentActivity;
    this.timestamp = data.timestamp;
  }

  public mapMetaData(data: IMetaData): void {
    if (!data) {
      return;
    }
    this.flowName = data.flowName;
    const from = data.from;
    this.from = from && from !== 'null' ? from : this.from;
    this.serviceId = data.serviceId;
    this.to = this.to || data.to;
    this.workType = data.workType || this.workType;
    if (data.start_timestamp) {
      this.timestamp = data.start_timestamp;
    }
    
    if (data.screenPopUrl) {
      this.screenPopUrl = data.screenPopUrl;
    }

    if (
      data.useEmbeddedIFrame !== null &&
      typeof data.useEmbeddedIFrame !== 'undefined'
    ) {
      try {
        this.useEmbeddedIFrame = JSON.parse(
          data.useEmbeddedIFrame
        );
      } catch (e) {
        this.useEmbeddedIFrame = data.useEmbeddedIFrame;
      }
    }
  }

  public mapJourney(data: { waypoints: any[] }): void {
    if (data && data.waypoints) {
      this.journey = data.waypoints.map(waypoint => new WaypointModel(waypoint));
    }
  }

  public mapAdditionalData(additionalData: any): void {
    if (additionalData && (!this.additionalData || this.additionalData.length === 0)) {
      this.additionalData = [];
      let promises = [];
      let _additionalData = additionalData.displayVariables ? additionalData.displayVariables : additionalData;
      
      if (Array.isArray(_additionalData)) {
        _additionalData = _additionalData.map(item => [item.variableName, item.displayName]);
      } else {
        _additionalData = Object.entries(_additionalData);
      }
      
      _additionalData.forEach(
        data => promises.push(
          new Promise(async resolve => {
            let prop = data[0];
            let value = data[1];

            let item: any = { name: prop, value };

            if (prop.startsWith('Link_')) {
              item.name = item.name.replace('Link_', '');
              item.isLink = true;
            } else if (prop.startsWith('IFrame_')) {
              item.name = item.name.replace('IFrame_', '');
              item.isIFrameLink = true;
            } else if (prop.startsWith('Display_')) {
              const parts = item.name.split('_');
              item.name = parts[2];
              item.requestProperty = parts[1];
              const value = await this.getAdditionalDataDisplay(item);
              item.value = value;
              item.isDisplay = true;
            } else if (prop.startsWith('Button_')) {
              const parts = item.name.split('_');
              item.name = parts[2];
              item.requestMethod = parts[1];
              item.isButton = true;
            }
            resolve(item);
          })
        )
      );

      Promise.all(promises).then((results) => {
        this.additionalData = results;
      });
    }
  }

  public async getAdditionalDataDisplay(data: any): Promise<string> {
    return await this.contactService.fetchFromUrl(data.value, data.requestProperty);
  }

  public mapAgent(agent: { memberId: string; firstName: string; surname: string }): void {
    if (agent) {
      this.agent = agent;
    }
  }

  private subscribeToChanges(): void {
    const interactionId = this.interactionId;
    if (!interactionId || this.interactionSubscription) {
      return;
    }
    
    this.interactionSubscription = this.eventAggregator.subscribe(
      'connected-interaction-' + interactionId,
      (interaction: any) => {
        if (!interactionId) {
          return (
            this.interactionSubscription &&
            this.interactionSubscription.dispose()
          );
        }
        if (interaction.interactionId !== interactionId) {
          return;
        }

        if (interaction.state === 'CONNECTED') {
          this.isLive = true;
        } else {
          this.isLive = false;
        }
      }
    );
    this.connectedInteractionSubscription = this.eventAggregator.subscribe(
      'connection-interaction-updated',
      (data) => {
        if (!this) {
          return (
            this.connectedInteractionSubscription &&
            this.connectedInteractionSubscription.dispose()
          );
        }
        if (this.isLive) {
          let isLiveInteraction =
            data &&
            data.interactions &&
            data.interactions.find(
              (interaction) => interaction.interactionId === this.interactionId
            );

          if (!isLiveInteraction) {
            this.isLive = false;
            this.eventAggregator.publish('cc-interaction-ended', {
              interactionId: this.interactionId,
              contactId: this.contactId,
              correlationId: this.correlationId,
            });
            setTimeout(
              () =>
                this.connectedInteractionSubscription &&
                this.connectedInteractionSubscription.dispose(),
              5000
            );
          }
        }
      }
    );
    this.eventAggregator.publish(
      'request-connected-interactions',
      this.interactionId
    );
  }

  public select(): void {
    this.isSelected = true;
    this.isActive = true;
  }

  public deselect(): void {
    this.isSelected = false;
    this.isActive = false;
  }

  public async linkToContact(contactId: string): Promise<void> {
    if (!contactId) {
      return;
    }
    this.contactId = contactId;
    await this.contactService.linkInteractionToContact(contactId, this.interactionId);
  }

  public linkToConversation(conversationId: string): void {

    if (!this.contactId || !conversationId) {
      return;
    }

    this.conversationId = conversationId;
    this.eventAggregator.publish('conversation-oplog-subscribe', conversationId);
    this.conversationService.linkInteractionToConversation(this.interactionId, conversationId, this.contactId);
    this.triggerInteractionLinkedToConversation();
  }

  private triggerInteractionLinkedToConversation(): void {
    this.eventAggregator.publish(INTERACTION_ACTIONS.INTERACTION_LINKED_TO_CONVERSATION, { interactionId: this.interactionId, contactId: this.contactId });
  }


  public unsubscribeFromEvents(): void {
    this.oplogSubscription && this.oplogSubscription.dispose();
    this.interactionSubscription && this.interactionSubscription.dispose();
    this.connectedInteractionSubscription && this.connectedInteractionSubscription.dispose();
    this.eventAggregator.publish(
      'interaction-oplog-unsubscribe',
      this.interactionId
    );

    delete this.oplogSubscription;
    delete this.connectedInteractionSubscription;
  }

  @computedFrom('timestamp')
  get relativeTime(): string {
    //@ts-ignore
    return moment(this.timestamp ? parseInt(this.timestamp) : Date.now())._d;
  }

  @computedFrom('agent')
  get agentName(): string {
    if (this.agent) {
      if (!this.agent.firstName && !this.agent.surname) {
        return '(No Name)';
      }
      if (!this.agent.firstName && this.agent.surname) {
        return this.agent.surname;
      }
      if (this.agent.firstName && !this.agent.surname) {
        return this.agent.firstName;
      } else {
        return this.agent.firstName + ' ' + this.agent.surname;
      }
    } else {
      return '-';
    }
  }
}
