import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

import { WebhookService } from '../webhook-service';
import { TableInputModel } from '../../../../../components/custom/table-input';
import { ApplicationProperties } from '../../../../../_config/application.properties';

const logger = LogManager.getLogger('Webhooks');

@autoinject()
export class Webhooks {

  private interactionFlowId: string;

  public live: boolean;
  public webhooks: any[] = [];
  public selectedWebhook: any;
  public tableInputHeaders: TableInputModel;

  private changedHeaders;

  constructor(
    private webhookService: WebhookService,
    private dialogController: DialogController,
    public applicationProperties: ApplicationProperties
  ) {}

  public activate(data: { data: { interactionFlowId: string; live: boolean } }): void {
    this.interactionFlowId = data.data.interactionFlowId;
    this.live = data.data.live;
    
    this.webhookService
      .getAssignedWebhooks(this.interactionFlowId, this.live)
      .then(response => {
        
        this.webhooks = response.map((wh) => {
          let newList = [];
          let keys = Object.keys(wh.responseTypeValues);
          for(var i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = wh.responseTypeValues[key];

            if (typeof value === 'object') {
              key = value.key;
              value = value.value;
            }

            newList.push({ key, value })
          }

          wh.responseTypeValues = newList;
          return wh;
        });
        this.selectDefault();
      })
      .catch(error => this.handleError(error));
  }

  public selectDefault(): void {
    if (this.webhooks.length > 0) {
      this.viewWebhook(this.webhooks[0]);
    } else {
      this.viewWebhook();
    }
  }

  public removeWebhookFromInteractionFlow(index: number, webhook: any, event: Event): void {
    event.stopPropagation();

    this.webhookService
      .removeWebhookFromFlow(this.interactionFlowId, webhook.webhookId)
      .then(() => this.handleWebhookRemoved(webhook.webhookId))
      .catch(error => this.handleError(error));
  }

  private handleWebhookRemoved(webhookId: string): void {
    this.webhooks = this.webhooks
                      .filter(item => item.webhookId !== webhookId);
    this.selectDefault();
  }

  private handleError(error: string): void {
    logger.warn(' > Error', error);
  }

  public viewWebhook(webhook?: any): void {
    this.selectedWebhook = webhook;
    this.webhooks.forEach(wh => wh.isSelected = false);
    if (webhook) {
      webhook.isSelected = true;
    }
    
    this.initTableInputHeaders();
  }

  private initTableInputHeaders(): void {
    let builder = TableInputModel.builder
      .setHeaders('Header Name', 'Header Value')
      .hideHeaders();

    if (this.selectedWebhook && this.selectedWebhook.responseTypeValues) {
      this.selectedWebhook.responseTypeValues
        .forEach(value => {
          builder.addRow(value.key, value.value);
        });
    }
    this.tableInputHeaders = new TableInputModel([builder.build()]);
  }

  private extractTableContent(): { [key: string]: string } {
    const rows = this.tableInputHeaders.table.rows;
    let map = {};

    if (rows) {
      rows.forEach(row => {
        map[row.cells[0]] = row.cells[1];
      });
    }
    return map;
  }

  public rowAdded(): void {
    let scrollContainer = document.querySelector('#scroll-container');
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }

  public selectJSON(): void {
    this.selectedWebhook.responseType = 'JSON';
  }

  public selectXML(): void {
    this.selectedWebhook.responseType = 'XML';
  }

  public valueChanged(tableData: any): void {
    if (tableData) {
      this.changedHeaders = tableData;
    }
  }

  public updateWebhook(): void {
    if (!this.selectedWebhook || !this.selectedWebhook.webhookId) {
      return this.dialogController.ok();
    }
    const responseTypeValues = this.extractTableContent();
    const keys = Object.keys(responseTypeValues);
    let newMap = {};

    keys.forEach((currentKey, index) => {
      let newKey;
      let value;

      if (this.changedHeaders && this.changedHeaders[index]) {
        newKey = this.changedHeaders[index][0];
        value = this.changedHeaders[index][1];
      }
      newMap[newKey || currentKey] = value || responseTypeValues[currentKey];
    });

    this.webhookService
      .updateWebhook(
        this.interactionFlowId,
        this.selectedWebhook.webhookId,
        this.selectedWebhook.responseType,
        newMap
      )
      .then(() => this.dialogController.ok());
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}
