import { DispositionModel } from './../organisation/dispositioncodes/disposition-codes-model';
import { DispositionCodesService } from './../organisation/dispositioncodes/disposition-codes-service';
import { SessionStore } from './../../../_common/stores/session-store';
import { OrderByTimestampValueConverter } from './../../../converters/order-by-timestamp';
import { LogManager, Container, computedFrom } from 'aurelia-framework';
// @ts-ignore
import moment from 'moment-timezone';

import {InteractionModel} from './interaction-model';
import {ConversationService} from './conversation-service';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {
  CONVERSATION_ACTIONS,
  INTERACTION_ACTIONS,
  LOADER_ACTIONS
} from '../contact/contactcontroller/contact-controller-actions';
import {ZIOplog} from "../../../../typings/zai/zai.common";
import {WaypointModel} from "./waypoint-model";
import {ProcessingLayer} from "./interaction/processing-layer";
import { TicketModel } from './interaction/ticket-model';

const logger = LogManager.getLogger('ConversationModel');

export class ConversationModel {
  private sessionStore: SessionStore = Container.instance.get(SessionStore);
  private dispositionCodesService: DispositionCodesService = Container.instance.get(DispositionCodesService);

  public contactId: string = null;
  public _id: string = null;
  public conversationId: string = null;
  public conversationName: string = null;
  public status: string = null;
  public reason: string = null;
  public showReminder: boolean = false;
  public firstName: string = null;
  public surname: string = null;
  public latestInteraction = null;
  public interactions: Array<InteractionModel | TicketModel> = [];
  public interaction: InteractionModel;
  public organisationId: string = null;
  public timestamp: string = null;
  public timestampOpened: string = null;
  public relativeTime: Object = {};
  public selected: boolean = false;
  public isSelected: boolean = false;
  public isSubscribed: boolean = false;
  public awaitingLinking: boolean = false;
  public selectedChannelTab: string = null;
  private conversationService: ConversationService;
  private eventAggregator: EventAggregator;

  public showMoreEnabled: boolean = true;
  public pageSize: number = 6;
  public visibleCount: number = 6;

  private conversationOplogSubscription: Subscription;

  private subscribers: Array<Subscription> = [];
  public dispositionCodeSelectEnabled: boolean = false;

  constructor(conversationService: ConversationService, eventAggregator: EventAggregator, conversationId?: string) {
    this.conversationService = conversationService;
    this.conversationId = conversationId;
    this.eventAggregator = eventAggregator;
    this.subscribeToEvents();
  }
  
  private subscribeToEvents(): void {
    this.subscribers.push(this.eventAggregator.subscribe('select-disposition-code:enabled' + this.conversationId, () => {
      this.dispositionCodeSelectEnabled = true;
    }));
  }

  public updateName(name: string): void {
    this.conversationService.renameConversation(this.conversationId, name);
  }

  public select(autoSelect?: boolean): void {
    logger.debug(this.getCurrentFormattedTime() + ' - CC | selectConversation | retrieve journey for', this.conversationId);
    this.isSelected = true;

    this.toggleLoader('interactionJourney', true);
    this.subscribeToConversationInteractionChanges();

    if (!this.interactions || this.interactions.length === 0) {
      this.toggleLoader('interactionList', true);

      this.conversationService.retrieveInteractions(this.conversationId, this.contactId).then(
        async (interactions: Array<any>) => {

          interactions.sort((a, b) => b.timestamp - a.timestamp);

          let _interactions = [];
          let promises = [];

          interactions.forEach((interaction: any, index: number) => promises.push(new Promise(async resolve => {
            interaction.contactId = this.contactId;

            if (index < this.visibleCount) {
              ProcessingLayer
                .processInteraction(interaction)
                .then(modelledInteraction => {
                  modelledInteraction.metaData = interaction.metaData;
                  _interactions.push(modelledInteraction);
                  modelledInteraction = null;
                  resolve({});
                });
            } else {
              _interactions.push(interaction);
              resolve({});
            }

          })));

          
          Promise.all(promises).then(async () => {
            await this.mapInteractions(_interactions);
            logger.debug(this.getCurrentFormattedTime() + ' - CC | selectConversation | journey retrieved ');
            if (_interactions.length > 0) {
              this.autoSelect(null, autoSelect);
            }
            this.eventAggregator.publish('change.view', {viewStrategy: 'interactions', contactId: this.contactId});
          });
        })
        .catch(() => {
          this.toggleLoader('interactionJourney', false);
        });
    } else {
      let interactionToSelect = this.interactions[this.interactions.length - 1];
      if (interactionToSelect) {
        this.selectInteraction(interactionToSelect.interactionId);
      }
      this.eventAggregator.publish('change.view', {viewStrategy: 'interactions', contactId: this.contactId});
      this.toggleLoader('interactionJourney', false);
    }
  }

  private async retrieveInteractionWaypoints(interaction: InteractionModel | TicketModel): Promise<void> {
    let interactionToUpdate = this.getInteraction(interaction.interactionId);
    if (interactionToUpdate) {
      interactionToUpdate.loaders.journey = true;
    }
    let rawData = await this.conversationService.retrieveInteraction(interaction.interactionId);
    if (rawData && interaction instanceof InteractionModel) {
      interaction.updateRecordingActive(rawData.metaData ? rawData.metaData.recordingId : null);
    }
    if (interactionToUpdate && rawData) {
      if (interactionToUpdate instanceof InteractionModel) {
        if (interactionToUpdate.isConsulting && !rawData.isConsulting) {
          localStorage.removeItem('zai-consult');
        }
        if (rawData.hasOwnProperty('isConsulting') && typeof rawData.isConsulting === 'boolean') {
          interactionToUpdate.isConsulting = rawData.isConsulting;
        }
        interactionToUpdate.workTypeTransferEnabled = rawData.workTypeTransferEnabled;

        if (rawData.agent) {
          interactionToUpdate.agent = rawData.agent;
        }
      }
      if (rawData.metaData && interactionToUpdate instanceof InteractionModel) {
        interactionToUpdate.recordingActive = rawData.metaData.recordingActive;
        interactionToUpdate.recordingIsEnabled = rawData.metaData.recordingActive;
        interactionToUpdate.recordingId = rawData.metaData.recordingId;
        interactionToUpdate.recordingPaused = rawData.metaData.recordingPaused;

        interactionToUpdate.disposition = rawData.metaData.disposition;
        interactionToUpdate.dispositionCode = rawData.metaData.dispositionCode;
        interactionToUpdate.showDispositionOrOutcome = true;
        
        const metaData = rawData.metaData as any;
        if (metaData.serviceId && metaData.direction) {
          interactionToUpdate.metaData = metaData;

          let organisationId = this.sessionStore.get.organisation.organisationId;
          let serviceId = metaData.serviceId;
          let direction = metaData.direction === 'INBOUND' ? 'Inbound' : 'Outbound';

          let channel;
          switch (metaData.channel) {
            case 'VOICE':
              channel = `${direction} Call`;
              break;
            case 'EMAIL':
              channel = 'Email';
              break;
            case 'CHAT':
              channel = 'Chat';
              break;
            case 'INSTANT_MESSAGE':
            case 'instant_message':
            channel = 'Instant Message';
              break;
            default:
              channel = metaData.channel;
              break;
          }

          this.dispositionCodesService.retrieveDispositionListByServiceChannelAndDirection(organisationId, serviceId, channel, direction, metaData.tiered)
            .then((dispositionList: DispositionModel) => {
              if (interactionToUpdate instanceof InteractionModel) {
                interactionToUpdate.showDispositionCodeSelectButton = dispositionList && dispositionList.dispositionCodes && dispositionList.dispositionCodes.length > 0;
                if (interactionToUpdate.showDispositionCodeSelectButton) {
                  this.eventAggregator.publish('select-disposition-code:enabled' + this.conversationId);
                }
              }
            });
        }
      }

      if (rawData.journey && rawData.journey.waypoints) {
        if (interactionToUpdate instanceof TicketModel) {
          interactionToUpdate.mapJourney(rawData.journey);
        } else if (interactionToUpdate instanceof InteractionModel) {
          interactionToUpdate.journey = rawData.journey.waypoints.map(waypoint => new WaypointModel(waypoint));
        }
      }
      if (interactionToUpdate instanceof TicketModel) {
        interactionToUpdate.mapAdditionalData(rawData.additionalData);
      } else if (rawData.additionalData && Object.keys(rawData.additionalData).length > 0) {
        interactionToUpdate.additionalData = rawData.additionalData;
        let promises = [];

        // @ts-ignore
        let additionalData = Object.entries(rawData.additionalData);
        additionalData.forEach(
          data => promises.push(
            new Promise(async resolve => {
              let prop = data[0];
              let value = data[1];

              if (prop.startsWith('vb_')) {
                resolve(null);
              }
              if (prop === 'Req MSISDN') {
                if (interactionToUpdate instanceof InteractionModel) {
                  interactionToUpdate.msisdn = value;
                }
              }

              let item: any = { name: prop, value };

              if (prop.startsWith('Link_')) {
                item.name = item.name.replace('Link_', '');
                item.isLink = true;
              } else if (prop.startsWith('IFrame_')) {
                item.name = item.name.replace('IFrame_', '');
                item.isIFrameLink = true;
              } else if (prop.startsWith('Display_')) {
                const parts = item.name.split('_');
                item.name = parts[2];
                item.requestProperty = parts[1];
                const value = await interactionToUpdate.getAdditionalDataDisplay(item);
                item.value = value;
                item.isDisplay = true;
              } else if (prop.startsWith('Button_')) {
                const parts = item.name.split('_');
                item.name = parts[2];
                item.requestMethod = parts[1];
                item.isButton = true;
              }
              resolve(item);
            })
          )
        );

        Promise.all(promises).then((results) => {
          if (interactionToUpdate instanceof InteractionModel) {
            interactionToUpdate.mappedAdditionalData = results.filter(item => !!item);
          }
        });
      }

    } else if (interaction && rawData) {
      if (interaction instanceof TicketModel) {
        interaction.mapJourney(rawData.journey);
      }
    }
    if (interactionToUpdate) {
      interactionToUpdate.loaders.journey = false;
    }
  }

  private toggleLoader(view: string, status: boolean): void {
    this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {contactId: this.contactId, status, loader: view});
  }

  public subscribeToConversationInteractionChanges(autoSelect?: boolean): void {
    this.conversationOplogSubscription = this.eventAggregator.subscribe(
      'conversation-oplog-update:' + this.conversationId,
      (data: { conversationId: string, response: any }) => {
        if (this.conversationId !== data.conversationId) {
          return;
        }
        this.handleInteractionJourneyOplogChange(data.response, autoSelect);
      }
    );
    this.isSubscribed = true;
  }

  private handleInteractionJourneyOplogChange(response: any, autoSelect?: boolean): void {
    if (response && response.waypoints) {
      response.waypoints.forEach(waypoint => {
        let interactionId = waypoint.interactionId;
        if(!interactionId){
          interactionId = waypoint.taskId;
        }
        let existingInteraction = this.getInteraction(interactionId);
        if (existingInteraction) {

          if (existingInteraction instanceof InteractionModel) {
            existingInteraction.mapInteraction(waypoint);
          }
        } else {
          ProcessingLayer
            .processInteraction(waypoint)
            .then(interaction => {
              
              existingInteraction = this.getInteraction(interactionId);
              if (existingInteraction) {
                return;
              }

              if (interaction instanceof TicketModel) {
                this.retrieveInteractionWaypoints(interaction);
              }
              if (!this.interactions) {
                this.interactions = [];
              }
              this.interactions.push(interaction);
              this.selectInteraction(interaction.interactionId);
            });
        }
      });
      setTimeout(() => {
        this.eventAggregator.publish(CONVERSATION_ACTIONS.INTERACTIONS_RETRIEVED, {
          interactions: this.interactions || [],
          contactId: this.contactId
        });
        if (autoSelect) {
          this.autoSelect(this.interactions[this.interactions.length - 1]);
        }
      }, 200);
    } else if (Object.keys(response).length > 0) {
      for (let key in response) {
        if (response.hasOwnProperty(key) && key.includes('dispositionCode')) {
          let existingInteraction = this.getInteraction(response._id);
          if (existingInteraction && existingInteraction instanceof InteractionModel) {
            existingInteraction.dispositionCode = response[key];
          }
        }
      }
    }
  }

  public deselect(): void {
    if (this.isSelected) {
      this.visibleCount = this.pageSize + 0;
      this.isSelected = false;
      this.deselectAllInteraction();
    }
  }

  public deselectAllInteraction(): void {
    this.interactions.forEach((interaction) => interaction.deselect && interaction.deselect());
  }

  public selectInteraction(interactionId: string): void {
    this.interactions.forEach(interaction => interaction.deselect && interaction.deselect());
    let interactionToSelect = this.interactions.find(interaction => interaction.interactionId === interactionId);
    if (interactionToSelect && (interactionToSelect instanceof InteractionModel || interactionToSelect instanceof TicketModel)) {
      interactionToSelect.select();
      if (!interactionToSelect.journey || !interactionToSelect.journey.length) {
        this.retrieveInteractionWaypoints(interactionToSelect);
      }
    }
  }

  public changeStatus(status: string, reason: string): void {
    this.status = status;
    this.reason = reason ? reason : null;
  }

  public equals(conversation: ConversationModel): boolean {
    return this.conversationId === conversation.conversationId;
  }

  public determineConversationName(): string {
    if (!this.conversationName) {
      this.conversationName = '(untitled)';
    }
    return this.conversationName;
  }

  public reviewStatus(memberId: string, status: string, reason: string): void {
    this.conversationService.reviewStatus(memberId, this.conversationId, status, reason);
  }

  public linkInteractionToConversation(interactionId: string): void {
    this.eventAggregator.publish('conversation-oplog-subscribe', interactionId);
    this.conversationService.linkInteractionToConversation(interactionId, this.conversationId, this.contactId);
  }

  @computedFrom('status')
  get statusColor(): string {
    if (this.status) {

      let colors = {
        new: 'highlight',
        pending: 'yellow',
        successful: 'green',
        unsuccessful: 'red',
        default: ''
      };
      return (colors[this.status.toLowerCase()] || colors['default']);
    }
  }

  public mapConversationInfo(conversation: ConversationModel, ignoreDispositions?: boolean): void {
    this.conversationName = conversation.conversationName || this.conversationName;
    this.status = conversation.status || this.status;
    this.reason = conversation.reason ? conversation.reason : this.reason || null;
    this.timestampOpened = conversation.timestampOpened;
    let latestInteraction: InteractionModel = null;
    if (conversation.latestInteraction && Object.keys(conversation.latestInteraction).length !== 0) {
      latestInteraction = new InteractionModel();
      conversation.latestInteraction._id = conversation.latestInteraction.interactionId;
      latestInteraction.mapInteraction(conversation.latestInteraction, ignoreDispositions);
    }
    this.latestInteraction = latestInteraction ? latestInteraction : this.latestInteraction;

    if (conversation && conversation.latestInteraction && conversation.latestInteraction.channel) {
      conversation.selectedChannelTab = conversation.latestInteraction.channel;
    } else {
      conversation.selectedChannelTab = null;
    }
  }

  public mapInteractions(interactions: Array<InteractionModel | TicketModel>): void {
    let selectedInteraction = this.getSelectedInteraction();

    interactions = interactions.map((interaction) => {
      if (interaction instanceof InteractionModel) {
        interaction.interactionId = interaction.interactionId ? interaction.interactionId : interaction.noteId || interaction.reminderId;
      } else if (interaction instanceof TicketModel) {
        interaction.interactionId = interaction.interactionId;
      }
      interaction.conversationId = this.conversationId;
      interaction.contactId = this.contactId;
      interaction.firstName = this.firstName;
      interaction.surname = this.surname;
      return interaction;
    });
    const intTimes = interactions.map(i => { return { interactionId: i.interactionId, timestamp: i.timestamp }})

    intTimes.sort((a, b) => a.timestamp - b.timestamp);
    interactions.sort((a, b) => a.timestamp - b.timestamp);
    this.interactions = interactions;

    if (selectedInteraction && !this.interactions.some((interaction) => interaction.interactionId === selectedInteraction.interactionId)) {
      if (!this.interactions) {
        this.interactions = [];
      }
      this.interactions.push(selectedInteraction);
    }
    this.toggleLoader('interactionJourney', false);
  }

  public autoSelect(interaction?: InteractionModel | TicketModel, auto?: boolean): void {
    if (this.interactions.length > 0) {
      logger.debug(this.getCurrentFormattedTime() + ' - CC | selectConversation | journey retrieved | electing latest interaction ');
      this.eventAggregator.publish(INTERACTION_ACTIONS.SELECT, {
        interaction: interaction || this.interactions[this.interactions.length - 1],
        contactId: this.contactId,
        auto
      });
    }
  }

  public getInteraction(interactionId: string): InteractionModel | TicketModel {
    if (!this.interactions) {
      return; // don't want to do this
    }
    return this.interactions.find((interaction: InteractionModel | TicketModel) => interaction.interactionId === interactionId);
  }

  public showMore(): void {

    if (this.showMoreEnabled) {
      this.showMoreEnabled = false;

      const scrollContainer: HTMLElement = document.querySelector('#js-conversation-journey-scroll-container');
      const journeyItems: any = scrollContainer.querySelectorAll('.js-interaction-item');
      const oldPosition = this.visibleCount;
      this.visibleCount = this.visibleCount + this.pageSize;

      let _interactions = [];
      let promises = [];
      let currentInteractions = [...this.interactions];

      currentInteractions.sort((a, b) => b.timestamp - a.timestamp);
      currentInteractions.forEach((interaction: any, index: number) => promises.push(new Promise(async resolve => {
        interaction.contactId = this.contactId;

        if (index < this.visibleCount) {
          if (interaction instanceof InteractionModel || interaction instanceof TicketModel) {
            _interactions.push(interaction);
            resolve({});
          } else {
            ProcessingLayer
              .processInteraction(interaction)
              .then(modelledInteraction => {
                modelledInteraction.metaData = interaction.metaData;
                _interactions.push(modelledInteraction);
                modelledInteraction = null;
                resolve({});
              });
          }
        } else {          
          _interactions.push(interaction);
          resolve({});
        }

      })));

      
      Promise.all(promises).then(async () => {
        _interactions.sort((a, b) => a.timestamp - b.timestamp);
        this.interactions = _interactions;
        currentInteractions = null;
      });


      setTimeout(() => {
        scrollContainer.scrollTo({ top: (journeyItems[0]).offsetTop - 50 });
        setTimeout(() => {
          this.showMoreEnabled = true;
        }, 250)
      }, 50)
    }
  }

  public disposeOfSubscriptions(): void {
    if (this.conversationOplogSubscription) {
      this.conversationOplogSubscription.dispose();
      this.eventAggregator.publish('conversation-oplog-unsubscribe', this.conversationId);
    }
    this.subscribers.forEach(subscriber => subscriber.dispose());
    
    let promises = [];
    promises.push(this.interactions.forEach(interaction => interaction.unsubscribeFromEvents && interaction.unsubscribeFromEvents()));
    Promise.all(promises).then(() => {
      delete this.interactions;
      delete this.interaction;
      delete this.latestInteraction;
    });
  }

  @computedFrom('interactions')
  get interactionLength(): number {
    return this.interactions.length;
  }

  public getSelectedInteraction(): InteractionModel | TicketModel {
    return this.interactions.find((interaction) => interaction.isSelected);
  }

  @computedFrom('interactions')
  get hasInteractionLength(): boolean {
    return this.interactions && this.interactions.length > 0;
  }

  @computedFrom('interactions', 'latestInteraction')
  get hasInteractions(): boolean {
    return this.currentLatestInteraction ? true : false;
  }

  @computedFrom('interactions')
  get currentLatestInteraction(): InteractionModel | TicketModel {

    if (this.interactions && this.interactions.length > 0) {
      let orderInteractions = this.interactions
        .sort((x, y) => {
          return moment.utc(x.timestamp).diff(moment.utc(y.timestamp));
        })
        .filter((interaction) => interaction.type !== 'NOTE' && interaction.type !== 'REMINDER' && interaction.type !== 'CAMPAIGN');

      if (orderInteractions.length > 0) {
        return orderInteractions[orderInteractions.length - 1];
      }

    } else if (this.latestInteraction) {
      return this.latestInteraction;
    }

    return null
  }

  private getCurrentFormattedTime(): string {
    const dateTime = moment().format('DD/mm/YYYY hh:mm:ss');
    return dateTime;
  }
}
