import { autoinject } from 'aurelia-dependency-injection';
import { HttpClient } from 'aurelia-http-client';

import { ApplicationProperties, OplogService, SessionStore } from 'zailab.common';

import { v4 as uuidv4 } from 'uuid';

@autoinject()
export class AssistantService {
  constructor(
    private httpClient: HttpClient,
    private oplogService: OplogService,
    private applicationProperties: ApplicationProperties,
    private sessionStore: SessionStore
  ) {}

  public retrieveAssistants(): Promise<any> {
    return this.httpClient
      .createRequest(`v1/interaction/interactionflow/assistants`)
      .asGet()
      .send();
  }

  public createAssistant(
    assistantId: string,
    name: string,
    description: string,
    purpose: string,
    greeting: string,
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/interaction/interactionflow/assistants/${assistantId}`)
      .asPost()
      .withContent({
        name,
        description,
        purpose,
        greeting,
      })
      .send();
  }

  public updateAssistant(
    assistantId: string,
    name: string,
    description: string,
    purpose: string,
    greeting: string,
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/interaction/interactionflow/assistants/${assistantId}`)
      .asPut()
      .withContent({
        name,
        description,
        purpose,
        greeting,
      })
      .send();
  }

  public deleteAssistants(assistantId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/interaction/interactionflow/assistants/${assistantId}`)
      .asDelete()
      .send();
  }

  public resetAssistant(assistantId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/interaction/interactionflow/assistants/${assistantId}/reset`)
      .asPost()
      .send();
  }

  public subscribeToAssistantChanges(id: string): any {
    return this.oplogService.subscribeOn('_id', id).in('interaction-flow-projector.assistantView');
  }

  public retrieveAssistant(assistantId): Promise<any> {
    return this.httpClient
      .createRequest(`v1/interaction/interactionflow/assistants/${assistantId}`)
      .asGet()
      .send();
  }

  public uploadAssistantFile(
    assistantId: string,
    file: { name: string; content: string }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      if (file) {
        formData.append('file', file.content);
      }

      const fileId = uuidv4();
      const baseURL = this.applicationProperties.apiQueryEndpoint;
      const url = `v1/interaction/interactionflow/assistants/${assistantId}/files/${fileId}`;
      const xhr = new XMLHttpRequest();

      xhr.open('POST', baseURL + url, true);
      xhr.setRequestHeader('Authorization', this.sessionStore.get.user.token);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve(fileId);
          } else if (xhr.status === 200) {
            resolve(fileId);
          } else if (xhr.status === 500) {
            reject('Failed to upload image.');
          }
        }
      };
      xhr.send(formData);
    });
  }

  public retrieveLoadedFiles(assistantId): Promise<any> {
    return this.httpClient
      .createRequest(`v1/interaction/interactionflow/assistants/${assistantId}/files`)
      .asGet()
      .send();
  }

  public retrieveAssistantsAuditTrail(pageNumber: number, pageSize: number): Promise<any> {
    return this.httpClient
      .createRequest(`v1/interaction/interactionflow/assistants/audit`)
      .asGet()
      .withParams({
        page: pageNumber,
        size: pageSize
      })
      .send();
  }
}
