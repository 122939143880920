import { ConversationModel } from './../conversation-model';
import { computedFrom } from 'aurelia-binding';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, customElement, autoinject, LogManager } from 'aurelia-framework';

import { ContactModel } from '../../contact/contact-model';
import { DispositionModel } from '../../organisation/dispositioncodes/disposition-codes-model';

const logger = LogManager.getLogger('DispositionList');

@customElement('z-disposition-list')
@autoinject()
export class DispositionList {

  @bindable public contact: ContactModel;

  public categoryMap: Map<string, Array<string>>;
  public selectedCategory: string;
  public selectedSubcategory: string;
  public comments: string;
  public selectedDisposition: DispositionModel;
  private scrollZone: HTMLElement;


  constructor(
    private eventAggregator: EventAggregator
  ) { }

  public attached(): void {
    this.categoryMap = new Map();
    this.selectedCategory = null;
    if (this.contact.dispositionList.categories && this.contact.dispositionList.categories.length) {
      const categories = this.contact.dispositionList.categories;
      for (const category of categories) {
        this.categoryMap.set(category.name, category.subcategories.map((subcategory) => subcategory.name));
      }
    }
    this.selectedCategory = this.categories && this.categories.length ? this.categories[0]: null;
    this.selectedSubcategory = this.subcategories && this.subcategories.length ? this.subcategories[0]: null;
  }

  public selectDisposition(disposition: DispositionModel): void {
    this.dispositionCodes.forEach(item => item.selected = false);
    disposition.selected = true;
    this.selectedDisposition = disposition;
    if (this.scrollZone) {
      this.scrollZone.scrollTop = this.scrollZone.scrollHeight;
    }
  }

  public dispositionSelected(): void {
    if (this.comments) {
      this.selectedDisposition.comments = this.comments;
    }
    this.eventAggregator.publish('select.disposition', this.selectedDisposition);
    this.contact.closeDispositionList();
  }


  public get isTiered(): boolean {
    if (this.contact && this.contact.dispositionList) {
      return this.contact.dispositionList.tiered;
    }
    return false;
  }

  @computedFrom('categoryMap')
  public get categories(): Array<string> {
    if (this.isTiered && this.categoryMap) {
      return Array.from(this.categoryMap.keys());
    }
    return [];
  }

  @computedFrom('selectedCategory')
  public get subcategories(): Array<string> {
    if (this.isTiered && this.categoryMap && this.selectedCategory) {
      return this.categoryMap.get(this.selectedCategory);
    }
    return [];
  }

  @computedFrom('selectedSubcategory')
  public get dispositionCodes(): Array<DispositionModel> {
    if (this.isTiered && this.categoryMap && this.selectedCategory && this.selectedSubcategory) {

      let dispositionCodes = [];

      const dispositionList = new DispositionModel(this.contact.dispositionList);

      const dispositionListCategories = dispositionList.categories
        .find((c) => c.name === this.selectedCategory);
      if (dispositionListCategories) {

        const dispositionListCategoriesSubcategories = dispositionListCategories.subcategories
          .find((s) => s.name === this.selectedSubcategory);
        if (dispositionListCategoriesSubcategories) {

          const dispositionCodeIds = dispositionListCategoriesSubcategories.dispositionCodeIds;

          dispositionCodes = dispositionList.dispositionCodes
            .filter((dispositionCode) => dispositionCodeIds.indexOf(dispositionCode.dispositionCodeId) !== -1);
        }
      }
      return dispositionCodes;
    }
    return this.contact.dispositionList.dispositionCodes;
  }
}
