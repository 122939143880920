import {autoinject, LogManager} from 'aurelia-framework';
import {HttpClient, HttpResponseMessage} from 'aurelia-http-client';

import {SessionStore} from 'zailab.common';
import {ApplicationProperties} from "../../_config/application.properties";
// @ts-ignore
import { AureliaConfiguration } from 'aurelia-configuration';

const logger = LogManager.getLogger('TelephonyService');

@autoinject
export class TelephonyService {

  countryRegionMap = new Map([
    ['US','usw2'],
    ['ZA','za'],
    ['AT','euw1'],
    ['BE','euw1'],
    ['EE','euw1'],
    ['ES','euw1'],
    ['FI','euw1'],
    ['FR','euw1'],
    ['DE','euw1'],
    ['GR','euw1'],
    ['IE','euw1'],
    ['IT','euw1'],
    ['LV','euw1'],
    ['LT','euw1'],
    ['LU','euw1'],
    ['MT','euw1'],
    ['NL','euw1'],
    ['PT','euw1'],
    ['SK','euw1'],
    ['SI','euw1']
  ]);
  private countryRegionMapDev1 = new Map([
    ['US','usw2'],
    ['ZA','za'],
    ['AT','eu'],
    ['BE','eu'],
    ['EE','eu'],
    ['ES','eu'],
    ['FI','eu'],
    ['FR','eu'],
    ['DE','eu'],
    ['GR','eu'],
    ['IE','eu'],
    ['IT','eu'],
    ['LV','eu'],
    ['LT','eu'],
    ['LU','eu'],
    ['MT','eu'],
    ['NL','eu'],
    ['PT','eu'],
    ['SK','eu'],
    ['SI','eu']
  ]);

  constructor(
    private applicationProperties: ApplicationProperties,
    private httpClient: HttpClient,
    private sessionStore: SessionStore,
    private aureliaConfiguration: AureliaConfiguration
  ) {}

  public goOffWrapUp(memberId: string, interactionId: string, wrapUpChannelIds: string[]): void {

    let commandPayload = {memberId, channel: 'Call', wrapUpChannelIds};

    this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${interactionId}/endwrapup`)
      .asPut()
      .withContent(commandPayload)
      .send()
      .catch((error: string) => {
      });
  }

  public consult(interactionId: string, targetMemberId: string, transferringMemberId: string): void {
    let commandPayload = { targetMemberId, transferringMemberId };

    this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${interactionId}/consult`)
      .asPost()
      .withContent(commandPayload)
      .send()
      .catch((error: string) => {
      });
  }

  public connect(interactionId: string, targetMemberId: string, transferringMemberId: string): void {
    let commandPayload = { targetMemberId, transferringMemberId };

    this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${interactionId}/consult/connect`)
      .asPut()
      .withContent(commandPayload)
      .send()
      .catch((error: string) => {
      });
  }

  public consultDestination(interactionId: string, destination: string, transferringMemberId: string): Promise<any> {
    let commandPayload = { destination, transferringMemberId };

    return this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${interactionId}/consult`)
      .asPost()
      .withContent(commandPayload)
      .send();
  }

  public connectDestination(interactionId: string, destination: string, transferringMemberId: string): Promise<any> {
    let commandPayload = { destination, transferringMemberId };

    return this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${interactionId}/consult/connect`)
      .asPut()
      .withContent(commandPayload)
      .send();
  }

  public transfer(callId: string, destination: string, transferringMemberId: string): Promise<any> {
    let commandPayload = { callId, destination, transferringMemberId };

    return this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${callId}/transfer`)
      .asPost()
      .withContent(commandPayload)
      .send();
  }

  public transferChat(interactionId: string, destinationMemberId: string, transferringMemberId: string): Promise<any> {
    const commandPayload = { interactionId, destinationMemberId, transferringMemberId };

    return this.httpClient.createRequest(this.applicationProperties.apiQueryEndpoint + `v1/messages/interactions/${interactionId}/transfer`)
      .asPost()
      .withContent(commandPayload)
      .send();
  }

  public workTypeTransfer(workTypeTransfer: any): void {

    const uri = this.getRegionBaseUrl() + `v1/telephony/calls/${workTypeTransfer.callId}/transfer/work-type`;

    this.httpClient.createRequest(uri)
      .asPost()
      .withContent(workTypeTransfer)
      .send()
      .catch((error: string) => {
      });
  }

  public addToConference(organisationId: string, interactionId: string, conferencingMemberId?: string, destination?: string): Promise<HttpResponseMessage> {
    let commandPayload = { organisationId, conferencingMemberId, destination };

    return this.httpClient
      .createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${interactionId}/consult/conference`)
      .asPut()
      .withContent(commandPayload)
      .send();
  }

  public cancelConsult(interactionId: string, targetMemberId: string, transferringMemberId: string): void {
    let commandPayload = { targetMemberId, transferringMemberId };

    this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${interactionId}/consult`)
      .asDelete()
      .withContent(commandPayload)
      .send()
      .catch((error: string) => {
      });
  }

  public cancelConsultDestination(interactionId: string, destination: string, transferringMemberId: string): void {
    let commandPayload = { destination, transferringMemberId };

    this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${interactionId}/consult`)
      .asDelete()
      .withContent(commandPayload)
      .send()
      .catch((error: string) => {
      });
  }

  public dial(callId: string, from: string, to: string, originatingFlowId: string): void {
    let commandPayload = { from, to, originatingFlowId };

    this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${callId}`)
      .asPut()
      .withContent(commandPayload)
      .send()
      .catch((error: string) => {
      });
  }

  public pauseRecording(callId: string): Promise<HttpResponseMessage> {
    return this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${callId}/recordings/pause`)
      .asPut()
      .withContent({})
      .send();
  }

  public resumeRecording(callId: string): Promise<HttpResponseMessage> {
    return this.httpClient.createRequest(this.getRegionBaseUrl() + `v1/telephony/calls/${callId}/recordings/resume`)
      .asPut()
      .withContent({})
      .send();
  }

  private getRegionBaseUrl(): string {
    let organisationCountryCode = this.sessionStore.get.organisation.country.code;
    let region = null;
    let url: string = this.applicationProperties.apiRouterEndpoint;
    
    const environment = this.aureliaConfiguration.environment;

    if (url && url.includes('region')) {
      if (environment === 'local' || environment === 'dev1') {
        region = this.countryRegionMapDev1.get(organisationCountryCode);
      } else {
        region = this.countryRegionMap.get(organisationCountryCode);
      }
      if(!region){
        region = 'za';
      }
      url = url.replace('region', region);
    }
    return url;
  }
}
