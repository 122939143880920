import { autoinject, LogManager } from 'aurelia-framework';
import { v4 as uuidv4 } from 'uuid';
import { AureliaConfiguration } from 'aurelia-configuration';

const logger = LogManager.getLogger('ApplicationProperties');

const ELIGIBLE_ORGANISATIONS = [
  '846b9093-c87a-4732-85fe-a2723967f9e5',
  '2292b5fa-7279-4da4-ac4c-4a1cec899550',
  'e2050ced-4efb-4114-a405-1aaddacdbbfc',
  '782c00f7-72ef-41d6-8b1a-efe71b836fd1',
  '71522dc5-5751-4b00-8db0-81db52c1ccb5',
  'd21163d6-8a11-49fd-b5ea-e4e63640a20a',
  '0ccbbec9-d64e-482a-935d-bddf9ff36722',
  '1ca36430-57d8-4ee3-b134-024cab470640',
];

@autoinject()
export class ApplicationProperties {
  constructor(private config: AureliaConfiguration) {}

  get apiRedirectEndpoint() {
    return this.config.get('apiRedirectEndpoint');
  }

  get stripeToken() {
    return this.config.get('stripeToken');
  }

  get apiQueryEndpoint() {
    return this.config.get('apiQueryEndpoint');
  }

  get apiCommandEndpoint() {
    return this.config.get('apiCommandEndpoint');
  }

  get apiInteractionEndpoint() {
    return this.config.get('apiInteractionEndpoint');
  }

  get apiRouterEndpoint() {
    return this.config.get('apiRouterEndpoint');
  }

  get interactionFlowEmailDomain() {
    return this.config.get('interactionFlowEmailDomain');
  }

  get webphoneEndpoint() {
    return this.config.get('webphoneEndpoint');
  }

  get widgetEndpoint() {
    return this.config.get('widgetEndpoint');
  }

  get boldBIServerEndpoint() {
    return this.config.get('boldBIServerEndpoint');
  }

  showExperimentalFeatures(organisationId) {
    return (
      this.config.get('showExperimentalFeature') ||
      ELIGIBLE_ORGANISATIONS.includes(organisationId)
    );
  }

  showForPostProdOnly(organisationId) {
    return (
      this.config.get('showExperimentalFeature') ||
      ELIGIBLE_ORGANISATIONS[0] === organisationId
    );
  }

  get availableCountries() {
    return this.config.get('availableCountries');
  }

  get currentRegion() {
    return this.availableCountries.some((country) => {
      return country === 'us';
    })
      ? 'us'
      : 'za';
  }

  get hackToken() {
    return this.config.get('hackToken');
  }
}
