import { LogManager, customElement, bindable, computedFrom, autoinject, observable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

import { SessionStore, DownloadService, ApplicationProperties, SearchTools, Event } from 'zailab.common';
import { InteractionModel } from '../../interaction-model';
import { ConversationModel } from '../../conversation-model';
import { ConversationService } from '../../conversation-service';
import { ContactService } from '../../../contact/contact-service';
import { CallInteractionCardService } from './call-interaction-card-service';
import { INTERACTION_ACTIONS } from '../../../contact/contactcontroller/contact-controller-actions';
import { ContactModel } from "../../../contact/contact-model";
import { ZIOplog } from '../../../../../../typings/zai/zai.common';
import { ZContextMenu } from "../../../../../components/molecules/card/context-menu/z-context-menu";
import { TelephonyService } from '../../../../telephony/telephony-service';
import { MemberModel } from "../../member-model";
import { FeatureFlagService } from "../../../../featureflags/feature-flag-service";
import { InteractionService } from '../../../interactions/interaction-service';
import { MemberInteractionModel } from '../../interaction/member-interaction-model';
import { DispositionCodesService } from '../../../organisation/dispositioncodes/disposition-codes-service';
import { DefaultDispositionCode, DispositionCode, DispositionModel } from '../../../organisation/dispositioncodes/disposition-codes-model';
import { WorkTypesService } from '../../worktypes/work-types-service';
import { DiallerLoginService } from "../../../member/dialler-login/dialler-login-service";
import { WaypointModel } from '../../waypoint-model';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { UserSessionModel } from '../../../../../_common/stores/sessionmodels/user-session-model';
import { inject } from 'aurelia-dependency-injection';

interface EventSubscriptions {
  interactionEnded: Object;
  clickToDialFailed: Object;
}

const logger = LogManager.getLogger('CallInteractionCard');

@customElement('z-call-interaction-card')
@inject(
  Element,
  EventAggregator,
  SessionStore,
  ConversationService,
  DownloadService,
  ContactService,
  CallInteractionCardService,
  TelephonyService,
  ApplicationProperties,
  FeatureFlagService,
  InteractionService,
  DispositionCodesService,
  WorkTypesService,
  DiallerLoginService
)
export class CallInteractionCard {
  private instanceId: string = uuidv4();

  @bindable private interaction: InteractionModel;
  @bindable private contactid: string;
  @bindable private contact: ContactModel;
  @bindable private unlinked: boolean;
  @bindable private conversations: ConversationModel[];
  @bindable public onCall: boolean;
  private recordingURL: string = null;
  private organisationId: string = null;
  @observable private searchCriteria: string = null;
  private searchCriteriaTransferList: string = '';
  private isPlaying: boolean = false;
  private retrievingUrl: boolean = false;
  private toggleCallBoxView: boolean = false;
  private audioPlayer: HTMLAudioElement;
  private playTime: number = 0;
  private audioInterval: any;
  private subscriptions: { key:string, subscription: Subscription }[] = [];
  private displaySearchResults: boolean = false;
  private displaySearchResultsTransferList: boolean = false;
  protected oplog: ZIOplog = null;
  protected oplogInteraction: ZIOplog = null;
  private isOnWrapup: boolean = false;

  public dispositionCode: DefaultDispositionCode = null;
  public disposition: string = null;

  public hasAdditionalData: boolean = true;
  public additionalDataVisible: boolean = false;
  public additionalData = [];
  
  public voiceAuthenticatorEnabled: boolean;
  public viewRecordingsDisabled: boolean = true;

  constructor(
    private element: Element,
    protected eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private conversationService: ConversationService,
    private downloadService: DownloadService,
    private contactService: ContactService,
    private callInteractionCardService: CallInteractionCardService,
    private telephonyService: TelephonyService,
    private applicationProperties: ApplicationProperties,
    private featureFlagService: FeatureFlagService,
    private interactionService: InteractionService,
    private dispositionCodesService: DispositionCodesService,
    private workTypeService: WorkTypesService,
    private diallerLoginService: DiallerLoginService,
  ) {}

  public bind(): void {
    this.getFeatureFlags();
  }

  private getFeatureFlags(): void {
    this.featureFlagService.isEnabled('voiceAuthenticator')
      .then((enabled) => {
        this.voiceAuthenticatorEnabled = enabled;
      });
    this.featureFlagService.isEnabled('viewRecordingsDisabled')
      .then(async (viewRecordingsDisabled) => {
        this.viewRecordingsDisabled = viewRecordingsDisabled;
      });
  }

  public async attached(): Promise<void> {
    this.subscribe();

    if (this.contact && this.contact.servedInteraction) {
      this.toggleCallBoxView = !this.toggleCallBoxView;
    }
    this.organisationId = this.sessionStore.get.organisation.organisationId;
  }

  public showAdditionalData(): void {
    this.additionalDataVisible = true;
  }

  public triggerAdditionalDataButton(data: any): void {
    this.contactService.triggerRequest(data.value, data.requestMethod);
  }

  public async getAdditionalDataDisplay(data: any): Promise<string> {
    return await this.contactService.fetchFromUrl(data.value, data.requestProperty);
  }

  public initialiseRecording(): void {
    // @ts-ignore
    if (!this.interaction.recordingId || this.interaction.recordingURL) {
      return;
    }
    this.eventAggregator.publish(INTERACTION_ACTIONS.GET_RECORDING_URL, this.interaction);
  }

  private subscribe(): void {

    if (!this.subscriptions.find(item => item.key ===  INTERACTION_ACTIONS.WRAP_UP_STARTED)) {
      this.subscriptions.push({ key: INTERACTION_ACTIONS.WRAP_UP_STARTED, subscription:  this.eventAggregator.subscribe(INTERACTION_ACTIONS.WRAP_UP_STARTED, (data) => {
        if (!this.interaction) {
          return;
        }
        if (data.state === 'WRAP_UP' && !this.isOnWrapup && data.interactionId === this.interaction.interactionId) {
          this.interaction.isOnWrapup = true;
        } else {
          this.interaction.isOnWrapup = false;
        }
      })});
    }

    if (!this.subscriptions.find(item => item.key === INTERACTION_ACTIONS.WRAP_UP_ENDED)) {
      this.subscriptions.push({ key: INTERACTION_ACTIONS.WRAP_UP_ENDED, subscription: this.eventAggregator.subscribe(INTERACTION_ACTIONS.WRAP_UP_ENDED, (data) => {
        if (!this.interaction) {
          return;
        }
        if (this.isOnWrapup && data.interactionId === this.interaction.interactionId) {
          this.isOnWrapup = false;
        }
      })});
    }

    if (!this.subscriptions.find(item => item.key === 'end-interaction-wrap-up')) {
      this.subscriptions.push({ key: 'end-interaction-wrap-up', subscription: this.eventAggregator.subscribe('end-interaction-wrap-up', (interactionId) => {
        if (!this.interaction) {
          return;
        }
        if (this.isOnWrapup && interactionId === this.interaction.interactionId) {
          this.isOnWrapup = false;
        }
      })});
    }
  }

  @computedFrom('dispositionCode')
  public get dispositionTitle(): string {
    if (!this.dispositionCode) {
      return '';
    }
    let title = `Outcome: ${this.dispositionCode.conversationOutcome}\nReason: ${this.dispositionCode.interactionOutcomeReason}`;
    return title;
  }

  @computedFrom('dispositionCode')
  public get dispositionColor(): string {
    if (!this.dispositionCode) {
      return '';
    }
    return this.dispositionCode.isNegative ? 'var(--unsuccessful)' : this.dispositionCode.isPositive ? 'var(--successful)' : '';
  }

  public togglePlayPause(): void {
    if (!this.interaction.recordingId || !this.canRetrieveRecording) {
      return;
    }
    if (this.interaction.recording.canPlay) {
      this.toggleRecordingPlayback();
    } else {
      this.interaction.retrieveRecordingUrl()
        .then(() => this.toggleRecordingPlayback())
        .catch((err) => logger.error('togglePlayPause :: err=', err));
    }
  }

  private toggleRecordingPlayback(): void {
    if (!this.canRetrieveRecording) {
      this.audioPlayer.pause();
      return;
    }
    if (this.interaction.recording.recordingURL) {
      this.interaction.recording.toggle();
      if (this.interaction.recording.isPlaying) {
        this.audioPlayer.play();
      } else {
        this.audioPlayer.pause();
      }
    }
  }

  private addAudioPlayerEventListeners(): void {
    this.audioPlayer.onloadeddata = () => {
      if (this.audioPlayer && this.audioPlayer.duration) {
        this.interaction.recording.audioLength = this.audioPlayer.duration;
      }
    };

    this.audioPlayer.ontimeupdate = () => {
      if (this.audioPlayer && this.audioPlayer.currentTime) {
        this.interaction.recording.playTime = this.audioPlayer.currentTime;
      }
    };

    this.audioPlayer.onended = () => {
      this.interaction.recording.isPlaying = false;
      this.audioPlayer.currentTime = 0;
    };
  }

  private seek(): void {
    this.audioPlayer.currentTime = this.interaction.recording.playTime;
  }

  private removeSubscriptions(): void {
    this.subscriptions.forEach((subscription) => subscription.subscription.dispose());
  }

  public colorChanged(data: { timeInSeconds: number, color: string }): void {

    new Event(this.element, 'interaction-color-change', {
      contactId: this.contact.contactId,
      correlationId: this.contact.correlationId || this.interaction.correlationId,
      color: data.color,
      timeInSeconds: data.timeInSeconds,
      interactionId: this.interaction.interactionId
    });
  }

  public endWrapUp(): void {
    this.isOnWrapup = false;
    const user = this.sessionStore.get.user;
    this.telephonyService.goOffWrapUp(user.memberId, this.interaction.interactionId, this.interaction.wrapUpChannelIds);
  }

  public selectDispositionCode(): void {
    this.eventAggregator.publish('member.select.disposition.code', {
      interactionId: this.interaction.interactionId,
      correlationId: this.contact.correlationId,
      conversationId: this.interaction.conversationId
    });
  }

  public openLink(url: string) {
    this.eventAggregator.publish('interaction-link-open', {
      firstName: this.contact.firstName,
      surname: this.contact.surname,
      remote: this.interaction.direction === 'OUTBOUND' ? this.interaction.to : this.interaction.from,
      url: url,
      interactionId: this.interaction.interactionId
    });
    this.eventAggregator.publish('dock-cards');
  }

  @computedFrom('interaction', 'interaction.agentName')
  public get interactionAgentName(): string {
    return this.interaction ? this.interaction.agentName : null;
  }

  public detached(): void {
    this.removeSubscriptions();
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
    if (this.oplogInteraction) {
      this.oplogInteraction.unsubscribe();
    }
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  @computedFrom('user')
  private get memberId(): string {
    return this.user.memberId;
  }

  @computedFrom('viewRecordingsDisabled', 'memberIsPartOfInteraction')
  public get canRetrieveRecording(): boolean {
    return !this.viewRecordingsDisabled && this.memberIsPartOfInteraction;
  }

  @computedFrom('interaction', 'interaction.journey', 'interaction.journey.length', 'memberId')
  public get memberIsPartOfInteraction(): boolean {
    
    if (!this.interaction || !this.interaction.journey) {
      return false;
    }
    let journeyStep = this.interaction.journey.find(item => {
      if (item.type === "CONNECT") { 
        return item._member.memberId === this.memberId;
      }
      return false;
    });
    return journeyStep ? true : false;
  }

}
