import { LogManager, customElement, bindable, inject } from 'aurelia-framework';

import { Event as CustomEvent } from 'zailab.common';

const logger = LogManager.getLogger('ZSearchDropdown');

@inject(Element)
@customElement('z-search-dropdown')
export class ZSearchDropdown {
  @bindable({ attribute: 'search-text-placeholder' })
  public searchTextPlaceholder: string = '';
  @bindable({ attribute: 'selected-value' }) public selectedValue: any;
  @bindable({ attribute: 'icon' }) public icon: string = 'search';
  @bindable({ attribute: 'items' }) public items: any[] = [];
  @bindable({ attribute: 'sub-items' }) public subItems: {
    [key: string]: string[];
  } = {};
  @bindable({ attribute: 'display-label' }) public displayLabel: string =
    undefined;
  @bindable({ attribute: 'query-on-input' }) public queryOnInput: boolean =
    false;
  @bindable({ attribute: 'show-more-enabled' })
  public showMoreEnabled: boolean = false;
  @bindable({ attribute: 'search-text' }) public searchText: any = '';
  @bindable({ attribute: 'is-multiple-select' })
  private isMultipleSelect: boolean = false;
  @bindable({ attribute: 'position' }) public position: string = 'bottom';
  @bindable({ attribute: 'max-height' }) public maxHeight: string;
  @bindable public height: string;
  @bindable public disabled: boolean;
  @bindable public error: boolean = false;
  @bindable public zindex: number;

  public jsSearchInput: HTMLInputElement;
  public displaySearchResults: boolean;

  private canClose: boolean = true;

  constructor(private element: Element) {}

  public bind(): void {
    if (
      typeof this.selectedValue === 'number' ||
      typeof this.selectedValue === 'string'
    ) {
      this.searchText = this.selectedValue;
    }
  }

  public showResults(event: Event): void {
    event.stopPropagation();
    this.displaySearchResults = true;
  }

  public closeDropdown(): void {
    setTimeout(() => {
      if (!this.canClose) {
        return;
      }
      this.displaySearchResults = false;
    }, 250);
  }

  public selectItem(event: Event, item: any): void {
    if (!item && item !== 0) {
      return;
    }
    const _item = this.displayLabel ? item[this.displayLabel] : item;
    if (this.subItems[_item]) {
      return;
    }
    if (!this.isMultipleSelect) {
      this.selectedValue = _item;
      this.searchText = _item;
    } else {
      this.searchText = '';
      this.displaySearchResults = false;
    }
    event.stopPropagation();
    new CustomEvent(this.element, 'select', item);
  }

  public valueChanged(): void {
    new CustomEvent(this.element, 'change', this.searchText);
  }

  public selectedValueChanged(newValue: any): void {
    if (!newValue && newValue !== 0) {
      this.clearSelection();
    }
  }

  private clearSelection(): void {
    setTimeout(() => {
      this.searchText = '';
    }, 50);
  }

  public doNothing(): void {}

  public partialMatch = (
    searchExpression: string,
    value: string,
    searchParam: string
  ): boolean => {
    if (
      !searchExpression ||
      searchExpression.length === 0 ||
      typeof value === 'number'
    ) {
      return true;
    }

    let _value = searchParam ? value[searchParam] : value;
    if (_value === 'Custom') {
      return true;
    }

    let match =
      _value.toLowerCase().indexOf(searchExpression.toLowerCase()) >= 0;
    if (match) {
      return match;
    } else {
      for (let key in this.subItems) {
        if (key.startsWith(searchExpression) && this.subItems[key]) {
          return !!this.subItems[key].find((_item) => _item === value);
        }
      }
    }
    return false;
  };

  public showMore(event: Event): void {
    this.ignoreClose();
    event.stopPropagation();
    new CustomEvent(this.element, 'show-more');
  }

  private ignoreClose(): void {
    this.canClose = false;
    setTimeout(() => {
      this.canClose = true;

      this.jsSearchInput && this.jsSearchInput.focus();
    }, 500);
  }
}
