import {autoinject, bindable, customElement, LogManager} from 'aurelia-framework';
//@ts-ignore
import {intlTelInputUtils} from '../../../../_assets/utils/utils';
import {intlTelInput} from 'intl-tel-input';
import {TelephoneNumberModel} from './telephone-number.model';
import { v4 as uuidv4 } from 'uuid';
import $ from 'jquery';
import {EventAggregator} from 'aurelia-event-aggregator';
import {SessionStore} from 'zailab.common';
import {ApplicationProperties} from '../../../../_config/application.properties';

const logger = LogManager.getLogger('ZPhoneNumberInput');

@customElement('z-phone-number-input')
@autoinject()
export class ZPhoneNumberInput {

  @bindable private phonenumber: TelephoneNumberModel;
  @bindable private placeHolder: string = '';
  @bindable({attribute: 'auto-placeholder-mode'}) private autoPlaceholderMode: string = 'aggressive';
  @bindable private onlyCountries: string = undefined;
  @bindable({ attribute: 'is-required' }) private isRequired: boolean = false;
  @bindable private invalidNumberMessage: string = 'Number is invalid';
  private customidentifier: string;
  private formattedId: string;
  private validationMessage: string = null;
  private eventSubscription: any;

  constructor(private eventAggregator: EventAggregator, private sessionStore: SessionStore, private applicationProperties: ApplicationProperties) {
    this.customidentifier = uuidv4();
  }

  public attached(): void {
    this.eventSubscription = this.eventAggregator.subscribe('PHONE.NUMBER.VALIDATE', () => this.validateNumber());
    this.eventSubscription = this.eventAggregator.subscribe('PHONE.NUMBER.RESET', () => this.validationMessage = '');
    this.setupNumberConfiguration();
    this.listenForKeyChanges();
  }

  public phonenumberChanged(newValue: TelephoneNumberModel): void {
    if (newValue && newValue.number && newValue.number.length > 0) {
      $(this.formattedId).intlTelInput('setNumber', newValue.number);
    } else {
      $(this.formattedId).intlTelInput('setNumber', '');
    }
  }

  private setupNumberConfiguration(): void {

    let organisationCountryCode = this.applicationProperties.availableCountries.some((country) => {return country === 'us'}) ? 'us' : 'za';
    if(this.sessionStore.get.organisation && this.sessionStore.get.organisation.country) {
      organisationCountryCode = this.sessionStore.get.organisation.country.code;
    }
    let availableCountries = this.onlyCountries ? this.onlyCountries.split(',') : undefined;
    $(intlTelInput);
    $(intlTelInputUtils);
    this.formattedId = '#' + this.customidentifier;
    $(this.formattedId).intlTelInput({
      onlyCountries: availableCountries,
      autoPlaceholder: this.autoPlaceholderMode,
      formatOnDisplay: false,
      initialCountry: organisationCountryCode || this.phonenumber.region,
      nationalMode: true,
      placeholderNumberType: this.setNumberType(this.phonenumber),
      utilsScript: intlTelInputUtils,
      allowDropdown: true,
      preferredCountries: [this.onlyCountries ? this.onlyCountries : organisationCountryCode],
      allowFail: true
    });
  }

  private listenForKeyChanges(): void {
    $(this.formattedId).on('keyup change countrychange paste', () => {
      let nullCheck = Object.keys($(this.formattedId).intlTelInput('getSelectedCountryData'));
      this.phonenumber.telephoneNumber = $(this.formattedId).intlTelInput('getNumber');
      if (this.phonenumber.validation) {
        this.validateNumber();
      }
      if (nullCheck.length !== 0) {
        this.phonenumber.region = $(this.formattedId).intlTelInput('getSelectedCountryData') ? $(this.formattedId).intlTelInput('getSelectedCountryData').iso2.toUpperCase() : '';
      }
    });
  }

  private setNumberType(phoneNumber: Object): string {
    if (phoneNumber && phoneNumber.type && phoneNumber.type.indexOf('mobile') >= 0) {
      return 'MOBILE';
    } else {
      return 'FIXED_LINE';
    }
  }

  public detached(): void {
    $(this.formattedId).intlTelInput('destroy');
    $(this.formattedId).remove();
    $(intlTelInputUtils).remove();
    this.eventSubscription.dispose();
  }

  private validateNumber(): void {
    let error = $(this.formattedId).intlTelInput('getValidationError');
    const numberTooShort = 2;
    const numberTooLong = 3;
    const numberPossible = 0;
    const invalidNumber = 4;


    switch (error) {
      case numberTooShort:
        this.validationMessage = 'Number is too short.';
        this.phonenumber.isNumberValid = false;
        break;
      case numberTooLong:
        this.validationMessage = 'Number is too long.';
        this.phonenumber.isNumberValid = false;
        break;
      case invalidNumber:
        if (this.phonenumber.number !== '') {
          this.validationMessage = this.invalidNumberMessage;
          this.phonenumber.isNumberValid = false;
        } else {
          if (this.isRequired) {
            this.validationMessage = 'Please enter a number.';
            this.phonenumber.isNumberValid = false;
            return;
          } else {
            this.validationMessage = '';
            this.phonenumber.isNumberValid = true;
          }
        }
        break;
      case numberPossible:
        this.validationMessage = '';
        this.phonenumber.isNumberValid = true;
        break;
      default:
        this.validationMessage = '';
    }
  }

  private keyPress(event: Object): boolean {
    const plusChar = 43;
    const maxChar = 57;
    const separatorChar = 31;
    const minChar = 48;
    if (event.charCode !== plusChar && event.charCode > separatorChar && (event.charCode < minChar || event.charCode > maxChar)) {
      return false;
    }
    return true;
  }

}
