import { HttpClient, HttpResponseMessage } from 'aurelia-http-client';
import { autoinject, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { OplogService, DownloadService, SessionStore } from 'zailab.common';
import { ConversationModel } from './conversation-model';
import { InteractionModel } from './interaction-model';
import { WaypointModel } from './waypoint-model';
import { StatusModel } from './status/status-model';
import { ConversationFactory } from './conversation-factory';
import { RecentConversationModel } from './recentconversations/recent-conversation.model';
import { ProcessingLayer } from './interaction/processing-layer';

const logger = LogManager.getLogger('ConversationService');

@autoinject()
export class ConversationService {
  private timeout: object = {};
  private delay: number = 0;
  private reconnectCount: number = 0;

  public conversationOplog = {
    subscribeToConversationInteractionsOplog: null,
  };

  constructor(
    private httpClient: HttpClient,
    private eventAggregator: EventAggregator,
    private oplogService: OplogService,
    private conversationFactory: ConversationFactory,
    private downloadService: DownloadService,
    private sessionStore: SessionStore
  ) {}

  /**
   *
   * Subscriptions
   *
   */

  public getInteractionOplog(interactionId: string): any {
    // @ts-ignore
    return this.oplogService
      .subscribeOn('_id', interactionId)
      .in('interaction-projector.interactionView');
  }

  public getConversationOplog(conversationId: string): any {
    // @ts-ignore
    return this.oplogService
      .subscribeOn('_id', conversationId)
      .in('conversation-projector.conversationSearchView');
  }

  public getInteractionJourneyOplog(conversationId: string): any {
    // @ts-ignore
    return this.oplogService
      .subscribeOn('_id', conversationId)
      .in('conversation-projector.conversationJourneyView');
  }

  public subscribeToRecentConversations(memberId: string): any {
    // @ts-ignore
    return this.oplogService
      .subscribeOn('interactionMembers.members', memberId)
      .in('conversation-projector.recentConversationsView');
  }

  /**
   *
   * Rest calls
   *
   */

  public retrieveInteraction(interactionId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/interactions/${interactionId}`)
      .asGet()
      .send()
      .catch(() => {
        return null;
      });
  }

  public retrieveConversations(
    contactId: string
  ): Promise<void | ConversationModel[]> {
    return this.httpClient
      .createRequest(`v1/organisation/conversations`)
      .asGet()
      .withParams({ contactId })
      .send()
      .then((response: any) => {
        return response.conversations;
      })
      .catch((error) => {
        return [];
      });
  }

  public retrieveTask(taskId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/interactions/tasks/${taskId}`)
      .asGet()
      .send()
      .catch((error) => {
        console.warn(' > Failed to retrieve task due to ', error);
      });
  }

  public retrieveUnlinkedInteractions(
    contactId: string
  ): Promise<InteractionModel[]> {
    return this.httpClient
      .createRequest(
        `/v1/organisation/contacts/${contactId}/unlinked-interactions`
      )
      .asGet()
      .send()
      .then((response: any) => {
        let noContent = 204;
        if (
          !response ||
          Object.keys(response).length === 0 ||
          response.statusCode === noContent
        ) {
          return [];
        } else {
          let interactions = [];

          response.interactions.forEach((interaction) => {
            interaction.contactId = contactId;

            ProcessingLayer.processInteraction(interaction).then(
              (modelledInteraction) => {
                interactions.push(modelledInteraction);
              }
            );
          });
          return interactions;
        }
      })
      .catch((error) => {
        return error;
      });
  }
  public retrieveConversation(
    conversationId: string,
    contactId: string
  ): Promise<void | Array<any>> {
    return new Promise((resolve) => {
      this.httpClient
        .createRequest(
          `v1/organisation/conversations/${conversationId}/waypoints`
        )
        .asGet()
        .withParams({ contactId })
        .send()
        .then((response) => {
          // @ts-ignore
          resolve(
            response && response.waypoints
              ? this.formatWayPoints(response.waypoints)
              : []
          );
        })
        .catch((error) => {});
    });
  }

  public retrieveConversationWaypoints(
    conversationId: string,
    contactId: string
  ): Promise<any> {
    return this.httpClient
      .createRequest(
        `v1/organisation/conversations/${conversationId}/waypoints`
      )
      .asGet()
      .withParams({ contactId })
      .send()
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  }

  public retrieveStatuses(): Promise<void | StatusModel[]> {
    return this.httpClient
      .createRequest('v1/organisation/organisations/me/reason-codes')
      .asGet()
      .send()
      .then((response: any) => {
        return this.mapStatusCodes(response.reasonCodes);
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }

  public retrieveInteractions(
    conversationId: string,
    contactId: string
  ): Promise<void | HttpResponseMessage | Array<any>> {
    let callback = (response: any) => {
      if (response && response.waypoints) {
        return response.waypoints;
      } else {
        return response;
      }
    };
    return this.httpClient
      .createRequest(
        `v1/organisation/conversations/${conversationId}/waypoints`
      )
      .asGet()
      .withParams({ contactId })
      .send()
      .then(callback)
      .catch((error) => {
        logger.info(' Conversation does not exist');
      });
  }

  public retrieveRecording(interactionId: string): any {
    let auth = 'Bearer ' + this.sessionStore.get.user.token;

    return this.downloadService
      .downloadWithAuth(
        'recording',
        `v1/media/recording/${interactionId}`,
        auth
      )
      .then((recordingBlob) => {
        let recordingBlobURI = URL.createObjectURL(recordingBlob);
        return { url: recordingBlobURI };
      })
      .catch((error) => {
        logger.warn('Failed to retrieve recoding due to cause', error);
      });
  }

  public checkRecording(url: string): Promise<any> {
    let succesStatus = 200;
    let errorStatus = 400;
    let endState = 4;
    let startState = 3;
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    return new Promise((resolve, reject) => {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === endState) {
          if (xhr.status !== 0) {
            if (xhr.status >= succesStatus && xhr.status < errorStatus) {
              xhr.abort();
              resolve(xhr);
            } else {
              xhr.abort();
              reject(xhr.statusText);
            }
          }
        }
        if (xhr.readyState === startState) {
          if (xhr.status !== 0) {
            if (xhr.status >= succesStatus && xhr.status < errorStatus) {
              xhr.abort();
              resolve(xhr);
            } else {
              xhr.abort();
              reject(xhr.statusText);
            }
          }
        }
      };
      xhr.send();
    });
  }

  public retrieveConversationHistory(
    memberId: string,
    status: string,
    page: number,
    size: number
  ): Promise<object[]> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`/v1/organisation/conversations/recent-conversations`)
        .asGet()
        .withParams({ memberId, status, page, size })
        .send()
        .then(
          (response: any) => {
            try {
              response.recentConversations = this.modelCollection(
                response.recentConversations,
                RecentConversationModel
              );
              resolve(response);
            } catch (e) {
              reject('No conversations match the criteria.');
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public modelCollection(items: any, Model: any): any {
    if (!items) {
      return;
    }

    if (items instanceof Array) {
      let mappedList: Array<any> = [];
      for (let item of items) {
        mappedList.push(new Model(item));
      }
      return mappedList;
    }

    let mappedObject: object = new Model(items);
    return mappedObject;
  }

  /**
   *
   * Post And Put
   *
   */

  public selectOutcome(
    interactionId: string,
    category: string,
    outcomeReason: string
  ): Promise<void> {
    const payload = { category, outcomeReason };

    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`/v1/organisation/interactions/${interactionId}/outcome`)
        .asPut()
        .withContent(payload)
        .send()
        .then(() => resolve())
        .catch(() => resolve());
    });
  }

  public createNewConversation(conversation: ConversationModel): void {
    this.httpClient
      .createRequest('v1/organisation/conversations')
      .asPost()
      .withContent({
        contactId: conversation.contactId,
        conversationId: conversation.conversationId,
        conversationName: conversation.conversationName,
        // @ts-ignore
        timestampOpened: conversation.relativeTime._i,
        status: conversation.status,
      })
      .send()
      .catch(() => {});
  }

  public createConversationAndLinkToInteraction(
    conversation: ConversationModel,
    interactionId: string,
    contactId: string
  ): void {
    this.httpClient
      .createRequest('v1/organisation/conversations')
      .asPost()
      .withContent({
        contactId: contactId,
        conversationId: conversation.conversationId,
        conversationName: conversation.conversationName,
        // @ts-ignore
        timestampOpened: conversation.relativeTime._i,
        status: conversation.status,
        interactionId: interactionId,
      })
      .send()
      .catch(() => {});
  }

  public discardConversation(conversationId: string): void {
    this.httpClient
      .createRequest(`v1/organisation/conversations/${conversationId}`)
      .asDelete()
      .send()
      .catch(() => {});
  }

  public renameConversation(
    conversationId: string,
    conversationName: string
  ): void {
    this.httpClient
      .createRequest(`v1/organisation/conversations/${conversationId}/rename`)
      .asPut()
      .withContent({ conversationName })
      .send()
      .catch(() => {});
  }

  public linkInteractionToConversation(
    interactionId: string,
    conversationId: string,
    contactId: string
  ): void {
    this.httpClient
      .createRequest(
        `v1/organisation/conversations/${conversationId}/link-interaction`
      )
      .asPut()
      .withContent({ interactionId, contactId })
      .send()
      .catch((error) => {
        logger.info(' link interaction to conversation >>>> ', error);
      });
  }

  public linkConversationToProspect(
    conversationId: string,
    prospectListId: string,
    prospectId: string
  ): void {
    this.httpClient
      .createRequest(
        `/v1/campaign/campaigns/prospectlist/${prospectListId}/prospect/${prospectId}/conversation/${conversationId}/link`
      )
      .asPut()
      .withContent({})
      .send()
      .catch(() => {});
  }

  public linkTaskToConversation(
    conversationId: string,
    taskId: string,
    campaignName: string,
    prospectListName: string,
    contactId: string,
    type?: string
  ): void {
    let params = {
      taskId,
      memberId: this.sessionStore.get.user.memberId,
      contactId,
      type: type || 'CAMPAIGN',
      metaData: {
        campaignName,
        prospectListName,
      },
    };

    this.httpClient
      .createRequest(
        `v1/organisation/conversations/${conversationId}/link-task`
      )
      .asPut()
      .withContent(params)
      .send()
      .catch((error) => {
        logger.info(' Failed to link task to conversation. error = ', error);
      });
  }

  public linkInteractionToContact(
    contactId: string,
    interactionId: string
  ): void {
    this.httpClient
      .createRequest(`v1/organisation/interactions/${interactionId}/link`)
      .asPut()
      .withContent({ contactId })
      .send()
      .catch((error) => {
        logger.warn(' Failed to link interaction to contact. error = ', error);
      });
  }

  public addNote(
    noteId: string,
    conversationId: string,
    note: string,
    memberId: string
  ): void {
    this.httpClient
      .createRequest(`v1/organisation/conversations/${conversationId}/notes`)
      .asPost()
      .withContent({ noteId, content: note, memberId })
      .send()
      .catch(() => {});
  }

  public reviewStatus(
    memberId: string,
    conversationId: string,
    status: string,
    reason: string
  ): void {
    this.httpClient
      .createRequest(`v1/organisation/conversations/${conversationId}/status`)
      .asPut()
      .withContent({ memberId, status, reason })
      .send()
      .catch(() => {});
  }

  /**
   *
   * Data Formatter
   *
   */

  public formatInteractionWaypoints(
    waypoints: WaypointModel[]
  ): WaypointModel[] {
    let interactionJourneyList = [];
    waypoints.forEach((waypoint) => {
      interactionJourneyList.push(new WaypointModel(waypoint));
    });
    return interactionJourneyList;
  }

  private buildInteraction(interaction: InteractionModel): InteractionModel {
    let newInteraction = new InteractionModel(interaction);
    newInteraction.waypoints = this.formatInteractionWaypoints(
      interaction.journey.waypoints
    );
    return newInteraction;
  }

  private mapStatusCodes(response: any): StatusModel[] {
    let statusCodes: StatusModel[] = [];
    statusCodes.push(
      new StatusModel(
        'SUCCESSFUL',
        response && response.SUCCESSFUL ? response.SUCCESSFUL : [],
        ''
      )
    );
    statusCodes.push(
      new StatusModel(
        'UNSUCCESSFUL',
        response && response.UNSUCCESSFUL ? response.UNSUCCESSFUL : [],
        ''
      )
    );
    statusCodes.push(
      new StatusModel(
        'PENDING',
        response && response.PENDING ? response.PENDING : [],
        ''
      )
    );

    return statusCodes;
  }

  public formatWayPoints(waypoints: InteractionModel[]): InteractionModel[] {
    let conversationInteractionsList = [];
    let conversationServiceWaypointCallback = (waypoint) => {
      let modelledInteraction = new InteractionModel(waypoint);
      conversationInteractionsList.push(modelledInteraction);
    };
    waypoints.forEach((waypoint) =>
      conversationServiceWaypointCallback(waypoint)
    );
    return conversationInteractionsList;
  }
}
