import { inject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { bindingMode } from 'aurelia-binding';
import {
  validateTrigger,
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import { BootstrapFormRenderer, Event } from 'zailab.common';

import moment from 'moment';

import './z-form.scss';

@inject(Element, ValidationControllerFactory)
export class ZForm {
  @bindable({ attribute: 'form' }) public form: any[][];
  @bindable({ attribute: 'form-data' }) public formData: {
    [key: string]: string | boolean;
  };
  @bindable({ attribute: 'force-format-on-load' })
  public forceFormatOnLoad: boolean;
  @bindable({ attribute: 'publish-data-on-load' })
  public publishDataOnLoad: boolean;
  @bindable({
    attribute: 'validate-form',
    defaultBindingMode: bindingMode.twoWay,
  })
  public validateForm: () => void;

  private validation: ValidationController;
  private fieldWidth: number;

  constructor(
    private element: Element,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public bind(): void {
    if (this.forceFormatOnLoad) {
      this.valueChanged();
    }
    this.validateForm = () => this.valueChanged();
    this.calculateFormFieldWidth();

    ValidationRules.customRule(
      'numbersOnly',
      (value) => /^[0-9]+$/.test(value) && !/[._]/.test(value), // Regular expression for numbers 0-9
      'Only numbers are allowed.'
    );

    this.form.forEach((row, parentIndex) => {
      row.forEach((field, index) => {
        if (field.validations) {
          let validationRules: any = ValidationRules.ensure('value');

          field.validations.regular.forEach((_validation, validationIndex) => {
            if (_validation.validation === 'required') {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }
              validationRules = validationRules
                .required()
                .withMessage(
                  _validation.validationMessage ||
                    'Please enter a ' + field.title
                );
            } else if (_validation.validation === 'email') {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }
              validationRules = validationRules
                .email()
                .withMessage(
                  _validation.validationMessage || 'Please enter a valid email.'
                );
            } else if (_validation.validation === 'numbers-only') {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }
              validationRules = validationRules
                .satisfiesRule('numbersOnly')
                .withMessage(
                  _validation.validationMessage || 'Only numbers are allowed.'
                );
            } else if (_validation.validation === 'text-whitespace-hyphen') {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }
              validationRules = validationRules
                .matches(/^[A-Za-z- ]+$/)
                .withMessage(
                  _validation.validationMessage ||
                    'Only alphabetical characters are allowed.'
                );
            } else if (
              _validation.validation === 'text-whitespace-hyphen-custom-var'
            ) {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }
              validationRules = validationRules
                .matches(/^[A-Za-z0-9\- ${}:.]+$/)
                .withMessage(
                  _validation.validationMessage ||
                    'Only alphanumerical characters are allowed.'
                );
            } else if (
              _validation.validation ===
              'text-whitespace-hyphen-fullstop-comma-question-exclamation'
            ) {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }
              validationRules = validationRules
                .matches(/^[A-Za-z0-9\- ,.!?'()[\]]+$/)
                .withMessage(
                  _validation.validationMessage ||
                    `Please enter valid characters.`
                );
            } else if (_validation.validation === 'unique-name') {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }

              ValidationRules.customRule(
                'uniqueName-' + parentIndex + '-' + validationIndex,
                (value) => {
                  const rule = _validation.value.find(
                    (item) =>
                      value && item.toLowerCase() === value.toLowerCase()
                  );
                  if (rule) {
                    return false;
                  }
                  return true;
                },
                _validation.validationMessage || 'Name must be unique.'
              );

              validationRules = validationRules.satisfiesRule(
                'uniqueName-' + parentIndex + '-' + validationIndex
              );
            } else if (_validation.validation === 'max-character-length') {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }
              ValidationRules.customRule(
                'max-character-length-' + parentIndex + '-' + validationIndex,
                (value) => value && value.length <= _validation.value,
                _validation.validationMessage ||
                  `Please enter a value with less or equal to ${_validation.value} characters.`
              );

              validationRules = validationRules.satisfiesRule(
                'max-character-length-' + parentIndex + '-' + validationIndex
              );
            } else if (_validation.validation === 'min-value') {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }
              ValidationRules.customRule(
                'minValue-' + parentIndex + '-' + validationIndex,
                (value) => {
                  if (field.type === 'date') {
                    const minDate = moment(_validation.value, 'DD/MM/YYYY');
                    const date = moment(value, 'DD/MM/YYYY');
                    return value === _validation.value || moment(minDate).isBefore(date);
                  }
                  return value >= _validation.value;
                },
                _validation.validationMessage ||
                  'Please specify a value higher or equal to ' +
                    _validation.value
              );

              validationRules = validationRules.satisfiesRule(
                'minValue-' + parentIndex + '-' + validationIndex
              );
            } else if (_validation.validation === 'max-value') {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }
              ValidationRules.customRule(
                'maxValue-' + parentIndex + '-' + validationIndex,
                (value) => value <= _validation.value,
                _validation.validationMessage ||
                  'Please specify a less or equal to ' + _validation.value
              );

              validationRules = validationRules.satisfiesRule(
                'maxValue-' + parentIndex + '-' + validationIndex
              );
            } else if (
              _validation.validation === 'custom-smaller-than-sibling'
            ) {
              if (validationIndex > 0) {
                validationRules = validationRules.then();
              }
              ValidationRules.customRule(
                'customMaxValue-' + parentIndex + '-' + validationIndex,
                (value) => {
                  const siblingId = _validation.field;
                  const sibling = this.findItemById(this.form, siblingId);
                  const _value = value ? parseInt(value) : 0;
                  const validationValue = sibling.value
                    ? parseInt(sibling.value)
                    : 0;

                  return _value < validationValue;
                },
                _validation.validationMessage ||
                  'Please specify a less than your Global Threshold.'
              );

              validationRules = validationRules.satisfiesRule(
                'customMaxValue-' + parentIndex + '-' + validationIndex
              );
            } else {
              console.error(
                ' Failed to set validation | type not implemented: ' +
                  _validation.validation
              );
            }
          });

          field.validations.regex.forEach((_regexp, validationIndex) => {
            if (validationIndex > 0) {
              validationRules = validationRules.then();
            }
            validationRules
              .matches(_regexp.validation)
              .withMessage(
                _regexp.validationMessage ||
                  'TODO | No validation message specified.'
              );
          });

          if (
            field.validations.regular &&
            field.validations.regular.length > 0
          ) {
            validationRules.on(this.form[parentIndex][index]);
          }
        }
      });
    });
    if (this.publishDataOnLoad) {
      this.valueChanged();
    }
  }

  public valueChanged(ignoreValidation?: boolean): void {
    const formData: { [key: string]: string | boolean } = {
      valid: false,
    };

    let promises = [];

    this.form.forEach((row) =>
      row.forEach((field) => {
        formData[field.id] =
          field.type === 'number' ? parseInt(field.value) : field.value;

        if (field.multiSelect) {
          formData[field.itemsId] = field.values;
        }
      })
    );

    this.form.forEach((row) => {
      promises.push(
        new Promise((resolve) => {
          row.forEach((field) => {
            formData[field.id] =
              field.type === 'number' ? parseInt(field.value) : field.value;
            if (field.multiSelect) {
              formData[field.itemsId] = field.values;
            }
            if (field.type === 'multi-selector') {
              formData[field.itemsId] = field.values;
            }
          });
          resolve({});
        })
      );
    });

    Promise.all(promises).then(() => {
      if (!ignoreValidation) {
        this.validation.validate().then((validation) => {
          formData.valid = validation.valid;
          this.formData = { ...formData };

          new Event(this.element, 'form-data-change', formData);
        });
      } else {
        new Event(this.element, 'form-data-change', formData);
      }
    });
  }

  public addItemToList(field: any, value: string | { label: string, value: string, icon?: string, group?: string }): void {
    if (!value) {
      return;
    }
    
    const label = field.displayField ? value[field.displayField] : value;
    if (!field.iconsEnabled) {
      if (field.values.indexOf(label) === -1) {
        field.values.push(label);
      }
    } else if (typeof value !== 'string') {
      if (JSON.stringify(field.values).indexOf(label) === -1) {
        field.values.push({
          label,
          value: value.value,
          icon: value.icon,
          group: value.group
        });
      }
    }
    field.value = '';
    this.valueChanged();
  }

  private calculateFormFieldWidth(): void {
    let maxLength = 0;

    for (const innerArray of this.form) {
      const innerArrayLength = innerArray.length;
      if (innerArrayLength > maxLength) {
        maxLength = innerArrayLength;
      }
    }

    this.fieldWidth = Math.floor(100 / maxLength);
  }

  private findItemById = (arr, targetId) => {
    for (const item of arr) {
      if (Array.isArray(item)) {
        // If the current item is an array, recursively search it
        const nestedResult = this.findItemById(item, targetId);
        if (nestedResult) {
          return nestedResult;
        }
      } else if (item.id === targetId) {
        // If the current item's id matches the target id, return it
        return item;
      }
    }
    // If no match is found, return null
    return null;
  };

  public isRequired(field: {
    type: string;
    required?: boolean;
    validations?: {
      regular: {
        validation: string;
      }[];
    };
  }): boolean {
    if (
      (
        field.type === 'label' &&
        field.required
      ) || (
        field.type !== 'header' &&
        field.validations &&
        field.validations.regular.find((val) => val.validation === 'required')
      )
    ) {
      return true;
    }
    return false;
  }

  public removeListItem(field: { values: any[] }, index: number): void {
    field.values.splice(index, 1);
    this.valueChanged();
  }

  public toggleSelected(value: { selected: boolean }): void {
    value.selected = !value.selected;
    this.valueChanged();
  }

  public doNothing(): void {
    //
  }

  public async showMore(field: any): Promise<void> {
    if (field.showMoreApiCall) {
      const data = await field.showMoreApiCall();
      if (data.length === 0 || field.pageSize && data.length < data.pageSize) {
        field.showMore = false;
      }
      field.options = field.options.concat(data);
    }
  }
}
