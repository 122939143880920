import { bindable, customElement, LogManager } from "aurelia-framework";
import { AgentScriptConnection, AgentScriptFlow, AgentScriptNode } from "./agent-script-designer-model";
import { confetti } from "../../../../_common/gamify/confetti";

const logger = LogManager.getLogger('AgentScriptCC');

@customElement('agent-script-cc')
export class AgentScriptCC {

  @bindable
  public flow: AgentScriptFlow;

  public loading: boolean;
  public node: AgentScriptNode;
  public conns: AgentScriptConnection[];
  public displayState = 'CURRENT_STEP';
  public history = [];
  public historyStepName: string;
  public historyStepText: string;
  public historyStepOption: string;

  public bind() {
    if (!this.flow) {
      return;
    }
    const startNode = this.flow.getStartNode();
    const nextNodeId = startNode.connectors[0].targetNodeId;
    this.loadNode(nextNodeId);
  }

  private loadNode(nodeId: string) {
    this.loading = true;
    setTimeout(() => {
      this.node = this.flow.getNode(nodeId);
      this.conns = this.node.connectionIds.map((connId) => this.flow.getConnection(connId));
      this.loading = false;
      if (!this.conns || !this.conns.length) {
        this.history.push({ name: this.node.name, text: this.node.text, option: 'End of Script' });
        confetti(36);
      }
    }, 500);
  }

  public next(event: any): void {
    const selectedOption = event.detail;
    this.history.push({ name: this.node.name, text: this.node.text, option: selectedOption.name });
    this.loadNode(selectedOption.destinationNodeId);
  }

  public setDisplayState(displayState: string): void {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.displayState = displayState;
    }, 500);
  }

  public showHistoryStep(event: any): void {
    this.historyStepName = event.detail.name;
    this.historyStepText = event.detail.text;
    this.historyStepOption = event.detail.option;
    this.setDisplayState('STEP');
  }
}
