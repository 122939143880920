
import {autoinject, LogManager} from 'aurelia-framework';
// @ts-ignore
import {DialogController, DialogService} from 'aurelia-dialog';

import { ConfirmDialog } from './../../../organisation/conversation/interactioncards/call/components/voiceauthenticator/confirm-dialog';
import { ConfigureAdditionalDataService } from './configure-additional-data-service';

const logger = LogManager.getLogger('ConfigureAdditionalDataDialog');

@autoinject()
export class ConfigureAdditionalDataDialog {

  private organisationId: string;
  private interactionFlowId: string;
  private additionalData: { variableName: string, displayName: string }[];
  private originalAdditionalData: { variableName: string, displayName: string }[];

  public tableColumns = ['Variable Name', 'Display Name'];
  public tableData = [];

  constructor(
    private dialogController: DialogController,
    private dialogService: DialogService,
    private configureAdditionalDataService: ConfigureAdditionalDataService
  ) {}

  public activate(
    model: {
      interactionFlow: {
        interactionFlowId: string,
        additionalData: {
          displayVariables: {
            variableName: string,
            displayName: string
          }[]
        }
      },
      organisationId: string
    }): void {
    logger.info('Model', model);
    this.interactionFlowId = model.interactionFlow.interactionFlowId;
    this.organisationId = model.organisationId;
    this.additionalData = model.interactionFlow.additionalData.displayVariables || [];
    this.originalAdditionalData = JSON.parse(JSON.stringify(this.additionalData))

    let tableData = [];
    
    this.additionalData.forEach(row => {
      tableData.push([row.variableName, row.displayName]);
    });
    
    this.tableData = tableData;
  }

  public valueChanged(tableData: any): void {
    this.additionalData = tableData.map(row => {
      return {
        displayName: row[1],
        variableName: row[0]
      }
    });
  }

  public cancel(): void {
    if (JSON.stringify(this.additionalData) !== JSON.stringify(this.originalAdditionalData)) {
      this.dialogService
        .open({
          viewModel: ConfirmDialog,
          model: {
            message: 'You have unsaved changes.' 
          }
        })
        .whenClosed(response => {
          if (response.wasCancelled) {
            return;
          }
          this.dialogController.cancel();
        });
      return;
    }
    this.dialogController.cancel();
  }

  public done(): void {
    this.confirmDone();
  }

  private confirmDone(): void {
    this.configureAdditionalDataService
      .setAdditionalData(
        this.interactionFlowId,
        this.organisationId,
        {
          displayVariables: this.additionalData
        }
      );
    this.dialogController.ok();
  }
}
