import { Factory, inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import {SessionStore} from "../../../../_common/stores/session-store";
import {ApplicationProperties} from "../../../../_config/application.properties";
import {DiallerLoginStatusModel} from "./dialler-login-status-model";
import {OplogService} from "../../../../_common/services/database-service";
import {ZIOplog} from "../../../../../typings/zai/zai.common";
import CampaignModel from "../../../campaigns/campaign/campaign-model";
const logger = LogManager.getLogger('DiallerLoginService');

@inject(HttpClient, SessionStore, ApplicationProperties, OplogService)
export class DiallerLoginService {
  private countryRegionMap: Map<string,string> = new Map([
    ['US','usw2'],
    ['ZA','za'],
    ['AT','euw1'],
    ['BE','euw1'],
    ['EE','euw1'],
    ['ES','euw1'],
    ['FI','euw1'],
    ['FR','euw1'],
    ['DE','euw1'],
    ['GR','euw1'],
    ['IE','euw1'],
    ['IT','euw1'],
    ['LV','euw1'],
    ['LT','euw1'],
    ['LU','euw1'],
    ['MT','euw1'],
    ['NL','euw1'],
    ['PT','euw1'],
    ['SK','euw1'],
    ['SI','euw1']
  ]);

  constructor(
    private httpClient: HttpClient,
    private sessionStore: SessionStore,
    private applicationProperties: ApplicationProperties,
    private oplogService: OplogService
  ) {
    this.httpClient = httpClient;
    this.sessionStore = sessionStore;
    this.applicationProperties = applicationProperties;
    this.oplogService = oplogService;
  }

  private configureClient(endPoint: any): void {
    let organisationCountryCode = this.sessionStore.get.organisation.country.code;
    let region = null;

    if (endPoint && endPoint.includes('region')) {
      region = this.countryRegionMap.get(organisationCountryCode);
      if(!region){
        region = 'za';
      }
      endPoint = endPoint.replace('region', region);
    }

    this.httpClient.configure(req => {
      req.withBaseUrl(endPoint);
    });
  }


  public loginToDialler = (memberId: string, campaignId: string) : Promise<any> => {
    return new Promise((resolve, reject) => {
      let url = `/v1/dialler/diallerMembers/` + memberId;
      let payload = {
        predictiveDialler: true,
        campaignId: campaignId
      };
      this.configureClient(this.applicationProperties.apiRouterEndpoint);
      this.httpClient.createRequest(url)
        .asPost()
        .withContent(payload)
        .send()
        .then(
          (response) => {
            logger.info('Dialler Login request successful');
            resolve("SUCCESS");
          },
          (error) => {
            logger.warn('Dialler Login request unsuccessful', error);
            reject(error);

          }
        );
      this.configureClient(this.applicationProperties.apiQueryEndpoint);
    });
  }
  public logOutOfDialler = (memberId: string) : Promise<any> => {
    return new Promise((resolve, reject) => {
      let url = `/v1/dialler/diallerMembers/` + memberId;
      this.configureClient(this.applicationProperties.apiRouterEndpoint);
      this.httpClient.createRequest(url)
        .asDelete()
        .send()
        .then(
          (response) => {
            resolve("SUCCESS");
          },
          (error) => {
            logger.warn('Dialler Login request unsuccessful', error);
            reject(error);

          }
        );
      this.configureClient(this.applicationProperties.apiQueryEndpoint);
    });
  }
  public retrieveDiallerLoginStatus(memberId: string): Promise<DiallerLoginStatusModel> {
    let url = `v1/organisation/members/${memberId}/predictive-dialler-login-status`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).then(
        response => {
          let diallerLoginStatus = new DiallerLoginStatusModel(response);
          resolve(diallerLoginStatus);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public initialiseDiallerLoginStatusOplog(memberId: string): Promise<ZIOplog> {
    return new Promise(resolve => {
      resolve(this.oplogService.subscribeOn('_id', memberId).in('member-projector.predictiveDiallerLoginStatusView'));
    });
  }

  public retrieveCampaigns(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = 'v1/campaign/campaigns';
      this.httpClient
        .createRequest(url)
        .asGet()
        .send()
        .then(
          response => {
            if (response.statusCode === 204) {
              response.numberOfTasksRemaining = 0;
            }

            response.campaigns = this._modelCollection(response.campaigns || []);
            resolve(response);
          },
          error => {
            reject(error);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }

  private _modelCollection(response: any): any[] {
    let list = [];
    response.forEach(item => {
      let campaign = new CampaignModel(item);
      if (!campaign.archived) {
        list.push(campaign);
      }
    });
    return list;
  }


}