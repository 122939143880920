import { PLATFORM } from 'aurelia-pal';

export function configure(aurelia) {
  aurelia
    // hud
    .globalResources(PLATFORM.moduleName('./user/passport/presence/presence'))
    .globalResources(
      PLATFORM.moduleName('./organisation/member/routing-status/routing-status'),
      PLATFORM.moduleName('./organisation/alert/count/alert-count'),
      PLATFORM.moduleName('./organisation/mailbox/count/mailbox')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/qualityassurance/surveys/surveys')
    )

    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactionjourney/interaction-journey'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactiontimeline/interaction-timeline'
      )
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/contact/contactcard/contact-card')
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/contact/contactcontroller/contact-controller'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './interaction/call/interaction-links/component/interaction-links'
      )
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/contact/contact/contact')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/contact/searchcontact/search-contact')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/contact/createcontact/create-contact')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/contact/editcontact/edit-contact')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/contact/emptystate/empty-state')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/conversation/list/list')
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/viewinteraction/view-interaction'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/conversationinteractions/conversation-interactions'
      )
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/conversation/actionmenu/action-menu')
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/calloutcome/call-outcome'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/ticket/ticket-interaction-card'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/email/email-interaction-card'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/sms/sms-interaction-card'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/call/call-interaction-card'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/note/note-interaction-card'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/instantmessage/im-interaction-card'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/campaign/campaign-interaction-card'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/reminder/reminder-interaction-card'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/unlinkedinteractions/unlinked-interactions'
      )
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/conversation/status/status')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/conversation/channel/channel')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/conversation/channel/views/note/note')
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/channel/views/email/email'
      )
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/conversation/channel/views/sms/sms')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/conversation/channel/views/chat/chat')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/conversation/channel/views/call/call')
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/conversation/wrapup/wrap-up')
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/dispositionlist/disposition-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName('./organisation/conversation/reminder/create/create')
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/call/components/voiceauthenticator/voice-authenticator'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/call/components/transfertomember/transfer-to-member'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/call/components/transfertoworktype/transfer-to-worktype'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/conversation/interactioncards/call/components/transfertotrasferlist/transfer-to-transfer-list'
      )
    )

    //admin
    .globalResources(
      PLATFORM.moduleName(
        './organisation/organisation/channels/condensedchannelslist/condensed-channels-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/organisation/capacityrules/condensedcapacityruleslist/condensed-capacity-rules-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/organisation/classofservice/condensedcoslist/condensed-cos-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/organisation/services/condensedserviceslist/condensed-services-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/organisation/sites/condensedsiteslist/condensed-sites-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/organisation/skills/condensedskillslist/condensed-skills-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/organisation/roles/condensedroleslist/condensed-roles-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/businesspartner/condensedbusinesspartnerlist/condensed-business-partner-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/contactcenter/condensedcontactcenterlist/condensed-contact-center-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        './organisation/member/teams/condensedteamslist/condensed-teams-list'
      )
    )
    .globalResources(
      PLATFORM.moduleName('./finance/account/stripecard/stripe-card')
    )

    //interaction
    .globalResources(
      PLATFORM.moduleName(
        './interaction/dashboard/live-dashboard/overview/live-dashboard-overview'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        './organisation/report/forms/agent-survey-rating-monthly'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        './media/alert-sound-config-dialog/alert-sound-config-dialog'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        './quality-assurance/agent-script/admin-config/agent-script-admin-config-create-dialog'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        './quality-assurance/agent-script/admin-config/agent-script-admin-config-update-dialog'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        './quality-assurance/agent-script/admin-config/agent-script-node-dialog'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        './quality-assurance/agent-script/admin-config/agent-script-cc-current-step'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        './quality-assurance/agent-script/admin-config/agent-script-cc-history'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        './quality-assurance/agent-script/admin-config/agent-script-cc-step'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        './quality-assurance/agent-script/admin-config/agent-script-cc'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        './quality-assurance/agent-script/admin-config/agent-script-preview-dialog'
      )
    );
}
