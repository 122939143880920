import { ArrayTools, BootstrapFormRenderer, EncryptTools, SessionStore } from 'zailab.common';
import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import { MicrosoftTeamsPresenceService } from '../microsoft-teams-presences/microsoft-teams-presences-service';
//@ts-ignore
import { DialogController } from 'aurelia-dialog';
//@ts-ignore
import { ITab1ConnectingConfig } from '../microsoft-teams-presences/tabs/tab-1-connecting-config/tab-1-connecting-config';
//@ts-ignore
import { ITab2PresenceMapping } from './tabs/tab-2-presence-mapping/tab-2-presence-mapping';

const logger = LogManager.getLogger('MicrosoftTeamsPresenceDialog');

interface ITab1ConnectingConfig {
  enabled: boolean;
  clientId: string;
  tenantId: string;
  clientSecret: string;
  valid?: boolean;
}

interface ITab2PresenceMapping {
  presenceMappings: { [key: string]: string };
}

@autoinject
export class MicrosoftTeamsPresencesDialog {
  public tabs: any[] = [];
  public ready: boolean = false;
  public loading: boolean = false;
  private msTeamsPresences: any;

  constructor(
    private dialogController: DialogController,
    private microsoftTeamsPresenceService: MicrosoftTeamsPresenceService,
    private sessionStore: SessionStore,
  ) {
    this.changeTab = this.changeTab.bind(this);
  }

  public async activate(data: { data: ITab1ConnectingConfig & ITab2PresenceMapping }): Promise<void> {
    this.loading = true;
    await this.retrieveMSTeamConfig();

    this.tabs = [
      {
        id: '1',
        name: 'Configuration',
        //@ts-ignore
        viewModel: PLATFORM.moduleName('features/integrations/microsoft-teams-presences/tabs/tab-1-connecting-config/tab-1-connecting-config'),
        icon: 'services',
        selected: true,
        data: {
          valid: this.msTeamsPresences && this.msTeamsPresences.id,
          enabled: this.msTeamsPresences ? this.msTeamsPresences.enabled : false,
          clientId: this.msTeamsPresences ? this.msTeamsPresences.properties.clientId : '',
          tenantId: this.msTeamsPresences ? this.msTeamsPresences.properties.tenantId : '',
          clientSecret: this.msTeamsPresences ? this.msTeamsPresences.encryptedProperties.find(ep => ep.key === 'clientSecret').hashedValue : '',
        }
      },
      {
        id: '2',
        name: 'Presence Mapping',
        //@ts-ignore
        viewModel: PLATFORM.moduleName('features/integrations/microsoft-teams-presences/tabs/tab-2-presence-mapping/tab-2-presence-mapping'),
        icon: 'services',
        selected: false,
        data: {
          presenceMappings: this.msTeamsPresences ? this.msTeamsPresences.properties.presenceMappings : {}
        }
      }
    ];
    this.ready = true;
    this.loading = false;
  }

  private async retrieveMSTeamConfig(): Promise<void> {
    const orgId = this.sessionStore.get.organisation.organisationId;
    this.msTeamsPresences = await this.microsoftTeamsPresenceService.getMSTeamsPresences(orgId)
      .catch(error => logger.error(' > Failed to get MS Presence Mappings due to', error));
  }

  private changeTab(index: number): void {
    if (index >= 0 && index < this.tabs.length) {
      this.tabs.forEach((tab, i) => {
        tab.selected = (i === index);
      });
    }
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public async save(): Promise<void> {
    const tab1Data: ITab1ConnectingConfig = this.tabs[0].data;
    const tab2Data: ITab2PresenceMapping = this.tabs[1].data;


    if (!tab1Data.valid) {
      this.changeTab(0);
      return;
    }
    
    this.loading = true;

    const originalClientSecretHash = this.msTeamsPresences ? this.msTeamsPresences.encryptedProperties.find(ep => ep.key === 'clientSecret').hashedValue : null;
    let clientSecretData;

    if (tab1Data.clientSecret === originalClientSecretHash) {
      clientSecretData = {
        key: 'clientSecret',
        value: null,
        valueHash: originalClientSecretHash

      };
    } else {
      clientSecretData = {
        key: 'clientSecret',
        value: tab1Data.clientSecret || null,
        valueHash: EncryptTools.encrypt(tab1Data.clientSecret)
      };
    }

    const payload = {
      enabled: tab1Data.enabled,
      properties: {
        clientId: tab1Data.clientId,
        tenantId: tab1Data.tenantId,
        presenceMappings: tab2Data.presenceMappings
      },
      encryptedProperties: [
        clientSecretData
      ]
    };

    const orgId = this.sessionStore.get.organisation.organisationId;

    if (this.msTeamsPresences) {
      this.microsoftTeamsPresenceService
        .updateMSTeamsPresenceMapping(
          orgId,
          payload.enabled,
          payload.properties,
          payload.encryptedProperties
        )
        .then(() => this.dialogController.ok())
        .catch(error => {
          logger.error(' > Failed to update MS Presence Mappings due to', error);
          this.loading = false;
        });
    } else {
      this.microsoftTeamsPresenceService
        .createMSTeamsPresenceMapping(
          orgId,
          payload.enabled,
          payload.properties,
          payload.encryptedProperties
        )
        .then(() => this.dialogController.ok())
        .catch(error => {
          logger.error(' > Failed to create MS Presence Mappings due to', error);
          this.loading = false;
        });
    }
  }

  public get isValid(): boolean {
    if (!this.tabs) {
      return false;
    }
    return this.tabs[0].data.valid && Object.keys(this.tabs[1].data.presenceMappings).length > 0;
  }
}
