import { OplogManager } from './oplog-manager';
import { StatusModel } from './../../conversation/status/status-model';
import { ContactCardTools } from './contact-card-tools';
import { MESSAGE_EVENTS } from 'zailab.common';
import { ConversationFactory } from './../../conversation/conversation-factory';
import { ReminderService } from './../../conversation/reminder/reminder-service';
import { ChannelService } from './../../conversation/channel/channel-service';
import { TicketModel } from './../../conversation/interaction/ticket-model';
import { ContactService } from './../contact-service';
import { ConversationModel } from './../../conversation/conversation-model';
import { ContactModel } from './../contact-model';
import { CONTACT_ACTIONS, CONVERSATION_ACTIONS, LOADER_ACTIONS, CONTROLLER_ACTIONS } from './contact-controller-actions';
import { Disposition, DispositionModel } from './../../organisation/dispositioncodes/disposition-codes-model';
import { DispositionCodesService } from './../../organisation/dispositioncodes/disposition-codes-service';
import { SessionStore } from './../../../../_common/stores/session-store';
import { ConversationService } from './../../conversation/conversation-service';
import { INTERACTION_ACTIONS } from './../../conversation/interaction/interaction-actions';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ContactState } from './contact-state';
import { ContactControllerSubscriptions } from './contact-controller-subscriptions';
import { autoinject, LogManager } from 'aurelia-framework';

// @ts-ignore
import moment from 'moment';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { InteractionModel } from '../../conversation/interaction-model';
import { MemberInteractionModel } from '../../conversation/interaction/member-interaction-model';

const logger = LogManager.getLogger('ContactCardFlow');

@autoinject
export class ContactCardEventing {

  private contactState: ContactState;
  private eventAggregator: EventAggregator;
  private conversationService: ConversationService;
  private sessionStore: SessionStore;
  private dispositionCodesService: DispositionCodesService;
  private contactService: ContactService;
  private channelService: ChannelService;
  private reminderService: ReminderService;
  private conversationFactory: ConversationFactory;
  private contactCardTools: ContactCardTools;
  private oplogManager: OplogManager

  constructor(
    private contactControllerSubscriptions: ContactControllerSubscriptions
  ) {}

  public init(
    contactState: ContactState,
    eventAggregator: EventAggregator,
    conversationService: ConversationService,
    sessionStore: SessionStore,
    dispositionCodesService: DispositionCodesService,
    contactService: ContactService,
    channelService: ChannelService,
    reminderService: ReminderService,
    conversationFactory: ConversationFactory,
    contactCardTools: ContactCardTools,
    oplogManager: OplogManager
  ): void {
    this.contactState = contactState;
    this.eventAggregator = eventAggregator;
    this.conversationService = conversationService;
    this.sessionStore = sessionStore;
    this.dispositionCodesService = dispositionCodesService;
    this.contactService = contactService;
    this.channelService = channelService;
    this.reminderService = reminderService;
    this.conversationFactory = conversationFactory;
    this.contactCardTools = contactCardTools;
    this.oplogManager = oplogManager;

    this.subscribeToEAMessages();
    this.contactCardTools.subscribeToCardChanges();
    
    this.contactCardTools.retrieveConversationsFlows();
    this.contactCardTools.retrieveReasonCodes();
  }

  private subscribeToEAMessages(): void {
    this.contactControllerSubscriptions.searchContact = 
      this.eventAggregator.subscribe(CONTACT_ACTIONS.SEARCH, searchText => this.contactCardTools.handleSearchContact(searchText));

    this.contactControllerSubscriptions.memberSelectDispositionCode = 
      this.eventAggregator.subscribe('member.select.disposition.code', (data: { interactionId: string, correlationId: string, conversationId: string }) => this.contactCardTools.selectedDispositionCode(data));

    this.contactControllerSubscriptions.callDisconnected = 
      this.eventAggregator.subscribe(INTERACTION_ACTIONS.MEMBER_DISCONNECTED_FROM_OUTBOUND_FLOW_CALL, data => this.contactCardTools.memberDisconnectedFromOutboundFlowCall(data));

    this.contactControllerSubscriptions.callDisconnected = 
      this.eventAggregator.subscribe(INTERACTION_ACTIONS.OUTBOUND_ENDPOINT_NOTIFIED, data => this.contactCardTools.callDisconnected(data));

    this.contactControllerSubscriptions.searchContactResult = 
      this.eventAggregator.subscribe(CONTACT_ACTIONS.SEARCH_RESULT, (info: { contactId: string, correlationId: string, contacts: ContactModel[], isManualSearch?: boolean }) => this.contactState.populateSearchResults(info));
    
    this.contactControllerSubscriptions.previousView = this.eventAggregator.subscribe(CONTACT_ACTIONS.PREVIOUS, (contactId: string) => this.contactState.previousView(contactId));

    this.contactControllerSubscriptions.selectContact = this.eventAggregator.subscribe(CONTACT_ACTIONS.SELECT_SEARCH, (selectedContact: ContactModel) => {
      this.oplogManager.subscribeToContactInfoChanges(selectedContact.contactId);
      this.oplogManager.subscribeToConversationListChanges(selectedContact.contactId);
      this.oplogManager.subscribeToUnlinkedInteractionChanges(selectedContact.contactId);
      this.contactState.selectContactFromSearch(selectedContact);
    });

    this.contactControllerSubscriptions.createContact = this.eventAggregator.subscribe(CONTACT_ACTIONS.CREATE, (contact: ContactModel) => this.contactCardTools.createContact(contact));

    this.contactControllerSubscriptions.updateContact = this.eventAggregator.subscribe(CONTACT_ACTIONS.UPDATE, (contact: ContactModel) => this.contactService.updateContact(contact));

    this.contactControllerSubscriptions.createConversation = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.CREATE, (contactId: string) => this.contactState.createConversation(contactId));

    this.contactControllerSubscriptions.selectConversation = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.SELECT, conversation => this.contactState.selectConversation(conversation));

    this.contactControllerSubscriptions.autoSelect = this.eventAggregator.subscribe(CONTACT_ACTIONS.AUTOSELECT, (data: { contactId: string, correlationId: string }) => this.contactState.autoSelectConversation(data));

    this.contactControllerSubscriptions.conversationDiscard = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.DISCARD, (conversation: ConversationModel) => this.contactState.removeConversation(conversation));

    this.contactControllerSubscriptions.changeView = this.eventAggregator.subscribe(CONTACT_ACTIONS.CHANGE_VIEW, (viewObject: { viewStrategy: string, contactId: string }) => this.contactState.changeView(viewObject));

    this.contactControllerSubscriptions.changeView = this.eventAggregator.subscribe(CONTACT_ACTIONS.CHANGE_VIEW_SELECTED, (viewObject: { viewStrategy: string, contactId: string }) => this.contactState.changeViewSelected(viewObject));

    this.contactControllerSubscriptions.changeConversationName = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.CHANGE_NAME, (changeInfo: { newName: string, conversationId: string }) => this.contactState.updateConversationName(changeInfo.conversationId, changeInfo.newName));

    this.contactControllerSubscriptions.conversationListChanged = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.LIST_UPDATE_OPLOG, ((conversation: ConversationModel) => this.contactState.updateConversation(conversation)));

    this.contactControllerSubscriptions.reviewConversationStatus = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.REVIEW_STATUS, (reviewInfo: { status: string, conversationId: string, reason: string, member: string }) => this.contactState.reviewConversationStatus(this.sessionStore.get.user.memberId, reviewInfo.status, reviewInfo.reason, reviewInfo.conversationId));

    this.contactControllerSubscriptions.conversationListInsert = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.LIST_INSERT_OPLOG, ((conversation: ConversationModel) => this.contactState.handleConversationCreated(conversation)));
    
    this.contactControllerSubscriptions.selectUnlinkedInteraction = this.eventAggregator.subscribe(INTERACTION_ACTIONS.SELECT_UNLINKED, async (interaction: InteractionModel | TicketModel) => this.contactState.selectUnlinkedInteraction(interaction));

    this.contactControllerSubscriptions.selectInteraction = this.eventAggregator.subscribe(INTERACTION_ACTIONS.SELECT, (data: { interaction: InteractionModel | TicketModel, contactId: string, auto?: boolean }) => this.contactState.selectInteraction(data));

    this.contactControllerSubscriptions.toggleLoader = this.eventAggregator.subscribe(LOADER_ACTIONS.TOGGLE_LOADER, (info: { contactId: string, status: boolean, loader: string }) => this.contactState.toggleLoader(info));

    this.contactControllerSubscriptions.changeChanelView = this.eventAggregator.subscribe('change.channel.view', (info: { contactId: string, channel: string }) => this.contactState.changeContactCardChannel(info));
    this.contactControllerSubscriptions.channelSizeExpand = this.eventAggregator.subscribe('change.channel.size.expand', () => this.contactState.expandContactCardChannels());
    this.contactControllerSubscriptions.channelSizeCollapse = this.eventAggregator.subscribe('change.channel.size.collapse', () => this.contactState.collapseContactCardChannels());
    this.contactControllerSubscriptions.channelSizeSmall = this.eventAggregator.subscribe('change.channel.size.reset', () => this.contactState.resetContactCardChannels());

    this.contactControllerSubscriptions.clickToDial = this.eventAggregator.subscribe(CONTROLLER_ACTIONS.CLICK_TO_DIAL, (call: { contactId: string, conversationId: string, correlationId: string, channel: string, flow: object, email: string, prospectId: string, recipientNumber: string, type: string, additionalProperties: any }) => this.contactCardTools.clickToDial(call));

    this.contactControllerSubscriptions.sendEmail = this.eventAggregator.subscribe(CONTROLLER_ACTIONS.SEND_EMAIL, (email: InteractionModel) => this.contactCardTools.sendEmail(email));

    this.contactControllerSubscriptions.sendSms = this.eventAggregator.subscribe(CONTROLLER_ACTIONS.SEND_SMS, (sms: InteractionModel) => this.contactCardTools.sendSms(sms));

    this.contactControllerSubscriptions.addNote = this.eventAggregator.subscribe(CONTROLLER_ACTIONS.ADD_NOTE, (note: InteractionModel) => this.conversationService.addNote(note.noteId, note.conversationId, note.message, note.memberId));

    this.contactControllerSubscriptions.connectedInteractionsRecovered = this.eventAggregator.subscribe(INTERACTION_ACTIONS.CONNECTED_INTERACTIONS_RECOVERED, (data: { interactions: Array<MemberInteractionModel>, initiateLinking: true }) => this.contactState.rehydrateInteractions(data));


    this.contactControllerSubscriptions.selectCallOutcome = this.eventAggregator.subscribe('select.call.outcome', (info: { interactionId: string, category: string, outcomeReason: string }) => this.contactCardTools.selectCallOutCome(info));

    this.contactControllerSubscriptions.selectDisposition = this.eventAggregator.subscribe('select.disposition', (disposition: Disposition) => this.contactCardTools.selectDisposition(disposition));

    this.contactControllerSubscriptions.showReminder = this.eventAggregator.subscribe('change.reminder', (info: { contactId: string, conversationId: string, showReminder: boolean }) => this.contactCardTools.changeReminder(info));

    this.contactControllerSubscriptions.selectContact = this.eventAggregator.subscribe(CONTACT_ACTIONS.SUBMIT_REMINDER, (reminderInfo: any) => this.contactCardTools.submitReminder(reminderInfo));

    this.contactControllerSubscriptions.unlinkedInteractionToSelect = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.UNLINKED_INTERACTION_TO_SELECT, (interaction: InteractionModel) => this.contactCardTools.setUnlinkedInteractionToSelect(interaction));

    this.contactControllerSubscriptions.createAndLinkConversation = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.CREATE_AND_LINK_INTERACTION, (data: InteractionModel | Array<InteractionModel>) => this.contactCardTools.createAndLinkConversation(data));


    this.contactControllerSubscriptions.wrapUpStart = this.eventAggregator.subscribe(INTERACTION_ACTIONS.WRAP_UP_STARTED, (data) => this.contactCardTools.wrapUpStart(data));

    this.contactControllerSubscriptions.wrapUpInteractionUpdated = this.eventAggregator.subscribe(INTERACTION_ACTIONS.WRAP_UP_INTERACTION_UPDATED, data => this.contactCardTools.wrapUpUpdated(data));

    this.contactControllerSubscriptions.wrapUpEnd = this.eventAggregator.subscribe(INTERACTION_ACTIONS.WRAP_UP_ENDED, (data) => this.contactState.removeWrapUp(data));
  }

}
