import { LogManager, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';

import { PlaceholderService } from '../../../../../_common/services/placeholder-service';
import { InAppAlertsService } from '../../in-app-alerts-service';
import { DashboardAlertsConfig } from '../dashboard-alerts-dialog/dashboard-alerts-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AbstractList, List } from 'zailab.abstract';
import { ArrayTools } from 'zailab.common';

const logger = LogManager.getLogger('Integrations List');

@autoinject()
export class IntegrationsList extends AbstractList {
  public loading: boolean;
  public itemList: any;
  public placeholders: number = 0;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private inAppAlertsService: InAppAlertsService,
    eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
  }

  public activate(): void {
    this.retrieveInAppAlerts();
  }

  private async retrieveInAppAlerts(): Promise<void> {
    const inAppAlerts = await this.inAppAlertsService
      .retrieveInAppAlerts()
      .catch((e) =>
        console.warn(' > Failed to retrieve in app alerts due to', e)
      );

    this.setupListView(inAppAlerts ? ArrayTools.sort(inAppAlerts, 'name') : []);
  }

  private setupListView(shiftTemplates: any[]): void {
    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableClick()
      .enableAdd()
      .enableDelete()
      .items(shiftTemplates)
      .uniqueIdentifier('id')
      .hasRollover(false)
      .tilesPerRow(5)
      .disableOrderBy()
      .build();
    this.generatePlaceholders();
    super.hideLoader();
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(
      this.container,
      this.itemList.items.length,
      3,
      (placeholders) => {
        this.placeholders = placeholders;
      }
    );
    if (this.placeholderService) {
      //@ts-ignore
      this.placeholders = this.placeholderService.generatePlaceholders(
        this.itemList.items.length
      );
    }
  }

  public navigateToAuditTrail(): void {
    this.router.navigate('in-app-alerts-audit');
  }

  public select(inAppAlert: any): void {
    if (this.itemList.isDeleting) {
      this.itemList.items.forEach((item) => (item.isDeleting = false));
      inAppAlert.isDeleting = true;
    } else {
      this.openInAppAlertsDialog(inAppAlert);
    }
  }

  public openInAppAlertsDialog(inAppAlert?: any): void {
    this.dialogService
      .open({
        viewModel: DashboardAlertsConfig,
        model: {
          inAppAlert,
          existingNames: this.itemList.items.map((item) => item.name),
          inAppAlertList: this.itemList.items,
        },
      })
      .whenClosed(
        // @ts-ignore
        (response: { output: IInAppAlert; wasCancelled: boolean }) => {
          if (response.wasCancelled) {
            return;
          }
          let item = this.itemList.items.find(
            (item) => item.id === response.output.id
          );
          if (item) {
            item = {
              name: response.output.name,
              description: response.output.description,
              message: response.output.message,
              severity: response.output.severity,
              repeatIntervalInSeconds: response.output.repeatIntervalInSeconds,
              inAppAlerts: response.output.inAppAlerts,
              audienceType: response.output.audienceType,
              audience: response.output.audience,
              source: response.output.source,
              conditions: response.output.conditions,
              triggers: response.output.triggers,
            };
          } else {
            this.itemList.items.push(response.output);
            ArrayTools.sort(this.itemList.items, 'name');
          }
          this.generatePlaceholders();
        }
      );
  }

  public delete(): void {
    const item = this.itemList.items.find((_item) => _item.isDeleting);
    if (!item) {
      return;
    }

    super.showLoader();
    this.inAppAlertsService
      .deleteInAppAlert(item.id)
      .then(() => {
        super.hideLoader();
        this.itemList.items.splice(this.itemList.items.indexOf(item), 1);
        this.generatePlaceholders();
        this.itemList.toggleDelete();
      })
      .catch((e) => {
        super.hideLoader();
        console.warn(' > Failed to remove shift template due to', e);
      });
  }

  public navigateToInAppAlerts(): void {
    this.router.navigate('');
  }
}
