import {autoinject, LogManager} from 'aurelia-framework';

import {MemberModel} from '../../member-model';
import { MembersService } from "../../../member/members/members-service";

const logger = LogManager.getLogger('Call Interaction Card Service');

@autoinject
export class CallInteractionCardService {
  constructor(private membersService: MembersService) {}

  public retrieveOrganisationMembers(name: string, pageNumber: number, pageSize: number): Promise<any> {
    return this.membersService.retrieveOrganisationMembersAndTotalMemberCount(undefined, name, undefined, pageNumber, pageSize);
  }

  public retrieveMemberStatuses(memberIds: string[]): Promise<any> {
    return this.membersService.retrieveMemberStatuses(memberIds);
  }

  public modelMembers(members: MemberModel[]): MemberModel[] {
    let list = [];
    members.forEach(member => {
      list.push(new MemberModel(member));
    });
    return list;
  }
}