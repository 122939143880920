import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { ControllerValidateResult, ValidationController, ValidationRules } from 'aurelia-validation';
import { CurrentScopeValidation } from '../../../../_common/validation/current-scope-validation';
import CampaignService from '../campaign-service';
/*
 */
const logger = LogManager.getLogger('ScheduleDncListDialog');
/*
 */
@autoinject
export class ScheduleDncListDialog {

  public campaign: any;
  public intervalUnitsSelect: HTMLSelectElement;
  public dncSchedule: DncSchedule = new DncSchedule();
  public intervalUnits = ['HOUR', 'DAY', 'WEEK', 'MONTH'];
  public isScheduleSet: boolean = false;
  public isProcessing: boolean = false;

  private validation: ValidationController;

  constructor(
    private readonly dialogController: DialogController,
    private readonly validationScope: CurrentScopeValidation,
    private readonly campaignService: CampaignService,
  ) {
    this.init();
  }

  private init(): void {
    this.validation = this.validationScope.getController();
  }

  private validationRules(): void {
    this.validation.reset();
    ValidationRules
      .customRule('sftpPortValid', (value) => {
        return value >= 1 && value <= 65535;
      }, 'Must be a valid number between 1 - 65535');
    ValidationRules
      .ensure((dncSchedule: DncSchedule) => dncSchedule.host).required()
      .ensure((dncSchedule: DncSchedule) => dncSchedule.port).required().then().satisfiesRule('sftpPortValid')
      .ensure((dncSchedule: DncSchedule) => dncSchedule.username).required()
      .ensure((dncSchedule: DncSchedule) => dncSchedule.password).required()
      .on(this.dncSchedule);
  }

  private retrieveData(): void {
    this.isProcessing = true;
    this.campaignService.retrieveDncSchedule(this.campaign.campaignId)
      .then((dncSchedule: any) => {
        if (dncSchedule) {
          this.isScheduleSet = true;
          this.dncSchedule = DncSchedule.fromResponse(dncSchedule);
          this.intervalUnitsSelect.disabled = true;
        }
        this.isProcessing = false;
      });
  }

  public activate(campaign: any): void {
    this.campaign = campaign;
    this.retrieveData();
    this.validationRules();
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public remove(): void {
    this.isProcessing = true;
    this.campaignService.removeDncSchedule(this.campaign.campaignId)
      .then(() => {
        this.isProcessing = false;
        this.dialogController.ok();
      });
  }

  public submit(): void {
    this.isProcessing = true;
    this.validation.validate()
      .then((result: ControllerValidateResult) => {
        if (result.valid) {
          const request = this.dncSchedule.toRequest();
          this.campaignService.setDncSchedule(this.campaign.campaignId, request)
            .then(() => {
              this.isProcessing = false;
              this.dialogController.ok();
            });
        } else {
          this.isProcessing = false;
        }
      });
  }
}

class SftpConfiguration {

  public host: string;
  public port: number;
  public username: string;
  public password: string;
  public path: string;
}

class DncSchedule extends SftpConfiguration {

  public intervalUnit: string;

  public static fromResponse(response: any): DncSchedule {
    const instance = new DncSchedule();
    instance.host = response.sftpConfiguration.host;
    instance.port = response.sftpConfiguration.port;
    instance.username = response.sftpConfiguration.username;
    instance.password = response.sftpConfiguration.password;
    instance.path = response.sftpConfiguration.path;
    instance.intervalUnit = response.intervalUnit;
    return instance;
  }

  public toRequest(): DncScheduleRequest {
    const request = new DncScheduleRequest();
    request.sftpConfiguration = new SftpConfiguration();
    request.sftpConfiguration.host = this.host;
    request.sftpConfiguration.port = this.port;
    request.sftpConfiguration.username = this.username;
    request.sftpConfiguration.password = this.password;
    request.sftpConfiguration.path = this.path;
    request.intervalUnit = this.intervalUnit;
    return request;
  }
}

class DncScheduleRequest {

  public sftpConfiguration: SftpConfiguration;
  public intervalUnit: string;
}
