import { DialogService } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { InfiniteScrollService } from '../../../../../_common/services/infinitescroll-service';
import { AssistantService } from '../assistants-service';
import { ViewCampaignAuditDialog } from './../../../../campaigns/campaign/audit/view-audit';

// @ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('AssistantAudit');
@autoinject
export class AssistantAudit {
  public header: string = 'Assistants Audit Trail';
  public searchTerm: string = '';
  public ready: boolean = false;
  public auditTrail: any[];
  public total: number | string;
  public isSearching: boolean = false;
  public tableContainer: Element;
  public infiniteScroll: InfiniteScrollService;
  private pageNumber: number = 0;
  private pageSize: number = 40;
  public canShowMore = false;

  constructor(
    private router: Router,
    private assistantService: AssistantService,
    private dialogService: DialogService
  ) {}

  public activate(): void {
    this.retrieveAuditTrail();
  }

  public attached(): void {
    this.initialiseInfiniteScroll();
  }

  public retrieveAuditTrail(): void {
    this.assistantService
      .retrieveAssistantsAuditTrail(this.pageNumber, this.pageSize)
      .then(response => {
        response.content.forEach((item) => {
          item.formattedTimestamp = moment(parseInt(item.updateData.timestamp)).format('D MMM YY HH:mm:ss');
        });
        this.auditTrail = (this.auditTrail || []).concat(response.content);
        this.total = response.total;
        this.canShowMore = this.auditTrail.length < response.totalElements;
        this.ready = true;
      })
      .catch((error) => {
        logger.error(' > failed to get assistant audits ', error);
        this.total = '--';
        this.ready = true;
      });
  }

  public showMore(): void {
    this.pageNumber++;
    this.retrieveAuditTrail();
  }

  private initialiseInfiniteScroll(): void {
    this.infiniteScroll = new InfiniteScrollService(this.tableContainer, () => {
      this.pageNumber++;
      this.retrieveAuditTrail();
    });
  }
  
  public viewAssistants(): void {
    this.router.navigate('');
  }

  public toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  public partialMatch(searchExpression: any, value: any): boolean {
    if (!searchExpression || searchExpression.length === 0) {
      return true;
    }
    searchExpression = searchExpression.toLowerCase();

    let assistantName = value.assistantName ? value.assistantName.toLowerCase() : '';
    let lastUpdatedBy = value.updateData.fullName ? value.updateData.fullName.toLowerCase() : '';
    let lastUpdatedByEmail = value.updateData.email ? value.updateData.email.toLowerCase() : '';
    let action = value.action ? value.action.toLowerCase() : '';
    return (
      assistantName.includes(searchExpression) ||
      lastUpdatedBy.includes(searchExpression) ||
      lastUpdatedByEmail.includes(searchExpression) ||
      action.includes(searchExpression)
    );
  }

  public viewChange(auditRecord: any): void {
    let model = {
      size: 'small'
    };
    if (auditRecord.updateData.values) {
      model = {
        ...auditRecord.updateData.values,
        ...model
      }
    }
    this.dialogService
      .open({
        viewModel: ViewCampaignAuditDialog,
        model
      })
      .whenClosed(() => { });
  }
}
