import { LogManager, inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
/*
 */
import { ApplicationProperties } from '../../_config/application.properties';
import { SessionStore } from 'zailab.common';
// @ts-ignore
import { AureliaConfiguration } from 'aurelia-configuration';
/*
 */
const logger = LogManager.getLogger('ClickToDialService');

/*
 */
@inject(HttpClient, ApplicationProperties, SessionStore, AureliaConfiguration)
export class ClickToDialService {
  countryRegionMap = new Map([
    ['US', 'usw2'],
    ['ZA', 'za'],
    ['AT', 'euw1'],
    ['BE', 'euw1'],
    ['EE', 'euw1'],
    ['ES', 'euw1'],
    ['FI', 'euw1'],
    ['FR', 'euw1'],
    ['DE', 'euw1'],
    ['GR', 'euw1'],
    ['IE', 'euw1'],
    ['IT', 'euw1'],
    ['LV', 'euw1'],
    ['LT', 'euw1'],
    ['LU', 'euw1'],
    ['MT', 'euw1'],
    ['NL', 'euw1'],
    ['PT', 'euw1'],
    ['SK', 'euw1'],
    ['SI', 'euw1'],
  ]);
  countryRegionMapDev1 = new Map([
    ['US', 'usw2'],
    ['ZA', 'za'],
    ['AT', 'eu'],
    ['BE', 'eu'],
    ['EE', 'eu'],
    ['ES', 'eu'],
    ['FI', 'eu'],
    ['FR', 'eu'],
    ['DE', 'eu'],
    ['GR', 'eu'],
    ['IE', 'eu'],
    ['IT', 'eu'],
    ['LV', 'eu'],
    ['LT', 'eu'],
    ['LU', 'eu'],
    ['MT', 'eu'],
    ['NL', 'eu'],
    ['PT', 'eu'],
    ['SK', 'eu'],
    ['SI', 'eu'],
  ]);

  constructor(
    httpClient,
    applicationProperties,
    sessionStore,
    aureliaConfiguration
  ) {
    this.httpClient = httpClient;
    this.applicationProperties = applicationProperties;
    this.sessionStore = sessionStore;
    this.aureliaConfiguration = aureliaConfiguration;
  }

  dial(
    src,
    dst,
    conversationId,
    outboundInteractionFlowId,
    contactId,
    campaignId,
    prospectListId,
    metadata,
    additionalProperties
  ) {
    const task = JSON.parse(
      localStorage.getItem(
        'contactTabsState' + this.sessionStore.get.user.memberId
      )
    );
    let content = {
      from: src,
      to: dst,
      metadata:
        task && task.payload && task.payload.metadata
          ? task.payload.metadata
          : {},
      webhookAdditionalProperties: additionalProperties,
    };

    if (metadata && metadata.correlationId) {
      content.metadata.correlationId = metadata.correlationId;
    }

    if (metadata && metadata.prospectId) {
      content.metadata.prospectId = metadata.prospectId;
    }

    if (task && task.outboundInteractionFlowId) {
      content.metadata.outboundInteractionFlowId =
        task.outboundInteractionFlowId;
    }

    if (conversationId) {
      content.metadata.conversationId = conversationId;
    }

    if (contactId) {
      content.metadata.contactId = contactId;
    }

    if (campaignId) {
      content.metadata.campaignId = campaignId;
      content.metadata.prospectListId = prospectListId;
    }

    if (outboundInteractionFlowId) {
      content.metadata.flowId = outboundInteractionFlowId;
    }

    content.metadata.isClickToDial = true;

    this.call(content);
  }

  call(content) {
    let url = this.getRegionBaseUrl() + `v1/telephony/calls`;
    this.httpClient
      .createRequest(url)
      .asPost()
      .withContent(content)
      .send()
      .then(
        (response) => {
          logger.info('click-to-dial-success', response);
        },
        (error) => {
          logger.warn('click-to-dial-failed', error);
        }
      );
  }

  getRegionBaseUrl() {
    let organisationCountryCode =
      this.sessionStore.get.organisation.country.code;
    let region = null;
    let url = this.applicationProperties.apiRouterEndpoint;

    const environment = this.aureliaConfiguration.environment;

    if (url && url.includes('region')) {
      if (environment === 'local' || environment === 'dev1') {
        region = this.countryRegionMapDev1.get(organisationCountryCode);
      } else {
        region = this.countryRegionMap.get(organisationCountryCode);
      }
      if (!region) {
        region = 'za';
      }
      url = url.replace('region', region);
    }
    return url;
  }
}
