import {autoinject, LogManager} from 'aurelia-framework';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';

import {CommandFactory, ExclusionListService, SESSION_EVENTS, WebSocket, WebSocketKiosk} from 'zailab.common';
import {ProcessingLayer} from './processing-layer';
import {InteractionModel} from "../interaction-model";
import {InputDialogMessageModel} from "../input-dialog-message-model";
import {DiallerMessageModel} from "../dialler-message-model";
import { TicketModel } from './ticket-model';

import moment from 'moment';

const logger = LogManager.getLogger('InteractionService');

@autoinject
export class InteractionService {
  private subscriptions = [];
  private rescheduledTasks = [];

  constructor(private webSocketKiosk: WebSocketKiosk, private eventAggregator: EventAggregator) { }

  public onWebSocketEvent(eventName: string, callback: (interaction: InteractionModel) => void, pingCallback?: (data: any) => void): void {
    this.webSocketKiosk.subscribe(eventName, data => {
      data.payload.isServed = true;
      logger.debug(this.getCurrentFormattedTime() + ' - CC | processing websocket message ', data);
      if(data.channel === 'TR.ALL') {
        data.payload.isCallBack = true;
        data.payload.channel = 'call';
      }
      if (data && data.payload && data.payload.type === 'PING') {
        pingCallback(data);
        return;
      }
      let processedInteraction = ProcessingLayer.processServedInteraction(data.payload);
      processedInteraction.routerId = data.source;
      callback(processedInteraction);
    });
  }

  public onWebSocketInputDialog(eventName: string, callback: (inputDialogMessage: InputDialogMessageModel) => void): void {
    this.webSocketKiosk.subscribe(eventName, data => {
      let inputDialogMessage: InputDialogMessageModel = data.payload;
      inputDialogMessage.routerId = data.source;
      callback(inputDialogMessage);
    });
  }

  public pongWebsocket(data: any): void {
    this.webSocketKiosk.pingEndpoint(data);
  }

  public captureInputDialog(inputDialogMessage: InputDialogMessageModel): void {
    inputDialogMessage.action = 'CAPTURED';
    let wsMessage = {
      target: inputDialogMessage.routerId,
      source: inputDialogMessage.userId,
      type: 'InputDialogMessage',
      payload: inputDialogMessage
    };

    this.webSocketKiosk.emitInteraction(wsMessage);
  }

  public cancelInputDialog(inputDialogMessage: InputDialogMessageModel): void {
    inputDialogMessage.action = 'CANCELLED';
    inputDialogMessage.value = null;
    let wsMessage = {
      target: inputDialogMessage.routerId,
      source: inputDialogMessage.userId,
      type: 'InputDialogMessage',
      payload: inputDialogMessage
    };

    this.webSocketKiosk.emitInteraction(wsMessage);
  }

  public onWebSocketDiallerMessage(eventName: string, callback: (diallerMessage: DiallerMessageModel) => void): void {
    this.webSocketKiosk.subscribe(eventName, data => {
      let diallerMessage: DiallerMessageModel = data.payload;
      callback(diallerMessage);
    });
  }



  public subscribeToInteractionNotifications(): void {
    this.subscribeToEndAllTasks();
  }

  public subscribeToEndAllTasks(): void {
    this.subscriptions.push(this.eventAggregator.subscribe('end.all.tasks', (tasks) => {
      if (tasks && tasks.length > 0) {
        tasks.forEach((task) => {
          this.endTask(task);
        });
      }
    }));
  }

  public acceptMessage(interaction: any): void {
    let router = interaction.routerId;
    let src = interaction.src;
    let dst = interaction.dst;
    this.sendWebsocketMessage(dst, src, 'AcceptMessage', interaction.interactionId, null, router, 'Message');
  }

  public rejectMessage(interaction: any): void {
    let router = interaction.routerId;
    let src = interaction.src;
    let dst = interaction.dst;
    this.sendWebsocketMessage(dst, src, 'RejectMessage', interaction.interactionId, null, router, 'Message');
  }

  public acceptTask(interaction: any): void {
    let router = 'TR.ALL';
    this.sendTaskWebsocketMessage(interaction.dst, interaction.src, 'AcceptTask', interaction.interactionId, null, router);
  }

  public rejectTask(interaction: any): void {
    let router = interaction.routerId;
    let src = '';
    let dst = 'TR.ALL';

    if (interaction && interaction.src && interaction.dst) {
      src = interaction.src;
      dst = interaction.dst;
    }
    this.sendTaskWebsocketMessage(dst, src, 'RejectTask', interaction.interactionId, null, router);
  }

  public endInteraction(interaction: InteractionModel | TicketModel): void {
    if (
      interaction.channel.toLowerCase() === 'sms' ||
      interaction.channel.toLowerCase() === 'email' ||
      interaction.channel.toLowerCase() === 'chat' ||
      interaction.channel.toLowerCase() === 'instant_message' ||
      interaction.channel.toLowerCase() === 'ticket'
    ) {
      this.eventAggregator.publish('member.ended.interaction', interaction);

      this.endMessage(interaction);
    } else {
      if (this.rescheduledTasks.includes(interaction.interactionId)){
        this.rescheduledTasks = this.rescheduledTasks.filter(value => interaction.interactionId !== value)
      } else {
        this.endTask(interaction);
      }
    }
  }

  public rescheduleTask(interaction: InteractionModel, date: Date): void {
    if(interaction.channel.toLowerCase() === 'campaign') {
      let router = interaction.routerId ? interaction.routerId : 'TR.ALL';

      let payload = {
        interactionId: interaction.interactionId,
        timestamp: date.getTime()
      }

      this.sendTaskWebsocketMessage(interaction.dst, interaction.src, 'RescheduleTask', payload, null, router);
      this.rescheduledTasks.push(interaction.interactionId);
    }
  }

  public addRescheduledTask(taskId: string): void {
    this.rescheduledTasks.push(taskId)
  }

  public endTask(interaction: any): void {
    let router = interaction.routerId ? interaction.routerId : 'TR.ALL';
    this.sendTaskWebsocketMessage(interaction.dst, interaction.src, 'EndTask', interaction.interactionId, null, router);
  }

  public endMessage(interaction: any): void {
    let router = interaction.routerId ? interaction.routerId : 'message.ALL';
    let src = '';
    let dst = '';

    if (interaction && interaction.src && interaction.dst) {
      src = interaction.src;
      dst = interaction.dst;
    }
    let interactionId = this.getInteractionIdFromInteraction(interaction);
    this.sendWebsocketMessage(dst, src, 'EndMessage', interactionId, null, router, 'Message');
  }

  public getInteractionIdFromInteraction(interaction: any): string {
    return interaction.interactionId;
  }

  public startEmailInteraction(interaction: any, metadata: any): void {
    let router = 'message.ALL';
    let payload = {
      emailId: interaction.emailId,
      from: interaction.from,
      tos: interaction.tos,
      ccs: interaction.ccs,
      bccs: interaction.bccs,
      subject: interaction.subject,
      html: interaction.message,
      attachmentDetails: interaction.attachmentDetails,
      metadata: metadata
    };
    this.sendWebsocketMessage(interaction.userId, 'EMAIL', 'StartMessage', payload, 'EMAIL', router, 'Message');
    this.eventAggregator.publish('email.interaction.started', metadata);
  }

  public startSMSInteraction(interaction: any, metadata: any): void {
    let router = 'message.ALL';
    let payload = {
      from: interaction.from,
      to: interaction.to,
      metadata: metadata,
      text: interaction.message
    };
    this.sendWebsocketMessage(interaction.userId, 'SMS', 'StartMessage', payload, 'SMS', router, 'Message');
    this.eventAggregator.publish('sms.interaction.started', metadata);
  }

  public sendWebsocketMessage(source: any, destination: any, type: any, payload: any, callType: any, router: any, payloadType: any): void {
    let wsMessage = {
      target: router,
      type: payloadType,
      payload: {
        src: source,
        dst: destination,
        type: type,
        payload: payload,
        callType: callType
      }
    };
    this.webSocketKiosk.emitInteraction(wsMessage);
  }

  public sendTaskWebsocketMessage(source: any, destination: any, type: any, payload: any, callType: any, router: any): void {
    let wsMessage = {
      target: router,
      type: 'Task',
      payload: {
        src: source,
        dst: destination,
        type: type,
        payload: payload,
        callType: callType
      }
    };
    this.webSocketKiosk.emitInteraction(wsMessage);
  }

  public removeSubscriptions(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.dispose());
  }

  private getCurrentFormattedTime(): string {
    const dateTime = moment().format('DD/mm/YYYY hh:mm:ss');
    return dateTime;
  }
}
