import { ViewAuditDataDialog } from './../../../interaction/interactionFlow/view-audit-data/view-audit-data';
import { AbstractList, List } from 'zailab.abstract';
import { OrganisationSessionModel } from './../../../../_common/stores/sessionmodels/organisation-session-model';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, computedFrom, inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { PostInteractionProcessingService } from './post-interaction-processing-service';
import { MESSAGE_EVENTS, SessionStore } from 'zailab.common';
import { InfiniteScrollService } from '../../../../_common/services/infinitescroll-service';
import { PlaceholderService } from "../../../../_common/services/placeholder-service";
import { visibilityAware } from "../../../../_common/services/visibilityawareness/visibility-aware";
import { ViewState } from '../../../campaigns/campaign/campaign-view-state';
import { AddPostInteractionProcessor } from './add/add-post-interaction-processor';
import { EditPostInteractionProcessor } from './add/edit-post-interaction-processor';

import moment from 'moment';

const logger = LogManager.getLogger('PostInteractionProcessing');

interface IAuditEntry {
  id: string;
  name: string;
  interactionPostProcessorId: string;
  lastUpdatedBy: string;
  lastUpdatedByEmail: string;
  lastUpdatedTimestamp: number;
  action: string;
  object: string;
  value?: any;
}

@autoinject()
export class PostInteractionProcessing extends AbstractList {
  public header: string = 'Post Interaction Processing';
  public ready: boolean = false;
  public placeholders: number = 0;

  public postinteractionProcessors: any[] = [];
  public overviewState = new ViewState();

  protected container: HTMLElement;

  private auditTrail: IAuditEntry[] = [];
  private page: number = 0;
  private size: number = 20;
  public showMoreEnabled: boolean = false;

  constructor(
    private dialogService: DialogService,
    private postInteractionProcessingService: PostInteractionProcessingService,
    private sessionStore: SessionStore,
    protected eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
  }

  public attached(): void {
    this.retrievePostInteractionProcessors();
  }

  private async retrievePostInteractionProcessors(): Promise<void> {
    try {
      this.showLoader();
      const postinteractionProcessors = await this.postInteractionProcessingService.retrievePostInteractionProcessors(this.organisationId);

      this.itemList = List.Builder()
        .items(postinteractionProcessors)
        .enableAdd()
        .enableDelete()
        .enableClick()
        .selectionType(List.MULTIPLE_SELECTION_TYPE)
        .build();

    } catch(e) {
      logger.error('Failed to retrieve post interaction processors due to: ', e);
    } finally {
      this.generatePlaceholders();
      this.setReady();
      this.hideLoader();
    }
  }

  public toggleSelected(): void {
    if (this.overviewState.isLive) {
      this.retrieveAuditTrail();
    } else {
      this.page = 0;
      this.auditTrail = [];
    }
    this.overviewState.toggleSelected();
  }

  private retrieveAuditTrail(concatItems?: boolean): void {
    this.showLoader();
    this.postInteractionProcessingService
      .retrievePostInteractionProcessorsAudit(this.organisationId, this.page, this.size)
      .then((entries) => {

        if (Array.isArray(entries)) {
          entries.forEach((item) => {
            item.formattedTimestamp = moment(item.lastUpdatedTimestamp).format('D MMM YY HH:mm:ss')
          });
          this.auditTrail = concatItems ? this.auditTrail.concat(entries) : entries;
          this.showMoreEnabled = entries.length === this.size;
        }
        this.hideLoader();
      })
      .catch((error) => {
        logger.warn(' > failed to get campaign audits ', error);
        this.hideLoader();
      });
  }

  private showLoader(): void {
    this.eventAggregator.publish('app:loader:show');
  }

  private hideLoader(): void {
    this.eventAggregator.publish('app:loader:hide');
  }

  public showMore(): void {
    this.page++;
    this.retrieveAuditTrail(true);
  }

  private generatePlaceholders(): void {
    if (!this.container) {
      return;
    }
    this.placeholders = 0;
    this.placeholderService = new PlaceholderService(this.container, this.itemList.items.length, 5, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  private setReady(): void {
    this.ready = true;
  }

  public add(): void {
    this.subscribeToInteractionProcessorAddedEvent();
    this.dialogService
      .open({ viewModel: AddPostInteractionProcessor, model: this.itemList.items })
      .whenClosed(response => {
        if (response.wasCancelled) {
          this.unsubscribeFromInteractionProcessorAddedEvent();
        }
      });
  }
  
  public view(item: any): void {
    this.dialogService
      .open({ viewModel: EditPostInteractionProcessor, model: {
        postInteractionProcessor: item,
        postinteractionProcessors: this.itemList.items
      }})
      .whenClosed((response: any) => {
        if (response.wasCancelled) {
        } else {
          let items = this.itemList.items.map(_item => {
            if (_item.interactionPostProcessorId === item.interactionPostProcessorId) {
              response.output.active = item.active;
              return response.output;
            }
            return _item;
          });
          this.itemList.items = items;
        }
      });
  }

  public viewChange(auditRecord: IAuditEntry): void {
    logger.info(' :>> auditRecord >>>>> ', auditRecord);
    this.dialogService
      .open({
        viewModel: ViewAuditDataDialog,
        model: auditRecord.value.action || auditRecord.value
      })
      .whenClosed(() => {});
  }

  public downloadInteractionProcessor(auditRecord: IAuditEntry): void {
      try {
        const name = auditRecord.name;
        const data = auditRecord.value;
        
        const dataStr = JSON.stringify(data);
        const a = document.createElement('a');
        const file = new Blob([dataStr], { type: 'text/plain' });
        a.href = URL.createObjectURL(file);
        a.download = name + '.json';
        a.click();
      } catch(e) {
        logger.warn(' > failed to parse and download interaction processor', {
          error: e,
          auditRecord
        });
      }
  }

  private subscribeToInteractionProcessorAddedEvent(): void {
    this.postInteractionProcessingService
      .subscribeToInteractionPostProsessorCreated(data => {
        if (data && data.state) {
          this.itemList.items.push(data.state);
          this.generatePlaceholders();
        }
        this.unsubscribeFromInteractionProcessorAddedEvent();
      })
  }

  private unsubscribeFromInteractionProcessorAddedEvent(): void {
    this.postInteractionProcessingService
      .unsubscribeFromInteractionProcessorAddedEvent();
  }

  public selectItem(item: any): void {
    if (item.isPlaceholder || item.showLoader) {
      return;
    }
    if (this.itemList.isDeleting) {
      this.itemList.selectToDelete(item);
    } else {
      this.view(item);
    }
  }

  public async deleteItems(): Promise<void> {
    for (let item of this.itemList.items) {
      if (item.isDeleting) {
        super.showLoader();
        await this.postInteractionProcessingService
          .deleteInteractionPostProsessorDispositions(this.organisationId, item.interactionPostProcessorId);
        
        this.itemList.items = this.itemList.items.filter(_item => _item.interactionPostProcessorId !== item.interactionPostProcessorId);
      }
    }
    super.hideLoader();
    this.generatePlaceholders();
    this.itemList.toggleDelete();
  }

  public toggleActiveState(event: MouseEvent, item: any): void {
    if (this.isDeleting) {
      return;
    }
    event.stopImmediatePropagation();

    if (item.active) {
      this.postInteractionProcessingService
        .deactivatePostProcessor(this.organisationId, item.interactionPostProcessorId);
    } else {
      this.postInteractionProcessingService
        .activatePostProcessor(this.organisationId, item.interactionPostProcessorId);
    }
    item.active = !item.active;
  }

  @computedFrom('organisation')
  private get organisationId(): string {
    if(!this.organisation) {
      return null;
    }
    return this.organisation.organisationId;
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }
}