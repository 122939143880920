import { autoinject } from "aurelia-dependency-injection";
import { HttpClient } from "aurelia-http-client";


@autoinject
export class PresenceCodeService {
  constructor(
    private httpClient: HttpClient
  ) {}

  public retrievePresenceCodes(organisationId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/${organisationId}/presenceCode/presenceCodeList`)
      .asGet()
      .send();
  }
  
  public createPresenceCode(organisationId: string, presenceCode: { presenceCodeName: string, color: string }): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/${organisationId}/presenceCode`)
      .asPut()
      .withContent(presenceCode)
      .send();
  }
  
  public deletePresenceCode(organisationId: string, presenceCode: { presenceCodeName: string, color: string }): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/${organisationId}/presenceCode`)
      .asDelete()
      .withContent({
        presenceCodeName: presenceCode.presenceCodeName
      })
      .send();
  }
  
}