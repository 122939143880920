

import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { computedFrom } from 'aurelia-binding';
import { LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { MESSAGE_EVENTS, ZaiForm,  } from 'zailab.common';
import { ZIOplog } from '../../../../../../typings/zai/zai.common';
import { AssistantService } from '../assistants-service';
import { AssistantModel } from '../assistant-model';
import { AssistantFileModel } from './assistant-file-model';
import { ConfirmDialog } from '../../../../../components_v2/confirm-dialog/confirm-dialog';

import './edit-assistant.scss';

const logger = LogManager.getLogger('EditAssistant');

@autoinject
export class EditAssistant {
  public form: any[][];
  public formData: {
    name: string;
    description: string;
    purpose: string;
    greeting: string;
    token: string;
    valid: boolean;
  };
  public originalModel: {
    name: string;
    description: string;
    purpose: string;
    greeting: string;
  };
  public model: {
    name: string;
    description: string;
    purpose: string;
    greeting: string;
  };

  private assistantId: string;
  public assistant: AssistantModel;
  private assistantNames: string[];
  private files: AssistantFileModel[];
  public selectedFile: any;
  public validation: string;
  private oplogs = {};
  
  public assistantNamePlaceholderLayout = [
    [ 'image' ], [ 'text' ],
  ];
  
  public assistantFormPlaceholderLayout = [
    [ 'text' ], [ 'text' ], [ 'text' ], [ 'text' ], [ 'text' ], [ 'text' ],
  ];
  
  public messagesPlaceholderLayout = [
    [ 'text' ], [ 'text' ], [ 'text' ], [ 'text' ],
  ];
  public messagePlaceholderLayout = [
    [ 'image' ],
    [ 'text' ],
    [ 'text' ],
    [ 'text' ],
    [ '' ],
    [ '', 'image' ],
    [ 'text' ],
    [ 'text' ],
    [ 'text' ],
  ];
  
  private MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
  public file = null;
  public fileName: string = 'No file selected.';

  public savingAssistant: boolean;
  public uploadingFile: boolean;

  constructor(
    private router: Router,
    private assistantService: AssistantService,
    private dialogService: DialogService,
    private eventAggregator: EventAggregator
  ) {}
  
  public async activate(params: { assistantId: string }): Promise<void> {
    this.assistantId = params.assistantId;

    await this.getAssistant();
    this.addAssistantOplog();
    await this.retrieveAssistants();
    this.retrieveLoadedFiles();
    this.generateForm();
  }

  private async getAssistant(): Promise<void> {
    try {
      const assistant = await this.assistantService.retrieveAssistant(this.assistantId)
        .catch(e => logger.error(` > Failed to retrieve assistant with Id ${this.assistantId} due to`, e));
      
      this.assistant = new AssistantModel(
        assistant.assistantId,
        assistant.name,
        assistant.description,
        assistant.greeting,
        assistant.purpose,
        assistant.status
      );
      this.originalModel = {
        name: assistant.name,
        description: assistant.description,
        purpose: assistant.purpose,
        greeting: assistant.greeting,
      };
      this.model = { ...this.originalModel };
    } catch(e) {
      logger.error(' > Failed to get assistant due to', e);
    }
  }

  private addAssistantOplog(): void {
    let oplog: ZIOplog = this.assistantService.subscribeToAssistantChanges(this.assistant.assistantId);
    oplog.on('insert', response => this.findAndUpdateAssistant(response));
    oplog.on('update', response => this.findAndUpdateAssistant(response));
    this.oplogs[this.assistant.assistantId] = oplog;
  }

  private findAndUpdateAssistant(data: { [key: string]: any }): void {
    this.files = data.files.map(file => 
      new AssistantFileModel(
        file.name,
        file.size,
        file.status
      )
    );
  }

  private async retrieveAssistants(): Promise<void> {
    try {
      const assistants = await this.assistantService
        .retrieveAssistants()
        .catch((e) =>
          logger.error(' > Failed to retrieve assistants due to', e)
        );
      this.assistantNames = assistants
        .map(assistant => assistant.name)
        .filter(name => name !== this.assistant.name);
    } catch(e) {
      logger.error(' > Failed to get assistant due to', e);
    }
  }

  private async retrieveLoadedFiles(): Promise<void> {
    try {
      const files = await this.assistantService.retrieveLoadedFiles(this.assistantId)
        .catch(e => logger.error(` > Failed to retrieve uploaded assistant files with Id ${this.assistantId} due to`, e));
      
        this.files = files.map(file => 
          new AssistantFileModel(
            file.name,
            file.size,
            file.status
          )
        );
    } catch(e) {
      logger.error(' > Failed to get uploaded assistant files due to', e);
    }
  }
  
  private generateForm(): void {
    
    new ZaiForm()
      .newField()
      .asLabel()
      .withTitle('Name')
      .withRequiredIndicator()
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .withFocus()
      .fullWidth()
      .withIdentifier('name')
      .withPlaceholder('Enter text here.')
      .withValue(this.model.name)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
        { validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH, value: 50 },
        {
          validationType: ZaiForm.VALIDATION_TYPES.UNIQUE_NAME,
          value: this.assistantNames,
        },
      ])
      .insertField()

      .newRow()
      .newField()
      .asLabel()
      .withTitle('Description')
      .withRequiredIndicator()
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .fullWidth()
      .withIdentifier('description')
      .withPlaceholder('Enter enter description here.')
      .withValue(this.model.description)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
        { validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH, value: 100 },
      ])
      .insertField()

      .newRow()
      .newField()
      .asLabel()
      .withTitle('Purpose')
      .withRequiredIndicator()
      .insertField()
      
      .newRow()
      .newField()
      .asTextArea()
      .fullWidth()
      .withIdentifier('purpose')
      .withPlaceholder('Enter enter purpose here.')
      .withValue(this.model.purpose)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
        { validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH, value: 100 },
      ])
      .insertField()

      .newRow()
      .newField()
      .asLabel()
      .withTitle('Greeting')
      .insertField()
      
      .newRow()
      .newField()
      .asTextArea()
      .fullWidth()
      .withIdentifier('greeting')
      .withPlaceholder('Enter enter greeting here.')
      .withValue(this.model.greeting)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
      ])
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }

  public showResetConfirmation(): void {
    let text = `<div class="width--400">Are you sure you want to reset the AI model? This action will erase all current training data, and the model will need to be retrained.</div>`;
    this.dialogService
      .open({
        viewModel: ConfirmDialog,
        model: {
          header: 'Are you sure?',
          text
        }
      })
      .whenClosed(dialog => {
        if (dialog.wasCancelled) {
          return;
        }
        this.reset();
      });
  }

  public reset(): void {
    this.assistantService.resetAssistant(this.assistant.assistantId)
    .then(() => {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Assistant ${this.assistant.name} has been successfully reset.`);
    })
    .catch(e => {
      logger.error(' > Failed to reset the Assistant due to', e);
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, `Failed to reset Assistant ${this.assistant.name}.`);
    });
  }

  public formDataChanged(data: any): void {
    this.model = { ...data };
  }

  @computedFrom('model', 'originalModel')
  public get hasFormChanges(): boolean {
    return JSON.stringify(this.originalModel) !== JSON.stringify(this.model);
  }

  public discardAssistantChanges(): void {
    this.model = { ...this.originalModel };
  }

  public updateAssistant(): void {
    this.savingAssistant = true;

    this.assistantService.updateAssistant(
      this.assistant.assistantId,
      this.model.name,
      this.model.description,
      this.model.purpose,
      this.model.greeting
    )
    .then(() => {
      this.originalModel = { ...this.model };
      this.savingAssistant = false;
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Assistant ${this.model.name} successfully updated.`);
    })
    .catch(e => {
      logger.error(' > Failed to update assistant due to', e);
      this.savingAssistant = false;
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, `Failed to update assistant ${this.model.name}.`);
    });
  }
  
  public selectFile(evt: { target: { files: File[] }}): void {
    let file = evt.target.files[0];
    const validationError = this.isValid(file);
  
    if (validationError) {
      this.validation = validationError;
      return;
    }
    this.validation = null;
    let reader = new FileReader();
    this.fileName = file.name;

    if(!file) {
      return;
    }
    this.uploadingFile = true;
    reader.onload = (event) => {
      const fileExtension = this.fileName.slice(this.fileName.lastIndexOf('.')).toLowerCase();
      this.file = {
        content: file,
        name: this.fileName,
        size: file.size,
      };
      this.openConfirmUploadDialog();
    };
    reader.readAsDataURL(file);
  }

  private isValid(file: File): string | null {
    const validExtensions = [
      ".doc", ".docx", ".html", ".md", ".pdf", ".pptx", ".tex", ".txt"
    ];
    const validTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/html",
      "text/markdown",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/x-tex",
      "text/plain"
    ];
    
    const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
  
    if (!validExtensions.includes(fileExtension) || !validTypes.includes(file.type)) {
      return "Invalid file type. Please upload a supported file format.";
    }
  
    if (file.size > this.MAX_FILE_SIZE) {
      return `Please select a file smaller than ${this.MAX_FILE_SIZE / (1024 * 1024)} MB.`;
    }
  
    return null;
  }
  
  

  private openConfirmUploadDialog(): void {
    let text = `This will upload your file to be processes by OpenAI.`;
    this.dialogService
      .open({
        viewModel: ConfirmDialog,
        model: {
          header: 'Are you sure?',
          text
        }
      })
      .whenClosed(dialog => {
        this.selectedFile = null;
        this.fileName = null;

        if (dialog.wasCancelled) {
          this.uploadingFile = false;
          return;
        }
        this.assistantService.uploadAssistantFile(
          this.assistantId,
          this.file
        )
        .then(() => {
          this.files.push(
            new AssistantFileModel(
              this.file.name,
              this.file.size,
              null
            )
          );
          this.uploadingFile = false;
        })
        .catch(e => logger.error(' > Failed to upload file due to', e));
      });
  }

  public navigateBackToAssistants(): void {
    this.router.navigate('');
  }

  public deactivate(): void {
    const keys = Object.keys(this.oplogs);
    keys.forEach(key => this.unsubscribeOplog(key));
  }

  public unsubscribeOplog(id: string): void {
    if (this.oplogs[id]) {
      this.oplogs[id].unsubscribe();
      delete this.oplogs[id];
    }
  }
}
