import { inject } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";


@inject(HttpClient)
export class MicrosoftTeamsPresenceService {
  httpClient: any;
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  public getMSTeamsPresences(organisationId) {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/ms-teams`)
      .asGet()
      .send()
  }

  public updateMSTeamsPresenceMapping(
    organisationId,
    enabled: boolean,
    properties: { clientId: string; tenantId: string; presenceMappings: { [key: string]: string } },
    encryptedProperties: { key: string; value: string | null; valueHash: string; }[]
  ) {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/ms-teams`)
      .asPut()
      .withContent({
        enabled,
        properties,
        encryptedProperties
      })
      .send()
  }

  public createMSTeamsPresenceMapping(
    organisationId,
    enabled: boolean,
    properties: { clientId: string; tenantId: string; presenceMappings: { [key: string]: string } },
    encryptedProperties: { key: string; value: string; valueHash: string; }[]
  ) {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/ms-teams`)
      .asPost()
      .withContent({
        enabled,
        properties,
        encryptedProperties
      })
      .send()
  }
}