import { LogManager, autoinject, PLATFORM } from 'aurelia-framework';
import {
  ControllerValidateResult,
  ValidationController,
  ValidationRules,
} from 'aurelia-validation';

import { ZIWizard } from '../../../../../../../../../typings/zai/zai.common';
import { CurrentScopeValidation } from 'zailab.common';
import { DataTools } from '../../../../../../../../_common/tools/data-tools';

const logger = LogManager.getLogger('Text IVR | Output');

@autoinject
export class Keypad {
  public tableColumns = ['Output'];
  public tableData = [];
  public tableValidation = /^[A-Za-z0-9\- ,.!?()[\]]+$/;
  private validation: ValidationController;

  public newRowData: { [key: string]: string } = {};
  public editState: { [key: string]: string[] } = {};
  public errorState: { [key: string]: string[] } = {};
  public getParsedValue = (value: string | number) =>
    DataTools.getParsedValue(value);

  private model: ZIWizard;

  constructor(public validate: CurrentScopeValidation) {
    this.validation = validate.getController();
  }

  public activate(bindingContext: ZIWizard): void {
    this.initWizard(bindingContext);
    this.initValidationRules();
  }

  private initWizard(bindingContext: ZIWizard): void {
    this.model = bindingContext;
    if (this.model.data.IVRInvalidResponseMessage) {
      this.model.step.complete({});
    }
    if (this.model.data.outputs) {
      this.tableData = this.model.data.outputs.reduce((acc, item) => {
        acc.push([item]);
        return acc;
      }, []);
    }
  }

  private initValidationRules(): void {
    this.validation.reset();

    ValidationRules.customRule(
      'requiredOutput',
      () => {
        return this.model.data.outputs && this.model.data.outputs.length > 0;
      },
      'Please enter at least 1 output.'
    );

    ValidationRules.ensure('IVRInvalidResponseMessage')
      .required()
      .withMessage('Please enter a Message.')
      .matches(/^[a-zA-Z0-9_ ,.\-!?]+$/)
      .withMessage('Please enter a valid Message')
      .then()
      .satisfiesRule('requiredOutput')
      .on(this.model.data);
  }

  public toggletimeoutDestination(): void {
    this.model.data.timeoutDestination = !this.model.data.timeoutDestination;
  }

  public valueChanged(tableData: any): void {
    this.model.data.outputs = tableData.flatMap((item) => item);
    this.responseMessageChanged(this.model.data.IVRInvalidResponseMessage);
  }

  public responseMessageChanged(value: string): void {
    if (value) {
      this.validation.validate().then((validation) => {
        if (!validation.valid) {
          this.model.step.incomplete({});
          return;
        }
        this.model.step.complete({});
      });
    } else {
      this.model.step.incomplete({});
    }
  }
}
