import { LogManager, autoinject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

import { AbstractList, List } from 'zailab.abstract';
import { PlaceholderService } from '../../../../_common/services/placeholder-service';

const logger = LogManager.getLogger('InHouseWFMList');

@autoinject()
export class InHouseWFMList extends AbstractList {
  public placeholderService: PlaceholderService;
  public placeholders = 0;
  public container: any;

  public loading: boolean;
  public configs: any = [];

  private config = {
    name: 'Dashboard Alerts',
  };

  constructor(private router: Router, eventAggregator: EventAggregator) {
    super(eventAggregator);
  }

  public attached(): void {
    this.setUpList();
  }

  private async setUpList(): Promise<void> {
    this.configs = [this.config];
    this.generatePlaceholders();
  }

  public selectConfig(config: any): void {
    if (config.name === 'Dashboard Alerts') {
      this.router.navigate('dashboard-alerts');
    }
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(
      this.container,
      this.configs.length,
      5,
      (placeholders) => {
        this.placeholders = placeholders;
      }
    );
  }
}
