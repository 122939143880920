import { bindable, customElement, LogManager } from 'aurelia-framework';
import { InteractionModel } from '../../interaction-model';
import { InteractionService } from '../../interaction/interaction-service';
import moment from 'moment';
import {
  ValidationControllerFactory,
  ValidationController,
  ValidationRules,
  validateTrigger,
} from 'aurelia-validation';
import { EventAggregator } from 'aurelia-event-aggregator';

import { MESSAGE_EVENTS } from 'zailab.common';
import { computedFrom } from 'aurelia-binding';

const logger = LogManager.getLogger('CampaignInteractionCard');

@customElement('z-campaign-interaction-card')
export class CampaignInteractionCard {
  @bindable private interaction: InteractionModel;

  public dateTime = {
    date: null,
    hour: 0,
    minute: 0,
  };

  constructor(
    private validationController: ValidationController,
    private validationControllerFactory: ValidationControllerFactory,
    private eventAggregator: EventAggregator,
    private interactionService: InteractionService
  ) {
    this.interactionService = interactionService;

    this.validationController =
      validationControllerFactory.createForCurrentScope();
    this.validationController.validateTrigger = validateTrigger.changeOrBlur;
    this.initValidation();
  }

  public reschedule(): void {
    let dateTime = moment(this.dateTime.date).toDate();
    dateTime.setHours(this.dateTime.hour);
    dateTime.setMinutes(this.dateTime.minute);

    this.interactionService.rescheduleTask(this.interaction, dateTime);
    this.eventAggregator.publish(
      MESSAGE_EVENTS.SUCCESS,
      'Task has been rescheduled to' + dateTime + '.'
    );
    this.dateTime.date = null;
    this.dateTime.hour = 0;
    this.dateTime.minute = 0;
  }

  private initValidation(): void {
    const dateTimeRules = ValidationRules.ensure('date').required().rules;
    this.validationController.addObject(this.dateTime, dateTimeRules);
    this.validationController.validate();
  }

  @computedFrom('interaction.channelIcon')
  public get reschedulable(): boolean {
    return (
      this.interaction.channelIcon === 'campaign' && !this.interaction.taskEnded
    );
  }
}
