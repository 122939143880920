import { LogManager, autoinject } from 'aurelia-framework';
import { observable } from 'aurelia-binding';

import { SearchTools, ZaiForm } from 'zailab.common';

import { AssistantModel } from '../../../../../assistants/assistant-model';
import { AssistantService } from '../../../../../assistants/assistants-service';
import { ZIWizard } from '../../../../../../../../../typings/zai/zai.common';

const logger = LogManager.getLogger('Assistant Dialog');

@autoinject
export class AssistantDialog {

  public assistants: Array<AssistantModel>;
  private isSearching: boolean = false;
  public searchTerm: string;
  public wizard;
  public form: any[][];
  @observable public formData: {
    name: string;
    valid: boolean;
  };

  public emptyStateConfig: ZIEmptyState = {
    icon: 'person-question-mark.svg',
    heading: `No assistants to display.`,
    description: `You haven't created any assistants yet.`,
    hasButton: false,
    hasTip: false,
    hasExternalContentCreator: false,
  };

  constructor(
    private assistantService: AssistantService
  ) {}

  public activate(wizard: ZIWizard): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => this.wizard.continue());
    
    this.retrieveAssistants();
    this.setupForm();
  }

  private async retrieveAssistants(): Promise<void> {
    const assistants = await this.assistantService
      .retrieveAssistants()
      .catch((e) =>
        logger.error(' > Failed to retrieve assistants due to', e)
      );
    const mappedAssistants = assistants.map(
      assistant => {
        let modelledAssistant = new AssistantModel(
          assistant.assistantId,
          assistant.name,
          assistant.description,
          assistant.greeting,
          assistant.purpose,
          assistant.status,
        );
        modelledAssistant.isSelected = (
          this.wizard.data.assistantId &&
          this.wizard.data.assistantId === assistant.assistantId
        );
        return modelledAssistant;
      }
    );
    this.assistants = mappedAssistants;
  }

  public selectAssistant(assistant: AssistantModel): void {
    this.assistants.forEach(assistant => {
      assistant.isSelected = false;
    });

    this.wizard.data.assistantId = assistant.assistantId;
    assistant.isSelected = true;
  }

  public toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  private setupForm(): void {
    new ZaiForm()
      .newField()
      .asTextInput()
      .withFocus()
      .withIdentifier('name')
      .withTitle('Name', '100px')
      .withPlaceholder('Please enter a name for this Input Capture.')
      .withValue(this.wizard.data.name)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.TEXT_WHITESPACE_HYPHEN },
        {
          validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH,
          value: 50,
        },
      ])
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }

  public formDataChanged(newValue: any): void {
    this.wizard.data.name = newValue.name;

    if (newValue.valid) {
      this.wizard.step.complete();
    } else {
      this.wizard.step.incomplete();
    }
  }

  public doNothing(): void {}

  public startsWithMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}
