import { bindable, customElement, LogManager } from "aurelia-framework";
import { Event } from "zailab.common";

const logger = LogManager.getLogger('AgentScriptCCHistory');

@customElement('z-agent-script-cc-history')
export class AgentScriptCCHistory {

  @bindable
  public history = [];

  constructor(
    private element: Element,
  ) { }

  public showCurrentStep(): void {
    new Event(this.element, 'show-current-step', {});
  }

  public select(history: any): void {
    new Event(this.element, 'show-step', history);
  }
}
